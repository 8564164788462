import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import Media from '../../Admin/media/Media';
import FileUploader from '../../../utils/uploadFile';
import { useSelector } from 'react-redux';
import { createLesson } from '../../../utils/lessons';
import { success } from '../../../components/shared/modules/Notifications';

const AddLessonOther = ({materialCode }) => {
    const [show, setShow] = useState(false)
    const [showMediaLibrary, setShowMediaLibrary] = useState(false)
    const [selectedType, setSelectedType] = useState(null)
    const [files, setFiles] = useState([null]);
    const [mediaIds, setMediaIds] = useState([]);
    const loggedUser = useSelector((state) => state.loggedUser);
    const [lessonName, setLessonName] = useState(null)
    const [selectedMediaIds, setSelectedMediaIds] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleMediaSelect = (selectedIds) => {
        setSelectedMediaIds(selectedIds);
        setShowMediaLibrary(false);
    };

    const handleTypeSelect = (type) => {
        setSelectedType(type)
    }

    const addFileInput = () => {
        setFiles([...files, null]);
    };

    const handleFileChange = (index, file) => {
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);
    };




    const handleCreateLesson = async () => {
        setLoading(true);
        const newMediaIds = [];

        for (const file of files) {
            if (file) {
                const uploader = new FileUploader(loggedUser?.user?._id, file);
                try {
                    const uploadResponse = await uploader.uploadFile('AWS-S3');
                    if (uploadResponse?.data?.media?._id) {
                        newMediaIds.push(uploadResponse.data.media._id);
                    }
                } catch (error) {
                    console.error('File upload failed:', error);
                    return;
                }
            }
        }

        const allMediaIds = [...selectedMediaIds, ...newMediaIds];

        const lessonData = {
            name: lessonName,
            materialCode,
            content: allMediaIds,
        };

        try {
            const response = await createLesson(lessonData);
            if (response?.data?.case) {
                success('Lesson Created Successfully');
                setShow(false);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setShow(false);
            setShowMediaLibrary(false);
            setSelectedType(null);
            setFiles([null]);
            setMediaIds([]);
            setLessonName(null);
            setSelectedMediaIds([]);
            setLoading(false);
        }
    };

    const renderInputBasedOnType = () => {
        switch (selectedType) {
            case 'upload':
                return (
                    <div>
                        {files.map((file, index) => (
                            <div key={index} className="d-flex align-items-center mt-3">
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => handleFileChange(index, e.target.files[0])}
                                />
                                {index === files.length - 1 && (
                                    <button
                                        className="btn btn-primary ms-2"
                                        onClick={addFileInput}
                                    >
                                        +
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                );
            case 'link':
                return <input type="text" className="form-control mt-3" placeholder="Enter URL" />
            case 'media':
                return (
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => setShowMediaLibrary(true)}
                    >
                        Open Media Library
                    </Button>
                )
            default:
                return null
        }
    }

    return (
        <div>
            <Button variant="primary" onClick={() => setShow(true)}>
                Add Lesson
            </Button>

            <Modal show={show} onHide={() => setShow(false)} centered className='font'>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Lesson</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div>
                        <input type="text" placeholder='Lesson Name' className='form-control mt-3 mb-3' onChange={(e) => setLessonName(e.target.value)} value={lessonName} />
                    </div>
                    <Dropdown className='w-100'>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            Select Type
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleTypeSelect('media')}>From Media Library</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleTypeSelect('link')}>From Link</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleTypeSelect('upload')}>Upload File</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {renderInputBasedOnType()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateLesson} disabled={loading}>
                        {loading ? `creating` : `Create Lesson`}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMediaLibrary} onHide={() => setShowMediaLibrary(false)} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Media Library</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Media onMediaSelect={handleMediaSelect} showForMedia={true} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddLessonOther
