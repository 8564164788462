import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Get_All_transactions } from '../../../actions/transactionAct';
import { ErrorMsg } from "../../../components/shared/modules/Notifications"
import { Table } from 'react-bootstrap';
import Pagination from '../../../utils/Pagination';
import DelteTrans from './DelteTrans';
import EditTrans from './EditTrans';

const Alltrans = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        status: '',
        type: ''
    });
    const limit = 5;

    const handleTransactions = async (pageNumber) => {
        try {
            const res = await dispatch(Get_All_transactions(loggedUser?.user?._id, pageNumber, limit, filters));
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        handleTransactions(page, filters);
    }, [page, filters])

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
        setPage(1);
    }

    const transactions = useSelector((state) => state.transactionRed);

    console.log(transactions)
    const totalPages = Math.ceil(transactions?.total / limit);

    return (
        <div>
            <div className="filters mb-3">
                <select name="status" onChange={handleFilterChange} value={filters.status} className='form-control'>
                    <option value="">All Status</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>

                </select>
                <select name="type" onChange={handleFilterChange} value={filters.type} className='form-control'>
                    <option value="">All Types</option>
                    <option value="expense">expense</option>
                    <option value="income">income</option>
                </select>
            </div>
            <Table className='font mt-5' responsive >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>amount</th>
                        <th>currency</th>
                        <th>currency</th>
                        <th>status</th>
                        <th>type</th>
                        <th>description</th>
                        <th>date</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions?.data?.map((transaction, i) => (
                        <tr key={i}>
                            <td>{((page - 1) * limit) + i + 1}</td>
                            <td>{transaction?.category}</td>
                            <td>{transaction?.amount}</td>
                            <td>{transaction?.currency}</td>
                            <td>{transaction?.currency}</td>
                            <td>{transaction?.status}</td>
                            <td>{transaction?.type}</td>
                            <td>{transaction?.description}</td>
                            <td>{transaction?.date}</td>
                            <td>
                                <EditTrans trans={transaction} />
                            </td>
                            <td>
                                <DelteTrans transaction={transaction} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Pagination onPress={(pageNumber) => setPage(pageNumber)} countsPage={totalPages} />
        </div>
    )
}

export default Alltrans
