// import React, { useEffect, useState } from 'react'
// import { Alert, Button, Container } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import Question from './question';
// import { setResult } from '../../../../utils/results';
// import './test.css'
// function ViewTest({ userID }) {
//     const dispatch = useDispatch();
//     const [Answers, setAnswers] = useState(null);
//     const [message, setMessage] = useState(null);
//     const { test } = useSelector(state => ({
//         test: state.test
//     }));


//     console.log(test);

//     // test Types

//     useEffect(() => {
//         if (!Answers) {
//             let newAnswers = test.questions.map(q => {
//                 if (q.choices) {
//                     q.chosenAnswer = null
//                 }
//                 else if (q.TrueFalseAnswer) {
//                     q.answer = null
//                 }
//                 else {
//                     q.answer = null;
//                 }
//                 return q
//             })
//             setAnswers(newAnswers)
//         }
//     }, []);

//     const handleAnswer = (qOrder, answer) => {
//         console.log('fire handel answer', qOrder, answer)
//         const requiredQuestion = Answers[qOrder];
//         if (requiredQuestion.choices) {
//             requiredQuestion.chosenAnswer = answer
//         }
//         else if (requiredQuestion.TrueFalseAnswer !== null && requiredQuestion.choices === null) {
//             requiredQuestion.answer = answer
//         }
//         else {
//             answer ? (requiredQuestion.answer = answer) : (requiredQuestion.answer = null)
//         }
//         setAnswers(prev => {
//             prev[qOrder] = requiredQuestion;
//             return prev
//         })
//     }

//     console.log('answers', Answers)

//     const SetResult = async () => {
//         try {
//             if (!test) {
//                 console.error('No test available to set result.');
//                 return;
//             }

//             // let total = 0;
//             let result = 0;
//             let correctedQuestions = 0
//             const userAnswers = Answers.map((question, index) => {
//                 if (question.choices) {
//                     const correctAnswer = question.choices.find(c => c.correct)
//                     const isCorrect = correctAnswer && correctAnswer.value === question.chosenAnswer;
//                     if (isCorrect) {
//                         result += question.degree;
//                         // total += question.degree;
//                     }
//                     correctedQuestions++
//                 }
//                 else if (!question.choices && question.TrueFalseAnswer !== null) {
//                     if (question.answer === question.TrueFalseAnswer) {
//                         result += question.degree
//                     }
//                     correctedQuestions++

//                 }
//                 return question;

//             });

//             const resultData = {
//                 name: test.name,
//                 userID: userID,
//                 testID: test._id,
//                 result: result,
//                 total: test.tot_degree,
//                 questions: Answers,
//                 numberOfQuestions: Answers.length,
//                 correctedQuestions,
//             };

//             // Dispatch action to set result
//             const res = await setResult(resultData);

//             // Update test result
//             // setTestResult(res.data.result);
//             // console.log('Result :', res.data.result)
//             // dispatch(Set_Result(res.data.result))

//             // Show modal
//             setMessage({ case: res.data.case, message: res.data.message })

//         } catch (err) {
//             console.log(err.message);
//         }
//     };
//     return (

//         <Container style={{ width: '98%' , background:"transparent" , margin:"10px auto" , borderRadius:"10px" , }} >
//             {test?.questions.map((q, index) => <>
//                 <Question
//                     key={"1"}
//                     question={q}
//                     handleAnswer={handleAnswer}
//                     questionIndex={index}
//                 />
//                 {/* {q.choices ? <MultibleChoicesQuestion question={q} index={index} /> : <></>} */}
//             </>)}
//             {message ? <Alert variant={message.case ? 'success' : 'danger'}>{
//                 message.case ? 'سيتم تصحيح الاختبار وارسال النتيجة فى اقرب وقت' : message.message
//             }</Alert> : <></>}
//             <button
//                 className='test-submit-btn btn mt-5'
//                 onClick={() => SetResult()}>
//                 انهاء الإختبار
//             </button>
//         </Container>
//     )
// }

// export default ViewTest

import React, { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Question from './question';
import { setResult } from '../../../../utils/results';
import './test.css';

function ViewTest({ userID }) {
    const dispatch = useDispatch();
    const [Answers, setAnswers] = useState([]);
    const [message, setMessage] = useState(null);
    const { test } = useSelector(state => ({
        test: state.test
    }));

    console.log(test);

    useEffect(() => {
        if (test && Answers.length === 0) {
            let newAnswers = test.questions.map(q => {
                if (q.choices) {
                    q.chosenAnswer = null;
                } else if (q.TrueFalseAnswer) {
                    q.answer = null;
                } else {
                    q.answer = null;
                }
                return q;
            });
            setAnswers(newAnswers);
        }
    }, [test, Answers]);

    const handleAnswer = (qOrder, answer) => {
        console.log('fire handle answer', qOrder, answer);
        const requiredQuestion = { ...Answers[qOrder] }; // Copy to avoid direct state mutation
        if (requiredQuestion.choices) {
            requiredQuestion.chosenAnswer = answer;
        } else if (requiredQuestion.TrueFalseAnswer !== null && requiredQuestion.choices === null) {
            requiredQuestion.answer = answer;
        } else {
            requiredQuestion.answer = answer ? answer : null;
        }
        setAnswers(prev => {
            const newAnswers = [...prev];
            newAnswers[qOrder] = requiredQuestion;
            return newAnswers;
        });
    };

    console.log('answers', Answers);

    const SetResult = async () => {
        try {
            if (!test) {
                console.error('No test available to set result.');
                return;
            }

            let result = 0;
            let correctedQuestions = 0;
            const userAnswers = Answers.map((question, index) => {
                if (question.choices) {
                    const correctAnswer = question.choices.find(c => c.correct);
                    const isCorrect = correctAnswer && correctAnswer.value === question.chosenAnswer;
                    if (isCorrect) {
                        result += question.degree;
                    }
                    correctedQuestions++;
                } else if (!question.choices && question.TrueFalseAnswer !== null) {
                    if (question.answer === question.TrueFalseAnswer) {
                        result += question.degree;
                    }
                    correctedQuestions++;
                }
                return question;
            });

            const resultData = {
                name: test.name,
                userID: userID,
                testID: test._id,
                result: result,
                total: test.tot_degree,
                questions: userAnswers,
                numberOfQuestions: userAnswers.length,
                correctedQuestions,
            };

            const res = await setResult(resultData);

            setMessage({ case: res.data.case, message: res.data.message });

        } catch (err) {
            console.log(err.message);
        }
    };

    return (
        <Container style={{ width: '98%', background: "transparent", margin: "10px auto", borderRadius: "10px" }}>
            {
                test?.questions.map((q, index) => {
                    console.log(q);
                    return (
                        <Question
                            key={index} // Use unique key for each question
                            question={q}
                            handleAnswer={handleAnswer}
                            questionIndex={index}
                        />
                    )
                })
            }
            {message ? (
                <Alert variant={message.case ? 'success' : 'danger'}>
                    {message.case ? 'سيتم تصحيح الاختبار وارسال النتيجة فى اقرب وقت' : message.message}
                </Alert>
            ) : null}
            <button
                className='test-submit-btn btn mt-5'
                style={{backgroundColor:"#0F2A51" , color:"white"}}
                onClick={SetResult}>
                انهاء الإختبار
            </button>
        </Container>
    );
}

export default ViewTest;
