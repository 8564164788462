import "./styles/StdResulte.css";

export default function ShowSelectedTestResultsComponent({selectedTest}) {

    // console.log('selectedTest',selectedTest);
    const{questions}=selectedTest.result;
      // console.log('questions',questions);
  return (
    <div className="selectedTest" style={{direction:'rtl'}}>
      <div className="title">الاسئلة</div>
      <ol>
        {questions&&questions?.map((q,i)=>(
          <li key={i}>
          <div  className="d-flex justify-content-between smallwidth">
            <div className="question">
            <div>{q.question}</div>
            <div className="text-end">الاجابة : {q?.chosenAnswer}</div>  
            </div>

            <div className="d-flex align-items-center">
            {q.choices.find(el=>el.value==q.chosenAnswer)?.correct ?
              <button  className="btn-style true">
                صحيح
              </button>
              :
              <button  className="btn-style false">
                خطأ
              </button>
          }
            </div>
          </div>
          </li>
          
        ))}
      </ol>
    </div>
  )
}
