import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Update_Edu_Type, Update_Edu_Type_branch } from '../../../actions/eduAction';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';

const UpdateEdu = ({ edu }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [name, setName] = useState(edu?.name);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);
    const branchs = useSelector((state) => state.branchs);

    const [selectBranch,setSelectBranch] = useState(null);


    console.log(branchs);


    const handleUpdate = async () => {
        try {
            setLoading(true)
            const res = await dispatch(Update_Edu_Type({ name: name }, edu?._id, loggedUser?.user?._id));

            if (res?.status === 200) {
                success(res?.data?.message);
                handleClose();
            }

            if(selectBranch){
                const res = await dispatch(Update_Edu_Type_branch(edu?._id,selectBranch, loggedUser?.user?._id));
            }
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <FaEdit onClick={handleShow} size={"24px"} />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>

                    <select className='form-control' value={selectBranch} onChange={(e) => setSelectBranch(e.target.value)}>
                        <option hidden>Select Branch</option>
                        {
                            branchs?.map((branch) => {
                                return (
                                    <option key={branch?._id} value={branch?._id}>{branch?.name}</option>
                                )
                            })
                        }
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger' onClick={handleClose}>Cancel</Button>
                    <Button className='btn-primary' onClick={handleUpdate} disabled={loading}>
                        {loading ? "loading.." : "Update"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateEdu
