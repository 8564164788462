import React, { useEffect, useState } from 'react';
// import { getStudentsInProgramNotInClass } from '../../../utils/packagesRequests';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { getUsersByIDS as getStudentsByIDS, getUsersByIDS } from '../../../utils/API';
import { Add_Students_To_Class } from '../../../actions/class';
function AddStudents({ Class, studentsToAdd }) {
    const [message, setMessage] = useState(null)
    const [allStudents, setAllStudents] = useState([])
    const [students, setStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState([]); // ids
    const [selectedStudentsObj, setSelectedStudentsObj] = useState([])

    // const allStudents = useSelector(state=>state.users.students)
    // Function to fetch students from API
    const getStudents = async () => {
        try {
            // const res = await getStudentsInProgramNotInClass({ programId: Class.packID });
            // console.log(res); // Logging response for debugging
            // const studentsIDS = res.data.studentsDoesNotInClass;
            const response = await getUsersByIDS({ IDS: studentsToAdd });
            //  console.log('Response',res)
            setAllStudents(response.data.users)
            setStudents(response.data.users);
            // setStudentsToAdd(response.data.users)
        } catch (err) {
            console.log(err.message); // Logging error message for debugging
        }
    }

    // Fetch students when component mounts
    useEffect(() => {
        getStudents();
    }, [Class]); // Dependency array to ensure useEffect runs when packID changes

    // Selector to get levels from Redux store
    const { levels } = useSelector(({ levels }) => ({
        levels
    }));



    // Function to handle select change
    const handleSelectChange = (event) => {
        const id = event.target.value
        setSelectedStudent(prev => [...prev, id]);
        const stObj = students.find(s => s._id === id)
        setSelectedStudentsObj(prev => [...prev, stObj])
        const remStudents = students.filter(s => s._id !== id);
        setStudents(remStudents);
    }

    // Function to handle form submission
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        try {

            // console.log('jhjk') 
            e.preventDefault();
            // console.log("Selected Student:", selectedStudent);
            if (!selectedStudent) return;
            const res = await dispatch(Add_Students_To_Class({ students: selectedStudent, id: Class._id }))
            // Perform any action with the selected student, like adding it to the class
            // Reset the selected student state

            setSelectedStudent([]);
            setSelectedStudentsObj([])
            setMessage(res.data)
            // Close the modal
            // closeModal();
        }
        catch (err) {
            console.warn(err)
        }
    }

    return (
        <div>
            {/* Button to open modal */}

            {/* Modal */}
            <Modal.Header>
                <Modal.Title className='font'>اضافة طلاب</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className='font'>Selected Students:</h5>
                <ul>
                    {selectedStudentsObj.map(student => (
                        <li key={student._id}>{student.username}</li>
                    ))}
                </ul>
                {/* Fields for selecting students */}
                <div className="form-group font">
                    <label htmlFor="selectStudent">اختر الطلاب:</label>
                    {message && <Alert variant={message.case ? 'success' : 'danger'}>
                        {message.message}
                    </Alert>}
                    <select
                        className="form-control"
                        id="selectStudent"
                        value={selectedStudent}
                        onChange={handleSelectChange}
                    >
                        <option value="">اختر طالب</option>
                        {students && students.map(student => (
                            <option key={student._id} value={student._id}>{student.username}</option>
                        ))}
                    </select>
                </div>
                {/* Submit button */}
                <button className='btn special color' onClick={handleSubmit}>Submit</button>
            </Modal.Body>


        </div>
    );
}

export default AddStudents;
