import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { success } from '../../shared/modules/Notifications';

const ShowLiveSessions = ({ sessions }) => {

    const [show, setshow] = useState(false);
    const handleShow = () => setshow(true);
    const handleClose = () => setshow(false);
    return (
        <div>
            <button className='btn btn-primary' onClick={handleShow}>Show Live Sessions</button>
            <Modal centered show={show} onHide={handleClose} className='font' size='lg'>
                <Modal.Body>
                    <Table responsive>
                        <thead style={{ textAlign: "center" }}>
                            <tr>
                                <th>Session Name</th>
                                <th>Material Code</th>
                                <th>Homework Task</th>
                                <th>Description</th>

                                <th>Host Email </th>
                                <th>Join Student</th>

                                <th>Status </th>

                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {sessions?.map((session) => (
                                <tr key={session._id}>
                                    <td>{session.name}</td>
                                    <td>{session.materialCode}</td>
                                    <td>{session.homeworkTask}</td>
                                    <td>{session.description}</td>
                                    {/* <td>{new Date(session.date).toLocaleDateString()}</td> */}
                                    <td>{session?.zoom?.host_email || "لا يوجد لينك زووم"}</td>
                                    <td>
                                        {session?.zoom?.join_url ? (
                                            <span
                                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(session.zoom.join_url);
                                                    success('تم نسخ الرابط بنجاح!');
                                                }}
                                                title={session.zoom.join_url}
                                            >
                                                {session.zoom.join_url.substring(0, 10)}...
                                            </span>
                                        ) : "لا يوجد لينك زووم"}
                                    </td>

                                    <td>{session?.zoom?.status || "لا يوجد لينك زووم"}</td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ShowLiveSessions
