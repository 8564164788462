import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Delete_Packages_Subscriptions } from '../../../actions/packagesubscriptions'; // Import your delete action
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';

const ModalDeleteService = ({ show, handleClose, packageId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false); // State for loading

    const handleDelete = async () => {
        setLoading(true); // Set loading to true when the deletion process starts
        try {
            const res = await dispatch(Delete_Packages_Subscriptions({id:packageId})); // Dispatch delete action
            console.log(res)
            if(res?.data === ""){
                success(`تم حذف الباقه بنجاح`);
                handleClose();
            }
        } catch (e) {
            ErrorMsg('حدث خطأ أثناء الحذف');
        } finally {
            setLoading(false); // Stop loading after deletion process finishes
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered className='font'>
            <Modal.Header closeButton>
                <Modal.Title>تأكيد الحذف</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                هل انت متأكد من أنك تريد حذف هذه الباقه؟
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={loading}>
                    الغاء
                </Button>
                <Button variant="danger" onClick={handleDelete} disabled={loading}>
                    {loading ? 'جارٍ الحذف...' : 'حذف'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDeleteService;
