import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import { codeActive, downloadExcelFile, getSheetsPerMaterialPerProgram } from '../../../utils/codes';
import { getMaterialByCode } from '../../../utils/materials';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import DownloadExcellFile from './downloadExcellFile';
import PdfGenerator from './getPDF';
const GetCodes = ({ material, packages }) => {
    const [show, setShow] = useState(false);
    const [materialName, setMaterialName] = useState("");
    const [loading, setLoading] = useState(true);
    const [codeSheets, setCodesSheet] = useState([]);
    const [downloadLink, setDownloadLink] = useState(null);
    const headers = [{ header: 'اسم الدرس', key: 'lessonName' }, {
        header: 'تفاصيل الكود', key: 'codeInfo'
    }]
    const [requestedSheet, setRequestedSheet] = useState(null);
    const [pdfReady, setPdfReady] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }

    // Request PDF File
    const requestPdf = (c) => {
        setRequestedSheet(c);
        setPdfReady(true)
    }
    const fetchGetCode = async () => {
        try {
            const res = await getSheetsPerMaterialPerProgram({
                materialCode: material.materialCode,
                packID: packages._id,
            });

            if (res?.data?.case) {
                setCodesSheet(res?.data?.codeSheets)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const fetchMaterialName = async () => {
        setLoading(true);
        try {
            const res = await getMaterialByCode({
                code: material.materialCode
            });
            if (res?.data?.case) {
                setMaterialName(res?.data?.material?.name);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchMaterialName();
    }, []);

    useEffect(() => {
        if (show === true) {
            fetchGetCode()
        }
    }, [show])


    const formatToCairoTime = (dateString) => {
        const date = new Date(dateString); // Create Date object from creationDate string
        const options = {
            timeZone: 'Africa/Cairo', // Set time zone to Cairo
            weekday: 'long', // Display full weekday name (e.g., "Monday")
            year: 'numeric', // Display full year (e.g., "2024")
            month: 'long', // Display full month name (e.g., "January")
            day: 'numeric', // Display day of the month (e.g., "1")
            // hour: 'numeric', // Display hour (e.g., "1" or "01" for 1 AM/PM)
            // minute: 'numeric' // Display minute (e.g., "30")
        };
        return date.toLocaleString('en-US', options); // Format to Cairo local time
    };




    const changeActive = async (id) => {
        try {
            const res = await codeActive({
                id,
            })
            if (res.data.case) {
                setCodesSheet(prev => prev.map(s => {
                    if (s?._id === res.data?.sheet._id) {
                        return res.data.sheet
                    }
                    return s
                }))
            }
            // console.log(res)
        } catch (e) {
            console.log(e)
        }
    }


    // console.log(codeSheets)

    // console.log('codSheets',codeSheets)
    return (
        <>
            <p onClick={handleShow}>
                {loading ? "Loading..." : materialName}
            </p>

            <Modal show={show} onHide={handleClose} centered className='font' size='lg'>
                <div style={{ textAlign: "center" }}>
                    <p>مجموعة الاكواد الخاصه ب {materialName}</p>
                    <Table style={{ fontSize: "16px" }}>
                        <thead style={{ fontSize: "16px" }}>
                            <tr>
                                <th>#</th>
                                <th>تاريخ الانتهاء</th>
                                <th>عدد مرات الاستخدام</th>
                                <th>سعر الكود</th>
                                <th>تفعيل</th>
                                <th>تنزيل الملف</th>
                                <th>عرض الاكواد</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: "16px" }}>
                            {
                                codeSheets?.map((code, index) => (
                                    <tr key={`${code._id}`} style={{padding:"10px" }}>
                                        <td>{index + 1}</td>
                                        <td>{formatToCairoTime(code.expir)}</td>
                                        <td>{code.tries}</td>
                                        <td>{code?.codePrice}</td>
                                        <td>
                                            <Form>
                                                <Form.Check // prettier-ignore
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={code.active}
                                                    onChange={() => { changeActive(code._id) }}
                                                />
                                            </Form>
                                        </td>
                                        <td style={{ cursor: "pointer" }}>
                                            <DownloadExcellFile downloadRequestData={code._id} fileName={code._id} />
                                        </td>
                                        <td>
                                            {pdfReady ? <button onClick={() => { setRequestedSheet(code) }}>طلب الملف</button>
                                                : <PdfGenerator headers={headers} sheet={code} />
                                            }
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </Table>
                </div>
            </Modal >
        </>
    );
}

export default GetCodes;
