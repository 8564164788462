import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdAdd } from "react-icons/io";
import GetLessonById from './GetLessonById';
import { useDispatch, useSelector } from 'react-redux';
import { Create_Session } from '../../../../actions/sessionAction';
import { ErrorMsg, success } from '../../../../components/shared/modules/Notifications';

const CreateSession = ({ cls }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedLessons, setSelectedLessons] = useState([]);
    const [lessonId, setLessonId] = useState("");
    const [name, setName] = useState("");
    const [meetingType, setMeetingType] = useState("");
    const [homeWorktask, setHomeWorkTask] = useState("");
    const [desc, setDesc] = useState("");

    const handleMaterialChange = (e) => {
        const materialCode = e.target.value;
        setSelectedMaterial(materialCode);

        // Find the selected material and its lessons
        const material = cls?.packID?.materials.find(m => m.materialCode === materialCode);
        setSelectedLessons(material?.selectedLessons || []);
    }

    const loggedUser = useSelector((state) => state.loggedUser);


    const handleCreateSession = async () => {
        try {
            setLoading(true)
            const res = await dispatch(Create_Session({
                name: name,
                classId: cls?._id,
                materialCode: selectedMaterial,
                lesson: lessonId,
                homeworkTask: homeWorktask,
                description: desc,
                meetingService: meetingType
            }, loggedUser?.user?._id))
            console.log(res)
            if (res?.data?.case) {
                success(res?.data?.message)
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        } finally {
            setLoading(false)
        }
    }


    console.log("CLASSESS FOR SESSIONS  SSSSSSSS", cls)
    return (
        <div>
            <div className='w-100 text-center' onClick={handleShow}>
                <IoMdAdd siz={"25px"}  color='primary' />
            </div>
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>Create Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            {/* <input type="text" name="sessionName" id="sessionName" className='form-control' placeholder='Session Name'
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            /> */}
                            <input
                                type="text"
                                name="sessionName"
                                id="sessionName"
                                className='form-control'
                                placeholder='Session Name'
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>

                        <div>
                            <select className="form-control" onChange={handleMaterialChange}>
                                <option hidden>Select Material</option>
                                {
                                    cls?.packID?.materials.map(m => (
                                        <option value={m.materialCode}>{m.materialCode}</option>
                                    ))
                                }
                            </select>
                        </div>
                        {selectedMaterial && (
                            <div className="mt-3">
                                <select className="form-control" value={lessonId} onChange={(e) => setLessonId(e.target.value)}>
                                    <option hidden>Select Lesson</option>
                                    {selectedLessons.map(lesson => (
                                        <option key={lesson} value={lesson}>
                                            <GetLessonById lesson={lesson} />
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        <div>
                            {/* <input type="text" name="hw" id="hw" className='form-control' value={homeWorktask}
                                onChange={(e) => setHomeWorkTask(e.target.value)}
                                placeholder='HomeWork Task (optional)' /> */}
                            <input
                                type="text"
                                name="hw"
                                id="hw"
                                className='form-control'
                                value={homeWorktask}
                                onChange={(e) => setHomeWorkTask(e.target.value)}
                                placeholder='HomeWork Task (optional)'
                            />

                            {/* <input type="text" name="desc" id="desc" className='form-control'
                                onChange={(e) => setDesc(e.target.value)}
                                value={desc}
                                placeholder='Description (optional)' /> */}
                            <input
                                type="text"
                                name="desc"
                                id="desc"
                                className='form-control'
                                onChange={(e) => setDesc(e.target.value)}
                                value={desc}
                                placeholder='Description (optional)'
                            />
                        </div>

                        <div>
                            <select className='form-control' value={meetingType} onChange={(e) => setMeetingType(e.target.value)}>
                                <option hidden> Meeting Service Type</option>
                                <option value={"external"}>External</option>
                                <option value={"zoom"}>Zoom</option>
                                <option value={"vconnect"}>Vconnect</option>
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                    <button className='btn btn-primary' disabled={loading} onClick={handleCreateSession}>
                        {loading ? "تحميل" : "Create"}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateSession
