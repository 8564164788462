import React from 'react'
import "../../../styles/contact.css"
const SharedImg = ({img,
    title,
    line}) => {
    return (
        <div>
            <div className='m-auto sharedImage bg-primary'>
                <div className='contact' style={{height:"30vh"}}>
                    <img src={img} alt="data" className='w-100 h-100' />
                </div>

                <div className='infoContact'>
                    <div className='w-100'>{title}</div>
                    <div className='w-75'>{line}</div>
                </div>
            </div>
        </div>
    )
}

export default SharedImg
