import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MdDeleteForever } from "react-icons/md";
import { deleteLesson } from '../../../utils/lessons'; // Adjust the path if needed

function DeleteLesson({ lessonId,updateLessons }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const confirmDelete = async () => {
        try {
            await deleteLesson({_id:lessonId});
            await updateLessons()
            alert('تم حذف الدرس');
        } catch (error) {
            console.error('Error deleting lesson:', error);
            alert('حدث خطأ اثناء الحذف');
        } finally {
            closeModal();
        }
    };

    return (
        <div>
            <Button variant="danger" onClick={openModal}>
                <MdDeleteForever size={24} />
            </Button>
            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>تأكيد الحذف</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>هل انت متاكد انك تريد حذف الدرس؟</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        الغاء
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        تأكيد 
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DeleteLesson;
