
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Del_Zoom } from '../../../actions/zoomAction';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';
import { disabeledZoomUser } from '../../../utils/zoom';
const DeactiveZoom = ({ teacher }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);

    const loggedUser = useSelector((state) => state.loggedUser);
    const handleDeactive = async () => {
        setLoading(true)
        try {
            const res = await disabeledZoomUser(teacher?._id, loggedUser?.user?._id);
            console.log(res);
            if (res?.data?.case) {
                success("تم الغاء خاصية الzoom للمستخدم")
            }
        } catch (e) {
            console.log(e);
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data);
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <button className='btn btn-danger' onClick={handleShow}>Deactive Zoom</button>
            <Modal className='font' centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Deactive Zoom User</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to Deactive this Zoom User?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={handleDeactive} disabled={loading}>
                        {loading ? "loading" : "Add"}
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeactiveZoom
