import axios from 'axios';
import { urlBase } from './API';


// Chat Ai
export const aiConversation = (data, id) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/gemini/conversation?userId=${id}`,
        data: data
    })
}



// Chat files

export const getInlineData = (data) => {
    console.log(data)
    return axios({
        method: 'POST',
        url: `${urlBase}/api/gemini/inlineData`,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}


//Create Packages Ai
export const createPackageAi = (data) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/packages-subscriptions`,
        data: data
    })
}

//Create Packages Ai
export const getPackageAi = (data) => {
    return axios({
        method: 'GET',
        url: `${urlBase}/api/packages-subscriptions`,
        data: data
    })
}


// Delete Packae Ai

export const deletePackageAi = (id) => {
    return axios({
        method: 'DELETE',
        url: `${urlBase}/api/packages-subscriptions/${id}`,
    })
}


// Update Package Ai
export const updatePackageAi = (data, id) => {
    return axios({
        method: 'PATCH',
        url: `${urlBase}/api/packages-subscriptions/${id}`,
        data: data
    })
}


// booking Package Ai
export const bookPackage = (data, id, userId) => {
    console.log(id)
    console.log(userId)
    return axios({
        method: "POST",
        url: `${urlBase}/api/packages-subscriptions/${id}/subscribe?userId=${userId}`,
        data: data
    })
}


// renew Pacakge Ai

export const renewpackage = (data, id, userId) => {

    return axios(
        {
            method: "PATCH",
            url: `${urlBase}/api/packages-subscriptions/${id}/renew?userId=${userId}`,
            data: data
        }
    )
}



// Get My All booking Subscriptions
export const getAllPackageAi = (userId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/subscriptions/me?userId=${userId}`,
    })
}
