import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { MdDelete } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { Del_Zoom } from '../../../actions/zoomAction';
import { ErrorMsg } from '../../../components/shared/modules/Notifications';

const DeleteZoomUser = ({ zoomUserId }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();


    const handleDeleteZoomUser = async () => {
        setLoading(true)
        try {
            const res = await dispatch(Del_Zoom(zoomUserId))
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        } finally {
            setLoading(false)
        }
    }


    return (
        <div>
            <MdDelete size={"25px"} onClick={handleShow} color='red' />
            <Modal className='font' centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Delete Zoom User</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to delete this Zoom User?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={handleDeleteZoomUser}>Delete</Button>
                    <Button variant='secondary' onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteZoomUser
