import React, { useContext } from 'react'
import "./timeline.css"
import { ThemeContext } from '../../ThemeProvider';

const Timeline = ({ activeStep }) => {

    const {theme} = useContext(ThemeContext)
    const steps = [
        { id: 1, label: 'اختيار الفئة', isActive: activeStep >= 1 },
        { id: 2, label: 'اختيار المرحلة', isActive: activeStep >= 2 },
        { id: 3, label: 'اختيار الصف', isActive: activeStep >= 3 },
        { id: 4, label: 'اختيار المادة', isActive: activeStep >= 4 },
        { id: 5, label: 'اختيار الفصول', isActive: activeStep >= 5 },
        { id: 6, label: 'الدفع', isActive: activeStep >= 6 },
        { id: 7, label: 'تم', isActive: activeStep >= 7 }
    ];

    return (
        <div>
            <div className="timeline font">
                {steps.map((step) => (
                    <div key={step.id} className={`step ${step.isActive ? 'active' : ''}`}>
                        <div className="circle">{step.id}</div>
                        <div className="label" style={{color:theme === "light" ? "":"white"}}>{step.label}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Timeline
