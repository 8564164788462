import { useEffect, useRef, useState } from "react";
import { addNote, getResultByUserByTest } from "../../../../utils/results";
import { useDispatch, useSelector } from "react-redux";

import "./styles/StdResulte.css";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { set_teacher_selected_test } from "../../../../actions/login&register";

export default function ResultsTableComponent({
  resultsTable,
  setSelectedTest,
  setShowResultsComponent,
  selectedStudent,
}) {
  const dispatch = useDispatch();

  const [tableRow, setTableRow] = useState([]);
  const [userID, setUserID] = useState(null);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [selectedTestResult, setSelectedTestResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.loggedUser);
  const { toast } = useSelector(state => state.notify);


  const navigate = useNavigate();


  useEffect(() => {
    if (user.role === "parent") {
      setUserID(user?.childId);
    }
    else if (user.role === ("teacher" || "supervisor")) {
      setUserID(selectedStudent);
    }
    else if (user.role === "student") {
      //console.log('student.............',user);
      setUserID(user._id);
    }
  }, []);


  useEffect(() => {


    if (userID == null) {
      setTableRow(null);
      return;
    }

    const get = async () => {
      try {
        setLoading(true);

        let resultsArr = [];

        for (let lesson of resultsTable) {
          const name = lesson?.name;
          const testIDS = lesson?.testIDS;

          for (let id of testIDS) {
            let res = await getResultByUserByTest({
              userID: userID,
              testID: id,
            });
            // console.log("test", res);

            if (res.data.case) {
              const dateString = res?.data?.result?.date;
              const formattedDate = dateString.split("T")[0];

              let obj = {
                name,
                result: res?.data?.result,
                date: formattedDate,
              };

              resultsArr.push(obj);
            }
          }
        }
        setLoading(false);
        setTableRow(resultsArr);
        //setTableRow([]);

      } catch (err) {
        console.warn(err);
      }
    };
    //setLoading(true);
    get();
    // firstRenderRef.current=false;
    //setLoading(false);

  }, [userID]);

  const addNoteToStudent = async () => {
    try {
      const res = await addNote({
        id: selectedTestResult,
        note: newNote
      });

      if (res.data.case) {
        const updatedRows = tableRow.map(row => row.result._id === res.data.testResult._id ?
          {
            ...row,
            result: { ...row.result, note: res.data.testResult.note }
          }
          : row);

        console.log('res.data.testResult', res.data.testResult);

        setTableRow(updatedRows);

        toast().success(res.data.message, {
          position: "top-center"
        });

        setNewNote('');
        setShowAddNoteModal(false);
      }

      else {
        toast().error(res.data.message, {
          position: "top-center"
        });
      }


    } catch (error) {
      console.error(error.message);
    }

  }

  console.log("tablerow", tableRow);

  // console.log('firstRenderRef',firstRenderRef.current);
  //if(userID==null) return;


  return (
    <>
      <Modal show={showAddNoteModal} onHide={() => setShowAddNoteModal(false)} size="lg" className="font" >
        <Modal.Body style={{ direction: "rtl" }}>
          <label>اضافة ملاحظة</label>
          <input value={newNote} onChange={(e) => setNewNote(e.target.value)} className="form-control my-3" />
          <button onClick={() => addNoteToStudent()} className="btn btn-success">اضافة</button>
        </Modal.Body>
      </Modal>


      <div
        className="stdResults"
        style={{ direction: "rtl", overflow: "auto" }}
      >
        <table className="">
          <thead>
            <tr>
              <th>
                <div className="th_Results font">اسم الدرس</div>
              </th>
              <th>

                <div className="th_Results font">اسم الاختبار</div>
              </th>
              <th>

                <div className="th_Results font"> التاريخ </div>
              </th>
              <th>

                <div className="th_Results font"> عدد المحاولات </div>
              </th>
              <th>

                <div className="th_Results font"> الدرجة </div>
              </th>
              <th>
                <div className="th_Results font">الحالة</div>
              </th>
              <th>

                <div className="th_Results font"> ملاحظات </div>
              </th>
            </tr>
          </thead>
          <tbody>

            {
              loading ? <tr>
                <td colSpan={6}>
                  <Spinner />
                </td>
              </tr>
                : tableRow?.length === 0 ?
                  <tr>
                    <td colSpan={6} className="font">
                      لا توجد نتائج حاليا لهذه المادة
                    </td>
                  </tr>
                  :
                  tableRow?.map((el, i) => (
                    <tr key={i} className="font bodyTableExam">
                      <td>
                        <div className="d-flex flex-column font">
                          {el?.name}
                          <div
                            className="numOfQuestionBox font mx-auto"
                            onClick={() => {
                              dispatch(set_teacher_selected_test(el));

                              navigate('teacherCorrection', {
                                state: el
                              });
                              console.log('el....', el);
                            }}
                          >
                            {`عدد الاسئلة ${el?.result?.questions?.length}`}
                          </div>
                        </div>
                      </td>
                      <td className="font" style={{fontSize:"16px"}}>{el?.result?.testID?.name}</td>
                      <td>{el?.date}</td>
                      <td>{el?.result?.tries}</td>
                      <td>{el?.result?.result} / {el?.result?.testID?.tot_degree}</td>
                      <td className="font">
                        {
                          el?.result?.correctedQuestions === el?.result?.numberOfQuestions ?
                            'تم التصحيح' : ' لم يتم التصحيح'
                        }
                      </td>
                      <td>
                        {el?.result?.note ? el?.result?.note : "لا يوجد "}
                        <div className="mt-2">
                          {user?.role === "teacher" && (
                            <button
                              onClick={() => {
                                setShowAddNoteModal(true);
                                setSelectedTestResult(el?.result?._id);
                              }}
                              className="btn btn-success"
                            >
                              اضافة ملاحظة
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
