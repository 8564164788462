import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Container } from "react-bootstrap";
import Col from "../../styled-components/col";
import Contain from "../../styled-components/contain";
import "../teacher.css";
import NewAccount from "../newAccount/newAccount";
import { FaRegTrashAlt } from "react-icons/fa";
import { Delete_Teacher } from "../../../actions/teachers.js";

function SuperVisorAccounts({ type }) {
    const [render, setRender] = useState(false);
    const dispatch = useDispatch();
    const users = useSelector(state => state.users.supervisors);

    useEffect(() => {

    }, [render]);

    return (
        <Container className="teacher-admin-page" style={{ height: '100%' }}>
            <Col fg='1' dir='column'>
                <NewAccount type={type} />
                <Col width={"80%"} dir="column" align={"flex-start"}>
                    <div className="scroll-overflow" style={{ width: "100%" }}>
                        <Table responsive striped bordered hover className="font">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>الاسم</th>
                                    <th>البريد الالكترونى</th>
                                    <th>التاريخ</th>
                                    <th>حذف</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((t, i) => (
                                    <TeacherList 
                                        key={i} 
                                        item={t} 
                                        icon={
                                            <FaRegTrashAlt 
                                                size='20px'
                                                onClick={() => {
                                                    dispatch(Delete_Teacher(t._id, t.role));
                                                    setRender(!render);
                                                }} 
                                                style={{ 
                                                    cursor: "pointer", 
                                                }} 
                                            />
                                        } 
                                    />
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Col>
        </Container>
    );
}

function TeacherList({ item, icon }) {
    const { loggedUser } = useSelector(({ users, loggedUser }) => {
        return {
            loggedUser
        };
    });
    const IM = item.personalImage ? item.personalImage : '';

    return (
        <tr>
            <td style={{fontSize:"18px"}}><img src={IM} alt="supervisor" style={{ width: "50px", height: "50px" }} /></td>
            <td style={{fontSize:"18px"}}>{item.username}</td>
            <td style={{fontSize:"18px"}}>{item.email}</td>
            <td style={{fontSize:"18px"}}>{item.date.split('T')[0]}</td>
            <td style={{fontSize:"18px"}}>{icon}</td>
        </tr>
    )
}

export default SuperVisorAccounts;
