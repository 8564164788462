import axios from 'axios';
import { urlBase } from './API';

// create New Material
export const addMainMaterialtoBranch = (data, userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/main-material?userId=${userId}`,
        data: data //{name , levelId, branchId}
    })
}


// Delete Material
export const deleteMaterialFromBranch = (id, userId) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/main-material/${id}?userId=${userId}`
    })
}


// For Update Material 
export const updateMainMaterial = (data, id, userId) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/main-material/${id}?userId=${userId}`,
        data: data
    })
}



// For Get Filterd Main Materials
export const getAllMainMaterials = () => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/main-material`
    })
}


// For Get Filterd Main Materials
export const getAllMainMaterialsFilter = (level) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/main-material?levelId=${level}`
    })
}


// Get Active Classes MainMats
export const getClassesMainMatsActive = (id)=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/main-materials/${id}/classes?active=true&populate=packID,branchId`
    })
}