import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Delete_Transaction_Act } from '../../../actions/transactionAct';
import { ErrorMsg } from '../../../components/shared/modules/Notifications';


const DelteTrans = ({ transaction }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const loggedUser = useSelector((state) => state.loggedUser);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const handleDelete = async () => {
        setLoading(true)
        try {
            const res = await dispatch(Delete_Transaction_Act(transaction?._id, loggedUser?.user?._id));
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
            handleClose();
        }
    }
    return (
        <div>
            <MdDelete onClick={handleShow} size={"25px"} color='red' style={{ cursor: "pointer" }} />

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    Delete Transaction
                </Modal.Header>
                <Modal.Body>
                    Do You Want To Delete This Transaction?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary'>الغاء</Button>
                    <Button variant='danger' onClick={handleDelete} disabled={loading}>
                        {loading ? "Loading..." : "حذف"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DelteTrans
