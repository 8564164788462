import { DELETE_MEDIA, GET_ALL_MEDIA } from "../actions/mediaAction"


export const mediaRed = (state = [], action) => {
    switch (action.type) {
        case GET_ALL_MEDIA:
            return state = action.medias
        // case CREATE_NEW_PACKAGE:
        //   return state = [...state, action.pack]
        // case UPDATE_PACKAGE:
        //   return state = state.map(s => {
        //     if (s._id === action.pack._id) {
        //       return action.pack
        //     }
        //     return s
        //   })
        case DELETE_MEDIA:

            return state = state.filter(s => s._id !== action.id)
        default: return state
    }
}

