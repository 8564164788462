import React, { useEffect, useState } from 'react'
import { IoPersonAddSharp, IoCloseCircle } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { Distribute_Students_OnSuperVisors } from '../../../actions/class';
function AddStudentsToSuperVisor({ cls, supervisor }) {

  const [showSelectField, setShowSelectField] = useState(false);
  const [supervisors, setSuperVisors] = useState([]);
  const [students,setStudents] = useState([])
  const [studentsToAdd ,setStudentsToAdd] =useState([])
  // console.log('cls', cls)
  // console.log('super', supervisor)

  const dispatch = useDispatch();

  const { toast } = useSelector(state => state.notify)

  // on select 

  const addStudent = async (s) => {
    try {
      // console.log(s.value) 
      let student = s?.value;
      const res = await dispatch(Distribute_Students_OnSuperVisors({ studentID: student, materialCode: supervisor.materialCode, classID: cls._id, superV: supervisor.superV._id }));
      // console.log(res.data);
      if (res.data.case) {
        toast().success('تم اضافة الطالب');

      }
      else {
        toast().error(res.data.message)
      }
    }
    catch (err) {
      console.warn(err);
      toast().error(err.messagee)
    }
  }

  useEffect(()=>{
    const classStudents = cls.studentsIDS;
    const classSuperVisors = cls.supervisorIDS.filter(s=>s.materialCode===supervisor.materialCode);
    setStudents(classStudents);
    setSuperVisors(classSuperVisors);
  },[])
  useEffect(()=>{
    if(students){const filteredStudents = students.filter(st=>{
      const check = supervisors.find(su => su?.students.includes(st._id));
      console.log('check',check)
      if(check){
        return false
      }
      else{return true}
    })
    setStudentsToAdd(filteredStudents);}
  },[cls,students])

  console.log('sTA',studentsToAdd,students)
  return (
    <div>
      {!showSelectField ?

        <IoPersonAddSharp
          size={'25px'}
          color='#0F2A51'
          onClick={() => setShowSelectField(true)}
        />
        : <IoCloseCircle
          size={'25px'}
          color='#0F2A51'
          onClick={() => setShowSelectField(false)}
        />
      }
      {showSelectField ?
        <select onChange={(e) => addStudent(e.target)}>
          <option value={null}>اختر طالب</option>
          {studentsToAdd.map((s, i) => <option key={i} value={s._id} >{s.name || s.username}</option>)}
        </select> : <></>}
    </div>
  )
}

export default AddStudentsToSuperVisor