import axios from 'axios';
import { urlBase } from './API';

export const contactUsForm = (data) => {
    console.log(data)
    return axios({
        method: "POST",
        url: `${urlBase}/api/send/contact/message`,
        data: data
    })
}