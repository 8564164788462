import { useDispatch, useSelector } from "react-redux";
import "./TeacherClassesComponent.css";
import { useEffect, useState } from "react";
import { getAllSuperVisorOfClass, getTeacherClasses } from "../../../utils/class";
import ClassRowComponent from "./ClassRowComponent";
import { store_classes } from "../../../actions/login&register";
import { meeting_btn } from "../../../constants";
import AddClass from "../../../components-old/Admin/classControl/addClass";
import Classes from "../../../components-old/Admin/classControl/classes";


export default function TeacherClassesComponent() {
  const { user } = useSelector(state => state.loggedUser);
  const [classes, setClasses] = useState([]);
  const dispatch = useDispatch();



  useEffect(() => {
    const get = async () => {
      if (user?.role === 'teacher') {
        const response = await getTeacherClasses({ id: user?._id });
        setClasses(response?.data?.classes);
      }

      if (user?.role === 'supervisor') {
        const response = await getAllSuperVisorOfClass({ id: user?._id });
        setClasses(response?.data?.classes);
        dispatch(store_classes(response?.data?.classes));
      }

    }

    get();
  }, []);



  return (
    <>
      <div className="TeacherClassesComponent" style={{ direction: 'rtl' }}>
        {/* <button className="w-25 btn special color">انشاء فصل</button> */}
        {/* <AddClass /> */}
        {
          classes?.map(el => <ClassRowComponent
            key={el?._id}
            classObj={el}
            type={
              user?.role === 'teacher' ? 'class' : 'supervisor'
            }
          />)
        }
        <div className="container">
          <Classes />
        </div>
      </div>
    </>

  )
}
