import { NEW_CATEGORY,NEW_PRODUCT_TO_STORE,DELETE_PRODUCT,GET_STORE_PRODUCTS,NEW_ORDER_FROM_STORE,GET_ALL_STORE_ORDERS, GET_CATEGORIES, ACTION_ON_REQUEST } from "../actions/store"

export const store = (state={products:[],categories:[],orders:[],userOrders:[]},action)=>{
    switch(action.type){
        case NEW_CATEGORY:
            return state={...state,categories:[...state.categories,action.cat]}
        case GET_CATEGORIES:
            return state={...state,categories:action.cats}
        case NEW_PRODUCT_TO_STORE:
            return state={...state,products:[...state.products,action.product]}
        case DELETE_PRODUCT: 
            return state = {
                ...state,
                products:state.products.filter(p=>p._id!==action._id)
            }
        case GET_STORE_PRODUCTS:
            return state={...state,products:action.products}
        case NEW_ORDER_FROM_STORE: 
            return state={...state,userOrders:[...state.userOrders,action.order]}
        case GET_ALL_STORE_ORDERS:
            return state = {...state,orders:action.orders}
        case ACTION_ON_REQUEST:
            return state = {...state, orders:state.orders.map(o=>{
                if(o._id===action?.request?._id){
                    return action.request
                }
                return o
            })}
        default:return state
    }
} 