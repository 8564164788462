import React,{useState,useRef} from 'react'
// import "./newAccount.css"
import Modal from "../modal/modal.js";
import Col from "../styled-components/col";
import Contain from "../styled-components/contain";
import { FaReply } from "react-icons/fa";
import Input from "../input/input";
import serializeForm from "form-serialize";
import { useDispatch ,useSelector} from "react-redux";
import { 
    Send_Message
  } from "../../actions/login&register";
function SendMessage({to}) { // type mean type of account wll send a message
  const user = useSelector(mapStateToProps)
  const type= user.role;
  const sender = user._id;
  const [open,setOpen] = useState(false);
  const form = useRef();
  const dispatch=useDispatch();
  //-----------Create New Account Function------
  const createNew =(e)=>{
    e.preventDefault();
    const message = serializeForm(form.current, { hash: true });
    const data={message:message,to:to,sender:sender,type}
    data.type=type;
    data.date=new Date().toISOString();
    // console.log(data)
    dispatch(Send_Message(data));
    setOpen(false);
  }
  //---------------------------------------------
  //---------------------------------------------
  return (
    <>
    <div className='fcplus-cont'>
        <FaReply
          size="2em"
          onClick={() => setOpen(true)}
          style={{
            position: "absolute",
            // top: "10px",
            right: "5px",
            cursor: "pointer",
          }}
        />
        </div>
      <Modal
            open={open}
            onClose={() => setOpen(false)}
            width={"70vw"}
            height="60vh"
          >
            <form ref={form} onSubmit={createNew}>
              <Contain>
                <Col dir="column" fg="1" border="0px">
                  <Col
                    fg="1"
                    justify="space-around"
                    border="0px"
                    perfix="form-row-n"
                    width="100%"
                  >
                    <Input
                      name="title"
                      type="text"
                      placeholder="Title"
                      label="Title"
                    />
                  </Col>
                  <Col
                    fg="1"
                    justify="space-around"
                    border="0px"
                    perfix="form-row-n"
                    width="100%"
                  >
                    
                    <Input
                      name="content"
                      type="text"
                      placeholder="Content"
                      label="Content"
                    />
                   
                  </Col>
                
            
                  <input type="submit" name="Save" placeholder="Save" className='subm'/>
                </Col>
              </Contain>
            </form>
          </Modal>
    </>
  )
}
function mapStateToProps({loggedUser}){
  return loggedUser.user
}
export default SendMessage