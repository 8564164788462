import React from 'react'
import { Modal } from 'react-bootstrap'

export default function ConfirmModalComponent({ showModal, setShowModal, onClick }) {
    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} className='font'>
            <Modal.Body style={{ direction: "rtl" }}>
                <div>
                    <label>هل تريد تعيين الطالب كطالب الاسبوع</label>

                </div>

                <div className='mt-5'>

                    <button onClick={() =>{
                        onClick();
                        setShowModal(false);
                    } } className='btn addclass_btn'>
                        تأكيد
                    </button>

                </div>


            </Modal.Body>
        </Modal>
    )
}
