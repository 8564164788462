import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMsg } from '../../../components/shared/modules/Notifications';
import { Delete_Edu_Type } from '../../../actions/eduAction';

const DeleteEdu = ({ edu }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);


    const handleDelete = async () => {
        try {
            setLoading(true);
            const res = await dispatch(Delete_Edu_Type(edu?._id, loggedUser?.user?._id))
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <MdDelete onClick={handleShow} size={"24px"} color='red' />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Do You sure For Delete This Edu Type</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-secondary' onClick={handleClose}>Cancel</Button>
                    <Button className='btn-danger' onClick={handleDelete} disabled={loading}>
                        {loading ? "loading..." : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteEdu
