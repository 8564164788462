
import React, { useEffect, useState } from 'react';
import { getNextSession } from '../../../utils/class';
import { useSelector } from "react-redux";
import { GetMaterialsByIds } from '../../../utils/materials'; // Updated API call

const SchedualClass = () => {
    const [schedualRes, setSchedualRes] = useState([]);
    const [materialNames, setMaterialNames] = useState({});
    const loggedUser = useSelector((state) => state.loggedUser);

    // Function to get the next day date based on the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const getNextDayDate = (dayOfWeek) => {
        const today = new Date();
        const currentDay = today.getDay();
        const daysUntilNext = (dayOfWeek - currentDay + 7) % 7 || 7; // Calculate the difference from today to the next desired day
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + daysUntilNext);
        return nextDate.toLocaleDateString('ar-EG'); // Format the date as Arabic
    };

    // Function to map id to corresponding day of the week
    const getDayNameAndNextDate = (id) => {
        const daysMap = {
            1: { name: 'الاثنين', dayOfWeek: 1 },
            2: { name: 'الثلاثاء', dayOfWeek: 2 },
            3: { name: 'الأربعاء', dayOfWeek: 3 },
            4: { name: 'الخميس', dayOfWeek: 4 },
            5: { name: 'الجمعة', dayOfWeek: 5 },
            6: { name: 'السبت', dayOfWeek: 6 },
            7: { name: 'الأحد', dayOfWeek: 0 },
        };

        const dayInfo = daysMap[id] || { name: 'يوم غير معروف', dayOfWeek: null };
        if (dayInfo.dayOfWeek !== null) {
            return { dayName: dayInfo.name, nextDate: getNextDayDate(dayInfo.dayOfWeek) };
        } else {
            return { dayName: dayInfo.name, nextDate: '' };
        }
    };

    const handleSchedualStudent = async () => {
        try {
            const res = await getNextSession(loggedUser?.user?._id);
            if (res?.data?.case) {
                setSchedualRes(res?.data?.classes);

                // Extract material codes
                const materialCodes = res?.data?.classes.map(resItem => resItem?.nextSession?.materialCode);
                const uniqueCodes = [...new Set(materialCodes)];

                // Fetch material names in one request
                const response = await GetMaterialsByIds({ codes: uniqueCodes });

                // Map the fetched names to their codes
                const materialMap = response?.data?.materials?.reduce((acc, curr) => {
                    acc[curr.code] = curr.name;
                    return acc;
                }, {});

                setMaterialNames(materialMap);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleSchedualStudent();
    }, []);

    return (
        <div>
            <table className="table mt-5">
                <thead>
                    <tr>
                        <th>اسم الفصل</th>
                        <th>اسم الماده</th>
                        <th>اسم المعلم</th>
                        <th>اليوم</th>
                        <th>التاريخ</th>
                        <th>الوقت</th>
                    </tr>
                </thead>
                <tbody>
                    {schedualRes?.map((resItem) => {
                        const materialCode = resItem?.nextSession?.materialCode;
                        const materialName = materialNames[materialCode] || materialCode; // Show name if available, otherwise show code
                        const { dayName, nextDate } = getDayNameAndNextDate(resItem?.nextSession?.id); // Get day name and next date

                        return (
                            <tr key={resItem?.nextSession?.id}>
                                <td>{resItem?.class?.name}</td>
                                <td>{materialName}</td>
                                <td>{resItem?.nextSession?.data?.teacherName}</td>
                                <td>{dayName}</td> {/* Display the day name */}
                                <td>{nextDate}</td> {/* Display the next date */}
                                <td>{resItem?.nextSession?.time}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default SchedualClass;
