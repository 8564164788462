import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

function DeleteProductModal({removeFunc}) {
  const [show,setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    removeFunc();
    handleClose();
  };
  return (
<>
<p onClick={()=>setShow(true)}>
    حذف
</p>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>هل انت متاكد من رغبتك فى حذف المنتج؟</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            لا
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            موافق
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default DeleteProductModal