import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { AiOutlineFolderView } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Delete_Main_Material, Get_All_Main_Materials, Set_Main_Materials_By_Level, Update_Main_Material } from '../../../actions/mainMaterial';
import { ErrorMsg } from '../../../components/shared/modules/Notifications';

const ViewMainMaterial = ({ item }) => {
    console.log("item for levellllllllllllllll", item)
    const dispatch = useDispatch();
    const [showViewModal, setShowViewModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedName, setEditedName] = useState('');

    const handleViewClose = () => setShowViewModal(false);
    const handleViewShow = () => setShowViewModal(true);

    const handleDeleteClose = () => setShowDeleteModal(false);

    const handleDeleteShow = (mainMat) => {
        setSelectedMaterial(mainMat);
        setShowDeleteModal(true);
    };

    const loggedUser = useSelector((state) => state.loggedUser);

    const handleDeleteConfirm = async () => {
        try {
            const res = await dispatch(Delete_Main_Material(selectedMaterial?._id, item?._id, loggedUser?.user?._id));
            setShowDeleteModal(false);
        } catch (e) {
            ErrorMsg(e?.response?.data?.message);
        }
    };

    // Handle Update Material 
    const handleUpdateMaterial = async (mainMatId) => {
        try {
            const res = await dispatch(Update_Main_Material({
                name: editedName
            }, mainMatId, item?._id, loggedUser?.user?._id));
            if (res?.data?.case) {
                setEditMode(false);
            }
        } catch (e) {
            ErrorMsg(e?.response?.data?.message);
        }
    };

    const handleEditClick = (mainMat) => {
        setEditMode(true);
        setEditedName(mainMat?.name);
        setSelectedMaterial(mainMat);
    };


    const handleGetMatsByLevel = (id) => {
        console.log("id", id)
        try {
            const res = dispatch(Set_Main_Materials_By_Level(id));
            console.log(res)
        } catch (e) {
            console.log(e)
        }
    }


    const mainMats = useSelector((state) => state.mainMaterialRed);

    console.log("Main Mats", mainMats)


    useEffect(() => {
        dispatch(Get_All_Main_Materials());
        handleGetMatsByLevel(item?._id);
    }, [item])




    return (
        <div>
            <AiOutlineFolderView onClick={handleViewShow} color="#0F2A51" size={30} />

            {/* View Main Material Modal */}
            <Modal show={showViewModal} onHide={handleViewClose} centered className='font'>
                <Modal.Header closeButton>
                    <Modal.Title>Main Material Branch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mainMats?.filter(mat => mat.levelId === item._id).map((mainMat) => {
                                return (
                                    <tr key={mainMat._id}>
                                        <td>
                                            {editMode && selectedMaterial?._id === mainMat._id ? (
                                                <input
                                                    type="text"
                                                    value={editedName}
                                                    className='form-control'
                                                    onChange={(e) => setEditedName(e.target.value)}
                                                />
                                            ) : (
                                                mainMat.name
                                            )}
                                        </td>
                                        <td>
                                            {editMode && selectedMaterial?._id === mainMat._id ? (
                                                <Button onClick={() => handleUpdateMaterial(mainMat._id)}>Save</Button>
                                            ) : (
                                                <FaEdit onClick={() => handleEditClick(mainMat)} style={{ cursor: 'pointer' }} />
                                            )}
                                        </td>
                                        <td><MdDelete onClick={() => handleDeleteShow(mainMat)} style={{ cursor: 'pointer' }} /></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleViewClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={handleDeleteClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the material?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ViewMainMaterial;
