import React, { useEffect } from 'react'
import TeacherCards from '../../components/TeacherPages/TeacherCards'
import PageContainer from '../../components/layout/PagecContainer'
import { useDispatch, useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
import TeacherCard from '../../components/shared/modules/TeacherCard';
import { Inetialize_Users } from '../../actions/teachers';


const TeacherPagePrograms = () => {

    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(Inetialize_Users('teacher'))
    },[])




    const teachers = useSelector(state => state.users.teachers);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    return (
        <div>
            <PageContainer>
                <div id="main-teacher" style={{ marginTop: "120px" }}>
                    <div className="sharedBackground font">
                        <p>{HomePage.teachers[lang]}</p>
                    </div>
                    <div className='container'>
                        <div className='row  justify-content-center align-items-start'>
                            {
                                teachers ? (
                                    teachers.map((teacher) => {
                                        return (
                                            <div className='col-lg-3 col-sm-6 col-md-4' key={teacher._id}>
                                                <TeacherCard teacher={teacher} />
                                            </div>
                                        )
                                    })
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default TeacherPagePrograms
