import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from '../../../utils/Translation/HomePage';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import { useParams } from 'react-router-dom';
import { Create_New_Package } from '../../../actions/packages';
import GetLessonById from '../../Admin/classControl/sessions/GetLessonById';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { success } from '../../../components/shared/modules/Notifications';
const CreateCourse = ({
    show,
    handleClose,
    material,
}) => {
    console.log("material Handle",material)
    const [lessons, setLessons] = useState([]);
    const handleGetLessons = async () => {
        try {
            setLoading(true);
            const lessonsData = await getLessonsOfSpecificMaterial({ code: material?.code });
            setLessons(lessonsData.data.lessons);
        } catch (error) {
            console.error('Error fetching lessons:', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (show) {
            handleGetLessons();
        }
    }, [show, material]);

    const [image, setImage] = useState(null);
    const [cover, setCover] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [name, setName] = useState('');
    const [price, setPrice] = useState(null);
    const [discountPrice, setDiscountPrice] = useState(null);
    const [description, setDesc] = useState(null);

    const loggedUser = useSelector((state) => state.loggedUser);

    const [selectedLessons, setSelectedLessons] = useState([]);
    const handleLessonSelect = (e) => {
        const lessonId = e.target.value;
        if (!selectedLessons.includes(lessonId)) {
            setSelectedLessons([...selectedLessons, lessonId]);
        }
    };

    const removeLessonFromSelection = (lessonToRemove) => {
        setSelectedLessons(selectedLessons.filter(lesson => lesson !== lessonToRemove));
    };

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            const data = {
                materials: [
                    {
                        materialCode: material.code,
                        selectedLessons: selectedLessons,
                    }
                ],
                teacher:material?.teacherId?._id,
                price,
                discountPrice,
                name,
                description,
            };
            // console.log('package' , data)
            setLoading(true);
            let upladCoverRes
            if (image) {

                upladCoverRes = await uploadingFileLocally(image);
                if (!upladCoverRes.data.case) throw new Error('خطا فى تحميل الملف!');
                data.coverImage = upladCoverRes?.data?.data._id;
            }

            const res = await dispatch(Create_New_Package(data, loggedUser?.user?._id));

            setMessage(res.data.message);

            setLoading(false);
            if (res?.data?.case) {
                success("Created success");
                handleClose();
            }
        }
        catch (err) {
            console.warn(err);
            // toast().error(err?.message);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }


    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`,
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );

            }
        })
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='mt-3'>
                            {/* <label>Coure Image</label> */}
                            <Form.Group controlId="program-cover-image">
                                <Form.Label>صورة البرنامج </Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(event) => {
                                        setImage(event.target.files[0]);
                                        const url = window.URL.createObjectURL(event.target.files[0]);
                                        setCover(url)

                                    }}
                                />
                            </Form.Group>
                            {cover ?
                                <img width="300px" src={cover} alt="imageprogram" /> : <></>}
                        </div>
                        <div className='mt-3'>
                            <label>Coure Name</label>
                            <input type="text" name="courseName" id="coureName" className='form-control' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='mt-3'>
                            <label>Coure Description</label>
                            <textarea name="desc" id="desc" className='form-control' style={{ resize: "none" }}
                                value={description} onChange={(e) => setDesc(e.target.value)}
                            />
                        </div>
                        <div className='mt-3'>
                            <label>Price</label>
                            <input type="number" name="price" id="price" className='form-control'
                                value={price} onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>
                        <div className='mt-3'>
                            <label>Discount Price</label>
                            <input type="number" name="price" id="price" className='form-control'
                                value={discountPrice} onChange={(e) => setDiscountPrice(e.target.value)}
                            />
                            <small style={{ color: "grey" }}>{HomePage.disPrice[lang]}</small>
                        </div>
                        <div className='mt-3'>
                            <label>Choose Lessons</label>
                            <select
                                className='form-control'
                                onChange={handleLessonSelect}
                                value=""
                            >
                                <option value="" hidden>Select Lesson</option>
                                {lessons?.map((lesson) => (
                                    <option key={lesson?._id} value={lesson?._id}>
                                        {lesson?.name}
                                    </option>
                                ))}
                            </select>

                            <div className="selected-lessons mt-2">
                                {selectedLessons.map((lessonId) => {
                                    const lessonObj = lessons.find(l => l._id === lessonId);
                                    return (
                                        <span
                                            key={lessonId}
                                            className="badge bg-primary me-2 mb-2"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => removeLessonFromSelection(lessonId)}
                                        >
                                            {lessonObj?.name || lessonId} ✕
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={loading} onClick={handleSubmit}>
                        {loading ? <Spinner animation="border" size="sm" /> : "Create"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateCourse
