//  upload image
import axios from 'axios';
import {urlBase} from './API';
// import { Uploading_Progress } from '../actions/generalActions';
// import {useDispatch } from "react-redux";
// const dispatch = useDispatch();
export const uploadingImageOfLesson = (data) => {
  console.log('data',data)
  return axios({
    method:'POST',
    url:`${urlBase}/api/upload-image`,
    data:data,
    onUploadProgress:(progressEvent)=>{
      const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      // dispatch(Uploading_Progress(percentage))
      // Uploading_Progress()
    }
  }) 
    }
  
  export const uploadingFileLocally = (data)=>{
    return axios({
      method:"POST",
      url:`${urlBase}/api/upload-file-locally?userId=${data?.user}`,
      data:data
    })
  }

  export const uploadSingleFile = (data)=>{
    return axios({
     method:"POST",
     url:`${urlBase}/api/upload`,
     data:data
    }) 
  }