import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IoVideocam, IoVideocamOff, IoMicCircleSharp, IoMicOffCircleSharp } from "react-icons/io5";
import './video.css';
import { TbArrowsJoin } from "react-icons/tb";
import Peer from 'peerjs';
import { ToastContainer, toast } from 'react-toastify';
import { getUsersByIDS } from '../../utils/API';
import Room from './room';
import Meeting from './meeting';
function InetializeRoom() {
  const [camera, setCamera] = useState(true);
  const [audio, setAudio] = useState(true);
  const socketRef = useSelector(state => state.meeting.socketRef);
  const myStream = useRef(null);
  const { roomId, id } = useParams();
  const [userPeer, setUserPeer] = useState(null);

  const [joiningRoom, setJoiningRoom] = useState(false);
  const [ready, setReady] = useState(false)
  const [usersRoom, setUsersInRoom] = useState([]);
  const [usersRoomData, setUsersRoomData] = useState([]);
  const [peers, setPeers] = useState([]);
  const [usersStreams, setUsersStreams] = useState([]);
  const [joined, setJoined] = useState(false)

  const getUserMedia = async (constraints) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      myStream.current.srcObject = stream;
    } catch (error) {
      console.error('Error accessing media devices.', error);
      alert('Permissions to access camera and microphone are required.');
    }
  };

  const ToggleLocalCamera = async () => {
    const tracks = myStream.current.srcObject.getTracks();
    tracks.forEach((track) => {
      if (track.kind === "video") {
        track.enabled = !camera;
      }
    });
    setCamera(!camera);
  };

  const ToggleLocalAudio = async () => {
    const tracks = myStream.current.srcObject.getTracks();
    tracks.forEach((track) => {
      if (track.kind === "audio") {
        track.enabled = !audio;
      }
    });
    setAudio(!audio);
  };

  const openedIconsStyle = {
    color: 'red',
    cursor: 'pointer'
  };
  const closedIconsStyle = {
    color: '#333',
    cursor: 'pointer'
  };

  useEffect(() => {
    getUserMedia({ video: true, audio: true });

  }, [joined]);

  const joinRoom = () => {
    try {
      if (socketRef && socketRef.current) {
        setJoiningRoom(true)
        // const myPeer = new Peer();
        // myPeer.userId=id
        // myPeer.on('open', (peerId) => {
        //   setUserPeer(myPeer);
        // });
        // socketRef.current.emit('join-room', { roomId, userId: id, peerId:null });

      }
    }
    catch (err) {
      console.warn(err)
      toast.error(err.message)
      setJoiningRoom(false)
    }
  }

  useEffect(() => {
    try {
      // socketRef?.current.on("users in room",({usersInRoom})=>{
      //   setUsersInRoom(usersInRoom);
      // })
      setJoiningRoom(false);
      setReady(true);
    }
    catch (err) {
      toast.error(err.message)
    }
  }, [])



  useEffect(() => {
    if (usersRoom.length) {

      const getUsersData = async (ids) => {
        try {
          const users = await getUsersByIDS({ IDS: ids });
          setUsersRoomData(prev => [...prev, ...users?.data?.users])
        }
        catch (err) {
          console.warn(err);
          toast.error(err.message);
        }
      }
      const filteredUsers = usersRoom.filter(u => {
        const uu = usersRoomData.find(s => s._id === u.userId);
        if (uu) return false;
        else return true;
      });

      const ids = filteredUsers.map(u => u?.userId);
      getUsersData(ids);

    }
  }, [usersRoom]);

  // console.log("users",usersRoom,usersRoomData);




  const Entering_Room = () => {
    try {
      setJoined(true)
    }
    catch (err) {
      toast.error(err.message)
      console.warn(err)
    }
  }

  return (
    <>
      <ToastContainer />
      {!joined ?

        <Container>

          <Row>
            <Col md={12} lg={12}>
              <Container>
                <Row className="justify-content-center">
                  <Col xs={12} md={10} lg={8}>
                    <video ref={myStream} autoPlay muted className="inetialize-my-video" />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <div onClick={ToggleLocalCamera} style={{ textAlign: 'center' }}>
                      {camera ? <IoVideocam size="50px" style={openedIconsStyle} /> : <IoVideocamOff size="50px" style={closedIconsStyle} />}
                    </div>
                  </Col>
                  <Col xs="auto">
                    <div onClick={ToggleLocalAudio} style={{ textAlign: 'center' }}>
                      {audio ? <IoMicCircleSharp size="50px" style={openedIconsStyle} /> : <IoMicOffCircleSharp size="50px" style={closedIconsStyle} />}
                    </div>
                  </Col>
                  <Col xs="auto">
                    {!ready ? <button
                      className='join-button'
                      style={{
                        border: '0px',
                        background: '#8C1C13',
                        color: '#fff'
                      }}
                      onClick={() => joinRoom()}>
                      <TbArrowsJoin size={'40px'}

                      />
                      دخول الغرفة
                    </button> :
                      <>
                        {
                          joiningRoom ?
                            <button className='join-button'>
                              Loading ...
                            </button>
                            :
                            <button className='join-button' onClick={() => Entering_Room()}>
                              انضمام
                            </button>
                        }
                      </>


                    }
                  </Col>
                </Row>
              </Container>
            </Col>
            {/* <Col md={3} lg={4}>
        <ol>
          <h5>Users In Room:</h5>
          {usersRoomData?.map((u,i)=><li key={i}>{u.name}</li>)}
        </ol>
        </Col> */}
          </Row>

        </Container>
        :
        <>
          {/* <Room 
      camera={camera}
      setCamera={setCamera}
      audio={audio}
      setAudio={setAudio}
      setPeers={setPeers}
      // ref={{myStream,userPeer}}
      userPeer={userPeer}
      usersRoom={usersRoom}
      setUsersInRoom={setUsersInRoom}
      id={id}
      roomId={roomId}
      toast={toast}
      
    /> */}
          <Meeting
            setJoined={setJoined}
            cameraState={camera}
            audioState={audio}
          />
        </>
      }
    </>
  );
}

export default InetializeRoom;
