import axios from 'axios';
import { urlBase } from './API';

// send new message
//{ownerID,ownerRole,contactID,contactRole,message}
export const sendNewMessage = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/send-message`,
        data:data// {ownerID,ownerRole,contactID,contactRole,date,message}
    })
}



// get all user contacts and messages 
//{ownerID}
export const getAllMyMessages = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/get-all-my-contacts`,
        data:data// {ownerID}
    })
}

// get my messages with single contact 
// {ownerID,contactID}
export const getMyMessagesWithContact = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/get-contact`,
        data:data // {ownerID,contactID}
    })
}

// get All contacts for all  users
// {} empty object sent
export const getAllContactsMessages = (data)=>{
    return axios({
     method:'POST',
     url:`${urlBase}/api/get-all-contacts`,
     data:data //{}
    })
}

// update newMessages notification 
// {userID,role}
export const updateNewMessagesNotification = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}`,
        data:data //{userID,role}
    })
}
