import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaEdit } from "react-icons/fa";
import { getSinglePackageSubscription } from '../../../utils/packagesubscriptions';
import Form from 'react-bootstrap/Form';
import { Get_All_Packages_Subscriptions, Update_Packages_Subscriptions } from '../../../actions/packagesubscriptions';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
const UpdateServiceModal = ({ subId }) => {
    const [show, setShow] = useState(false);
    const [singleSub, setSingleSub] = useState(null)
    const [namePackage, setNamePackage] = useState(null);
    const [desc, setDesc] = useState(null);
    const [priceMonth, setPriceMonth] = useState(null);
    const [discountMonth, setDiscountMonth] = useState(null);
    const [yearPrice, setYearPrice] = useState(null);
    const [discountPrice, setDiscountPrice] = useState(null);
    const [typePackage, setTypePackage] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [trialDuration, setTrialDuration] = useState(null);
    const [image, setImage] = useState(null);
    const [cover, setCover] = useState(null);
    const [trial, setTrial] = useState(null);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const loggedUser = useSelector((state) => state.loggedUser)


    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`,
            data: formData,
        })
    }

    const fetchSingleSub = async () => {
        try {
            const res = await getSinglePackageSubscription({
                id: subId
            })


            console.log(res)

            setSingleSub(res?.data?.data?.data)

            if (res?.data?.case) {
                setNamePackage(res?.data?.package?.name)
                setDesc(res?.data?.package?.description)
                setPriceMonth(res?.data?.package?.monthlyPrice)
                setDiscountMonth(res?.data?.package?.discountMonthlyPrice)
                setYearPrice(res?.data?.package?.annuallyPrice)
                setDiscountPrice(res?.data?.package?.discountAnnuallyPrice)
                setTypePackage(res?.data?.package?.packageType)
                setMaxValue(res?.data?.package?.maxValue)
                setTrialDuration(res?.data?.package?.trialDuration)
                setCover(res?.data?.package?.coverImage)
                setImage(res?.data?.package?.coverImage)
                setTrial(res?.data?.package?.trial)
            }
        } catch (e) {
            console.log(e)
        }
    }


    const updatePackage = async () => {
        try {
            const photoRes = await uploadingFileLocally(image);
            const uploadedPhotoName = photoRes?.data?.data?.name;
            const res = await dispatch(Update_Packages_Subscriptions({
                name: namePackage,
                monthlyPrice: priceMonth,
                discountMonthlyPrice: discountMonth,
                annuallyPrice: yearPrice,
                discountAnnuallyPrice: discountPrice,
                packageType: typePackage,
                trial: true,
                trialDuration: trialDuration,
                maxValue: maxValue,
                description: desc,
                coverImage: uploadedPhotoName || image
            }, subId))

            console.log(res)
            if (res?.data?.status === "success") {
                success("تم تعديل الباقه")
                dispatch(Get_All_Packages_Subscriptions())

            }
        } catch (e) {
            console.log(e)
            ErrorMsg(`حدث خطا ما`)
        }
    }



    useEffect(() => {
        if (show) {
            fetchSingleSub();
        }
    }, [subId, show])


    return (
        <div>
            <FaEdit size={"20px"} onClick={handleShow} />

            <Modal show={show} onHide={handleClose} centered className='font' style={{ textAlign: "right" }}>
                <Modal.Header>
                    <Modal.Title>تعديل الباقه</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>اسم الباقه</label>
                            <input type="text" name="namePackage" id="namePackage" className='form-control mt-3' value={namePackage}
                                onChange={(e) => setNamePackage(e.target.value)}
                            />
                        </div>
                        <div className='w-100'>
                            <label>صورة الباقه</label>
                            {/* <img src={`${urlBase/${cover}`} /> */}
                            <img src={`${urlBase}/${cover}`} alt="coverService" className='w-100' />
                        </div>
                        <div className='w-100'>
                            <label> تغيير صورة الباقه </label>
                            <input type='file' name="photoService" id="photoService"
                                className='form-control'
                                onChange={(event) => {
                                    setImage(event.target.files[0]);
                                    const url = window.URL.createObjectURL(event.target.files[0]);
                                    // setCover(url)

                                }}
                            />
                        </div>
                        <div>
                            <label>وصف الباقه</label>
                            <textarea type="text" name="desc" id="desc" className='form-control mt-3' style={{ resize: "none" }}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                            ></textarea>
                        </div>
                        <div>
                            <label>سعر الباقه الشهريه</label>
                            <input type="number" name="monthPrice" id="monthPrice" className='form-control mt-3'
                                value={priceMonth}
                                onChange={(e) => setPriceMonth(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>قيمة الخصم علي الباقه الشهريه</label>
                            <input type="number" name="discoutMonth" id="discoutMonth" className='form-control mt-3'
                                value={discountMonth}
                                onChange={(e) => setDiscountMonth(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>سعر الباقه السنويه</label>
                            <input type="number" name="yearPrice" id="yearPrice" className='form-control mt-3'
                                value={yearPrice}
                                onChange={(e) => setYearPrice(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>قيمة الخصم علي الباقه السنويه</label>
                            <input type="number" name="priceYear" id="priceYear" className='form-control mt-3'
                                value={discountPrice}
                                onChange={(e) => setDiscountPrice(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>نوع الباقه</label>
                            <br />
                            <select className='form-control' onChange={(e) => setTypePackage(e.target.value)} value={typePackage}>
                                <option hidden >اختر نوع الباقه</option>
                                <option value="STORAGE">STORAGE</option>
                                <option value="STUDENT">STUDENT</option>
                            </select>
                        </div>
                        <div>
                            <label>اقصي عدد لطلاب</label>
                            <input type="number" name="maxValue" id="maxValue" className='form-control mt-3'
                                onChange={(e) => setMaxValue(e.target.value)}
                                value={maxValue}
                            />
                        </div>
                        <div className='mt-3'>
                            <select className="form-control" onChange={(e) => setTrial(e.target.value)}>
                                <option value={true}>سماح بالفتره التجريبيه</option>
                                <option value={false}>لا توجد فتره تجريبيه</option>
                            </select>
                        </div>

                        <div className='mt-3'>
                            <label>حدد المده التجريبيه</label>
                            <input type="number" name="trialPeriod" id="trialPeriod" className='form-control mt-3'
                                value={trialDuration}
                                onChange={(e) => setTrialDuration(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        الغاء
                    </Button>
                    <Button variant="primary" onClick={updatePackage}>
                        حفظ التغييرات
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateServiceModal
