// Level.js
import React, { useEffect, useState } from 'react';
import { getLevelById } from '../../utils/API';

const GetLevelName = ({ levelId }) => {
    const [levelName, setLevelName] = useState('');

    useEffect(() => {
        const fetchLevelName = async () => {
            const level = await getLevelById({ _id: levelId });
            setLevelName(level?.data?.level?.name || 'Unknown');
        };

        if (levelId) {
            fetchLevelName();
        }
    }, [levelId]);

    return (
        <span>{levelName}</span>
    );
};

export default GetLevelName;
