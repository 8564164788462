import React, { useState, useRef } from 'react';
import "./newAccount.css";
import Modal from "../../modal/modal.js";
import Col from "../../styled-components/col";
import Contain from "../../styled-components/contain";
import { FcPlus } from "react-icons/fc";
import Input from "../../input/input";
import serializeForm from "form-serialize";
import { useDispatch, useSelector } from "react-redux";
import {
  Register_Admin,
  Register_Teacher,
} from "../../../actions/login&register";
import { ErrorMsg } from '../../../components/shared/modules/Notifications.js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import { urlBase } from '../../../utils/API.js';
function NewAccount({ type, setRender, render }) {
  const [open, setOpen] = useState(false);
  const form = useRef();
  const dispatch = useDispatch();
  const { toast } = useSelector(state => state.notify);

  const [image, setImage] = useState(null);
  const [phone, setPhone] = useState(null);
  const handlePhone = (valuePhone) => {
    setPhone(valuePhone)
  }

  const loggedUser = useSelector((state) => state.loggedUser);

  function uploadingFileLocally(data) {
    const formData = new FormData();
    formData.append("file", data)
    return axios({
      method: "POST",
      url: `${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`,
      data: formData,
    })
  }

  const [loading, setLoading] = useState(false)

  //-----------Create New Account Function------
  const createNew = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const data = serializeForm(form.current, { hash: true });
      const uploadPersonImage = await uploadingFileLocally(image)
      if (!uploadPersonImage.data.case) throw new Error('خطا فى تحميل الملف!');
      data.personalImage = uploadPersonImage?.data?.data.name;
      console.log(data);
      if (!data.name) {
        return toast().error(`"name" is required! `);
      }
      if (!data.email) {
        return toast().error(`"email" is required! `);
      }
      if (!data.password) {
        return toast().error(`"password" is required! `);
      }
      // if (!data.phone) {
      //   toast().error(`"phone" is required! `);
      // }
      data.phone = `+${phone}`
      data.role = type;
      const today = new Date();
      data.date = today.toISOString();
      if (type === "student") {
        await dispatch(Register_Teacher({
          student: data
        }));
      } else if (type === "teacher") {
        await dispatch(Register_Teacher({
          teacher: data
        }));
      } else if (type === "supervisor") {
        await dispatch(Register_Teacher({
          supervisor: data
        }));
      }
      if (setRender) {
        setRender(!render);
      }
      setOpen(false);
    } catch (e) {
      ErrorMsg(e?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  };

  const branches = useSelector((state) => state.branchs);



  return (
    <>
      <div className='fcplus-cont'>
        <FcPlus
          size="2em"
          onClick={() => setOpen(true)}
          style={{
            position: "absolute",
            right: "5px",
            cursor: "pointer",
            color: '#0F2A51'
          }}
          className='addPlus'
        />
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        width={"70vw"}
        height="60vh"
        className="font"
        centered
      >
        <form ref={form} onSubmit={createNew}>
          <Contain>
            <Col dir="column" fg="1" border="0px">
              <Col
                fg="1"
                justify="space-between"
                border="0px"
                perfix="form-row-n"
                width="80%"
              >
                <input
                  type="text"
                  name="name"
                  placeholder="Full name"
                  label="Full name"
                  className="form-control mx-2"
                />

                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  className="form-control mx-2"
                  label="Username"
                />
              </Col>
              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="80%"
              >
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  className='form-control mx-2'
                />

                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  className='form-control mx-2'
                />
              </Col>

              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="80%"
              >
                {/* <input
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  className='form-control mx-2'
                /> */}

                <PhoneInput
                  country={'eg'}
                  name="phone"
                  style={{ width: "100%" }}
                  value={phone}
                  onChange={(phone) => handlePhone(phone)}
                />

                <input type="file" className="form-control" onChange={(event) => {
                  setImage(event.target.files[0]);
                  const url = window.URL.createObjectURL(event.target.files[0]);


                }} />
              </Col>

              {
                loading ? 'loading...' : <input
                  type="submit"
                  name="Save"
                  placeholder="Save"
                  className='subm form-control'
                  style={{
                    width: "20%",
                    margin: "auto",
                    background: "#0F2A51",
                    textAlign: "center"
                  }}
                />
              }
            </Col>
          </Contain>
        </form>
      </Modal>
    </>
  );
}

export default NewAccount;
