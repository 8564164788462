import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { ThemeContext } from '../../../ThemeProvider';
import { HomePage } from "../../../utils/Translation/HomePage";
import { IoSettingsSharp, IoLockClosedSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { ErrorMsg } from "../../shared/modules/Notifications";
import { checkCompleteRegisterTeacher, checkTypevalidation } from "../../../utils/API";
import { Modal, Button } from "react-bootstrap"; // استيراد Modal و Button
import { useLocation } from 'react-router-dom';

const SidNav = ({ minimized, user }) => {
    const location = useLocation();
    const keyState = location.state?.key;
    const { theme } = useContext(ThemeContext);
    const loggedUser = useSelector((state) => state.loggedUser);
    const [message, setMessage] = useState("")
    const [key, setKey] = useState("")
    const [isClassesDisabled, setIsClassesDisabled] = useState(keyState ? keyState : false);
    const [showModal, setShowModal] = useState(false);  // للتحكم في ظهور الـ Modal
    const [isCompleteProfile, setIsCompleteProfile] = useState(true);


    const handleTypeValidationPackage = async () => {
        try {
            const res = await checkTypevalidation(loggedUser?.user?._id);
            if (res?.data?.subscription?.status) {
                setIsClassesDisabled(false);
            } else {
                setIsClassesDisabled(true);
                setMessage(res?.data?.subscription?.message)
                setKey(res?.data?.subscription?.key)
            }
        } catch (e) {
            ErrorMsg(e?.response?.data?.message);
        }
    };


    const checkComplete = async () => {
        try {
            const checkComplete = await checkCompleteRegisterTeacher(loggedUser?.user?._id);
            setIsCompleteProfile(checkComplete?.data?.case);
        } catch (e) {
            console.log(e)
            setIsCompleteProfile(e?.response?.data?.case);
        }
    }

    useEffect(() => {
        handleTypeValidationPackage();
        checkComplete();
    }, []);

    const { lang, dir } = useSelector(({ language }) => ({
        lang: language.lang,
        dir: language.dir,
    }));

    console.log(isCompleteProfile)
    let taps = [];

    if (user?.user?.role === "student") {
        taps = [
            { name: `التحويلات`, path: "my-transactions" },
            { name: `${HomePage.dashboard[lang]}`, path: "profile" },
            { name: `${HomePage.subscriptionsRequest[lang]}`, path: "subscriptions" },
            { name: `${HomePage.messages[lang]}`, path: "messages" },
            { name: `${HomePage.results[lang]}`, path: "results" },
            { name: `${HomePage.dashboard_programs[lang]}`, path: "subjects" },

        ];
    } else if (user?.user?.role === "teacher") {
        // taps = [
        //     { name: `${HomePage.subscriptions[lang]}`, path: "subscriptions" },
        //     { name: `${HomePage.students[lang]}`, path: "my-students" },
        //     { name: `${HomePage.programs[lang]}`, path: "programs-teacher" },
        //     { name: `${HomePage.create_Program[lang]}`, path: "create-program-teacher" },
        //     { name: `${HomePage.classes[lang]}`, path: "classes", disabled: isClassesDisabled },
        //     { name: `${HomePage.materials[lang]}`, path: "my-materials" },
        //     { name: `${HomePage.dashboard_society[lang]}`, path: "society" },
        //     { name: `${HomePage.personalInformation[lang]}`, path: "profile" },
        // ];
        if (isCompleteProfile) {
            taps = [
                { name: `التحويلات`, path: "my-transactions" },
                { name: `${HomePage.subscriptions[lang]}`, path: "subscriptions" },
                { name: `${HomePage.students[lang]}`, path: "my-students" },
                { name: `${HomePage.programs[lang]}`, path: "programs-teacher" },
                { name: `media`, path: "my-media" },
                // { name: `${HomePage.create_Program[lang]}`, path: "create-program-teacher" },
                { name: `${HomePage.classes[lang]}`, path: "classes", disabled: isClassesDisabled },
                { name: `${HomePage.materials[lang]}`, path: "my-materials" },
                { name: `${HomePage.dashboard_society[lang]}`, path: "society" },
                { name: `${HomePage.personalInformation[lang]}`, path: "profile" },

            ];
        } else {
            taps = [
                { name: "Complete Your Profile", path: "complete-profile" }
            ];
        }
    }

    const navigate = useNavigate();

    const handleSettingRoute = () => {
        navigate('settings');
    };

    const handleTabClick = (disabled) => {
        console.log("data")
        if (disabled) {
            setShowModal(true);
            console.log(disabled)
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <div className={`sidnav ${theme}`} >
                <div className="side-menu">
                    {taps.map((t, index) => {
                        return t.disabled ? (  // التحقق إذا كان التبويب معطلاً
                            <p
                                onClick={() => handleTabClick(t.disabled)}
                                key={index}
                                style={{
                                    textDecoration: "none",
                                    color: `${theme === "light" ? "#2C6DCE " : "white"}`,
                                    opacity: t.disabled ? 0.5 : 1,
                                    cursor: "pointer",
                                }}
                            >
                                <span className="link-text">{t.name} <IoLockClosedSharp size={"15px"} style={{ marginLeft: "5px" }} /></span>
                            </p>
                        ) : (
                            <NavLink
                                key={index}
                                to={t.path}
                                style={{
                                    textDecoration: "none",
                                    color: `${theme === "light" ? "" : "white"}`,
                                }}
                                className={({ isActive }) => (isActive ? "active-link" : "")}
                            >
                                <span className="link-text">{t.name}</span>
                                {({ isActive }) => isActive && <span className="active-indicator"></span>}
                            </NavLink>
                        );
                    })}


                </div>
            </div>

            {
                user?.user?.role === "teacher" ? (
                    <div
                        className="settings shadow"
                        style={{ cursor: "pointer", position: "fixed", right: "10px", background: "white", padding: "10px", borderRadius: "50%" }}>
                        <IoSettingsSharp size={"25px"} onClick={handleSettingRoute} />
                    </div>
                ) : null
            }

            <Modal show={showModal} onHide={() => setShowModal(false)} className="font" centered dir={dir}>
                <Modal.Header >
                    <Modal.Title>{key}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                    <div className="mt-3 text-center">
                        {
                            key === "no_subscription" ? (
                                <Link to="/teacher-services" className="btn" style={{ background: "#2C6DCE ", color: "white" }} >
                                    اذهب للاشتراك
                                </Link>
                            ) : (
                                <Link to="/subscriptions-teacher" className="btn" style={{ background: "#2C6DCE ", color: "white" }}>
                                    جدد الاشتراك
                                </Link>
                            )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>

            <div>
                <Outlet />
            </div>
        </div>
    );
}

export default SidNav;
