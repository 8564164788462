import React from 'react'
import PageContainer from '../layout/PagecContainer'
import Timeline from './Timeline'
import ThanksReverse from "../../assets/thanksReverse.png"
const Thanksrevers = () => {
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div>
                        <Timeline activeStep={7} />
                    </div>
                    <div style={{ textAlign: "center", color: "#071D5B" }} className='font'>
                        <img src={ThanksReverse} alt="thanks" loading='lazy' />
                        <p>تم الاشتراك بنجاح</p>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Thanksrevers
