import axios from 'axios';
import { urlBase } from './API';

// create new eduction type
export const newEduction = (data,userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/edu-types?userId=${userId}`,
        data: data
    })
}



// Get All Eduction Types
export const getAllEduType = ()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/edu-types`
    })
}


// Delete Edu Type
export const deleteType = (id,userId)=>{
    return axios({
        method:"DELETE",
        url:`${urlBase}/api/edu-types/${id}?userId=${userId}`
    })
}



// Update Edu Type

export const updateType = (data,id,userId)=>{
    return axios({
        method:"PATCH",
        url:`${urlBase}/api/edu-types/${id}?userId=${userId}`,
        data:data
    })
}
// Update Edu Type Asign It to Branch

export const updateTypeForBranch = (id,branchId,userId)=>{
    return axios({
        method:"PATCH",
        url:`${urlBase}/api/edu-types/${id}/branches/${branchId}/assign?userId=${userId}`,
    })
}