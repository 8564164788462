import { CREATE_COMMENT, CREATE_POST, INETIALIZE_POSTS } from "../actions/community"

export const posts = (state=[],action)=>{
    switch(action.type){
    case INETIALIZE_POSTS:
        return state = action.posts.sort((a,b)=>new Date(b.creationDate)-new Date(a.creationDate));
    case CREATE_POST:
        return state = [action.post,...state]
    // case CREATE_COMMENT :
    //     return state = state.map(s=>{
    //         if(s._id === action.comment.post){
                
    //         }
    //     })
        default: return state
    }
}

