// import React, { useState } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import AddPackage from './addPackage';
// import EditPackage from './editPackage';
// import PackagesList from './packagesList';
// import './pack.css'
// import CreatePackage from './createPackage';
// function Packages() {
//   const [switchEdit, setSwitch] = useState(false)
//   const [pack, setPack] = useState(null)
//   const handleToggle = (p) => {
//     setPack(p)
//     setSwitch(!switchEdit)
//   }
//   return (
//     <div className='full-width-cont'>
//       <Row>
//         <Col xs={12} md={6} style={{ border: '0px solid' }}>
//           <div className="">
//             {/* Content for the first column */}
//             {/* <AddPackage/> */}
//             <CreatePackage />
//           </div>
//         </Col>
//         <Col xs={12} md={6}>

//           <PackagesList handleToggle={(e) => handleToggle(e)} />

//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default Packages;

import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import AddPackage from './addPackage';
// import EditPackage from './editPackage';
import PackagesList from './packagesList';
import './pack.css'
import CreatePackage from './createPackage';
function Packages() {
  const [switchEdit, setSwitch] = useState(false)
  const [pack, setPack] = useState(null)
  const handleToggle = (p) => {
    setPack(p)
    setSwitch(!switchEdit)
  }
  return (
    <div className='full-width-cont'>
      <Row>
        <Col xs={12} md={12} style={{ border: '0px solid', padding: '10px 10px 50px 10px', minHeight: '200px' }}>
          <div className="">
            {/* Content for the first column */}
            {/* <AddPackage/> */}
            <CreatePackage />
          </div>
        </Col>
        <hr />
        <Col xs={12} md={12}>

          <PackagesList handleToggle={(e) => handleToggle(e)} />

        </Col>
      </Row>
      <hr />
      <br />
      <br />
    </div>
  );
}

export default Packages;
