
import axios from "axios"
import { urlBase } from "./API"

// 
// Create Zoom 
export const createZoom = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/zoom/users`,
        withCredentials: true,
        data: data
    });
}



// Get All Zoom
export const getAllZoom = (userId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/zoom/users?userId=${userId}`,
        withCredentials: true,
    });
}

// Get User Related ZoomUser

export const getUserRelatedZoom = (zoomUserId, userId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/zoom-users/${zoomUserId}/users?userId=${userId}`,
        withCredentials: true,
    })
}


// Assign User to Zoom User
export const assignUserToZoom = (idUser, zoomId, userId) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/users/${idUser}/zoom/${zoomId}?userId=${userId}`
    })
}


// Delete ZoomUser
export const deleteZoomUser = (zoomId) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/zoom/users/${zoomId}?action=delete`
    })
}


// Disabeled Teacher Zoom
export const disabeledZoomUser = (userId, adminUser) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/users/${userId}/zoom?userId=${adminUser}`
    })
}