import React from 'react'
import EmptySub from "../../../assets/emptysub.png"
import PageContainer from '../../layout/PagecContainer'
import { Link } from 'react-router-dom'
import { HomePage } from '../../../utils/Translation/HomePage'
import { useSelector } from 'react-redux'
const Emptysubscripe = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className='font mb-5 mt-5' dir={dir}>

                <div className='text-center'>
                    <img src={EmptySub} alt="ForEmptySubScripe" className='w-25' />
                    <div>
                        <p>{HomePage.noSubs[lang]}</p>
                        <Link to={'/teacher-services'} className='btn' style={{ background: "#2B6CCD", color: "white" }}>{HomePage.serviceTeacher[lang]}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Emptysubscripe
