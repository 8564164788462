import React from 'react';
import { urlBase } from '../../../../utils/API';
import { Card, Image, Button } from 'react-bootstrap';
import MultibleChoicesAnswer from './multibleChoicesAnswer';
import TrueFalseAnswer from './trueFalseAnswer';
import TextAnswer from './textAnswer';

function Question({ question, handleAnswer, questionIndex  }) {
  console.log('question type', question);

  return (
    <Card className="m-auto w-100" style={{ background: "transparent", border: "none" , boxShadow:"none" }}>
      <Card.Body>
        {/* Render question text */}
        <Card.Title className='font sharedColor'>{question.question}</Card.Title>
        {/* Conditional rendering for multimedia content */}
        {question.file?.name && question.file.source === 'LOCAL' && (
          <div className="mt-3">
            {/* Render image if question type is Text_Image */}
            {question.questionType.q === 'Text_Image' && (
              <Image className="q-img w-50" src={`${urlBase}/${question.file.name}`} fluid />
            )}

            {/* Render video if question type is Text_Video */}
            <div className='w-75' style={{ margin: "30px auto" }}>
              {question.questionType.q === 'Text_Video' && (
                <video className="q-video" controls>
                  <source src={`${urlBase}/${question.file.name}`} type={`video/${getFileExtension(question.file.name)}`} />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>


            {/* Render audio if question type is Text_Audio */}
            <div className='mt-4'>
              {question.questionType.q === 'Text_Audio' && (
                <audio className="q-audio" controls>
                  <source src={`${urlBase}/${question.file.name}`} type={`audio/${getFileExtension(question.file.name)}`} />
                  Your browser does not support the audio tag.
                </audio>
              )}
            </div>


          </div>
        )}

        {/* if(answer is choices ) */}
        {/* {question.choices ? <MultibleChoicesAnswer index={questionIndex} question={question} handleSelectAnswer={handleAnswer} /> : <></>} */}
        {question ? <MultibleChoicesAnswer index={questionIndex} question={question} handleSelectAnswer={handleAnswer} /> : <></>}
        {/* if true or false answer */}
        {!question.choices && question.TrueFalseAnswer !== null ?
          <TrueFalseAnswer index={questionIndex} handleAnswer={handleAnswer} /> : <></>
        }
        {!question.choices && question.TrueFalseAnswer === null ?
          <TextAnswer index={questionIndex} handleAnswer={handleAnswer} /> : <></>
        }

      </Card.Body>
    </Card >
  );
}
// Helper function to extract file extension
function getFileExtension(filename) {
  return filename.split('.').pop();
}

export default Question;
