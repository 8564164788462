import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function TrueFalseAnswer({ handleAnswer, index ,question , disabled=false }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleCheckboxChange = (value) => {
    const newOption = value === selectedOption ? null : value;
    setSelectedOption(newOption);

    // Convert the checkbox value ('true' or 'false') to a boolean
    const isTrue = newOption === 'true';
    // Call the handleAnswer function with the selected answer (true or false)
    handleAnswer(index ,isTrue);
  };

  return (
    <div className="true-false-answer">
      {/* True checkbox */}
      <Form.Check
        type="checkbox"
        id={`true-checkbox-${index}`}
        label="True"
        value="true"
        checked={question?.answer==true || selectedOption === 'true' ? true : false}
        disabled={disabled}
       // checked={selectedOption === 'true'}
        onChange={(e) => handleCheckboxChange(e.target.value)}
      />

      {/* False checkbox */}
      <Form.Check
        type="checkbox"
        id={`false-checkbox-${index}`}
        label="False"
        value="false"
        //checked={selectedOption === 'false'}
        checked={question?.answer==false || selectedOption === 'false' ? true : false}
        disabled={disabled}
        onChange={(e) => handleCheckboxChange(e.target.value)}
      />
    </div>
  );
}

export default TrueFalseAnswer;
 