import {urlBase} from '../API';
import axios from 'axios';

// create category
//{name}
export const newCategory = (data)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/new-category`,
        data:data
    })
}
// get categories

export const getCategories = ()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/get-categories`
    })
}
// delete category 
//{_id:id}
export const deleteCategory = (data)=>{
    return axios({
        method:"DELETE",
        url:`${urlBase}/api/delete-category`,
        data:data
    })
}