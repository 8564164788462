import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Update_Material } from '../../../actions/curriculums/materials';
import GetLevelName from '../../../components/shared/GetLevelName';
import { Update_Level } from '../../../actions/curriculums/levels';
import { getLevelById } from '../../../utils/API';
import { getAllMainMaterialsFilter } from '../../../utils/mainMaterial';
// import { BoxLoading } from 'react-loadingg';


function EditMaterial({ show, handleClose, material }) {
  const [materialName, setMaterialName] = useState(material?.name || null);
  const [materialCode, setMaterialCode] = useState(material?.code || null);
  const [selectedTeacher, setSelectedTeacher] = useState(material?.teacherId?._id || null);
  const [alertVariant, setAlertVariant] = useState('danger');
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [show, setShow] = useState(false);
  const [materialId, setMaterialId] = useState(material?.mainMaterialId);
  const [activeBranch, setActiveBranch] = useState(null);
  const [levels, setLevels] = useState([]);
  const [mainMaterials, setMainMaterials] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [levelsContent, setLevelsContent] = useState([]);
  const { teachers } = useSelector(state => state.users);
  const { user } = useSelector(state => state.loggedUser)
  const dispatch = useDispatch();

  useEffect(() => {
    if (material) {
      setMaterialName(material.name);
      setMaterialCode(material.code);
      setSelectedTeacher(material.teacherId);
    }
  }, [material]);

  const handleUpdateMaterial = async () => {
    try {
      setLoading(true);
      const resLevels = await getLevelById({ _id: selectedLevel })

      const res = await dispatch(Update_Material({
        _id: material._id, name: materialName, code: materialCode, teacherId: selectedTeacher,
        mainMaterialId: materialId
      }));
      setAlertVariant(res.data.case ? 'success' : 'danger');
      setAlertMessage(res.data.message);
      const updateLevelData = await dispatch(Update_Level({ _id: selectedLevel, materialIDS: resLevels?.data?.level?.materialIDS }));
      console.log(updateLevelData)
    } catch (err) {
      console.error(err.message);
      setAlertVariant('danger');
      setAlertMessage('Error updating material. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // const handleClose = () => {
  //   setShow(false)
  // }
  // const handleOpenModal = () => {
  //   setShow(true);
  // };

  const branches = useSelector((state) => state.branchs);

  const handleBranchSelection = (branchId) => {
    const selectedBranch = branches.find((branch) => branch._id === branchId);
    setActiveBranch(selectedBranch);
    setMainMaterials(selectedBranch?.mainMaterials)
    setLevels(selectedBranch ? selectedBranch.levelsIDS : []); // تحديث المستويات بناءً على الفرع النشط
  };

  const handleMainMaterial = (mainMaterialId) => {
    setMaterialId(mainMaterialId)
  }

  const getMainMatByLevel = async (selectedLevel) => {
    try {
      const res = await getAllMainMaterialsFilter(selectedLevel);
      if (res?.data?.case) {
        setMainMaterials(res?.data?.materials)
      }
    } catch (e) {
      console.log(e)
    } finally {

    }
  }


  console.log(material)

  useEffect(() => {
    getMainMatByLevel(selectedLevel);
  }, [selectedLevel])

  return (
    <>
      {/* <button className='btn buttonsGroub' onClick={handleOpenModal}>
        تعديل
      </button> */}

      <Modal show={show} onHide={handleClose} dir={'rtl'} className='font' centered>
        <Modal.Header>
          <Modal.Title>تعديل المادة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertMessage && (
            <Alert variant={alertVariant} onClose={() => setAlertMessage(null)} dismissible>
              {alertMessage}
            </Alert>
          )}
          <Form>
            <Form.Group controlId="formMaterialName">
              <Form.Label>اسم المادة:</Form.Label>
              <Form.Control
                type="text"
                placeholder="ادخل اسم المادة"
                value={materialName}
                onChange={(e) => setMaterialName(e.target.value)}
              />
            </Form.Group>
            {
              material?.mainMaterial && material?.mainMaterialId ? (
                null
              ) : (
                <div className="mt-3 mb-3">
                  <Form.Label>اختر فرع:</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => handleBranchSelection(e.target.value)}
                  >
                    <option value="">اختر مرحله دراسيه</option>
                    {branches &&
                      branches.map((branch) => (
                        <option key={branch._id} value={branch._id}>
                          {branch.name}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              )
            }
            {
              material?.mainMaterial && material?.mainMaterialId ? (

                null

              ) : (
                levels.length > 0 && (
                  <div className="mt-3 mb-3">
                    <Form.Label>اختر مستوي :</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => { setSelectedLevel(e.target.value) }}>
                      <option value="">اختر مستوي </option>
                      {levels.map((level) => (
                        <option key={level} value={level}>
                          <GetLevelName levelId={level} />
                          {/* <GetLevelName/> */}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                )
              )
            }


            {/* <div className="mt-3 mb-3">
              <Form.Label>اختر ماده رئيسيه:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={materialId}
                onChange={(e) =>
                  handleMainMaterial(e.target.value)
                }
              >
                <option value="">اختر ماده رئيسيه</option>
                {mainMaterials?.map(b =>
                  <option key={b?._id} value={b?._id}

                  >
                    {b?.name}
                  </option>

                )}


              </Form.Select>
            </div> */}
            {user.role === "Admin" ? <Form.Group controlId="formTeacherSelect">
              <Form.Label>المعلم:</Form.Label>
              <Form.Control
                as="select"
                value={selectedTeacher}
                onChange={(e) => setSelectedTeacher(e.target.value)}
              >
                <option value="">اختر معلما</option>
                {teachers.map(teacher => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group> : <></>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <>
              <Button variant="secondary" onClick={handleClose}>
                الغاء
              </Button>
              <Button variant="primary" onClick={handleUpdateMaterial}>
                تحديث المادة
              </Button>
            </>
          ) : (
            <div className="loaderIcon">
              <span className="loader"></span>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditMaterial;
