import axios from 'axios';
import {urlBase} from './API';

export const createBranch = (branch)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/new-branch`,
        data:branch
    })
}

// updateBranch 

export const updateBranch = (updatedBranch)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/update-branch`,
        data:updatedBranch// {branch} object contain _id
    })
}
// delete 

export const deleteBranch = (id)=>{
    return axios({
        method:"DELETE",
        url:`${urlBase}/api/delete-branch`,
        data:id // {_id:id}
    })
}

// Get All Branchs

export const getBranchs = ()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/get-branchs`,
        withCredentials:true
    })
}

// Get Branch By ID 

export const getBranchByID = (idObject)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get/branch/by/id`,
        data:idObject // {_id:id}
    })
}
