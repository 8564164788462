import React, { useState } from 'react'
import ModalAddMaterial from '../levels/ModalAddMaterial';
import ModalDelete from '../levels/ModalDelete';
import ExpandedContent from '../levels/ExpandedContent';
import "./branchs.css"
import Dropdown from 'react-bootstrap/Dropdown';
import ViewMainMaterial from './ViewMainMaterial';
import AddMainMaterial from './AddMainMaterial';
function LevelRow({ item, materials }) {
  const [message, setMessage] = useState('');
  const [selectedLevelId, setSelectedLevelId] = useState(null);

  const [showMaterials, setShowMaterials] = useState(false);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const handleShowAddMaterialModal = (levelId) => {
    setSelectedLevelId(levelId);
    setShowAddMaterialModal(true);
  };

  const handleCloseAddMaterialModal = () => {
    setSelectedLevelId(null);
    setShowAddMaterialModal(false);
  };
  return (
    <>
      <li key={item._id} className='level-row py-3' style={{ borderRadius: "10px" }}>

        {item.name}
        <ModalAddMaterial
          handleShowAddMaterialModal={handleShowAddMaterialModal}
          levelId={item._id}
          materialIDS={item.materialIDS}
          handleCloseAddMaterialModal={handleCloseAddMaterialModal}
          showAddMaterialModal={showAddMaterialModal} // Set to false to prevent automatic modal opening
          setMessage={() => { }}
          materials={materials}
        />
        <ModalDelete
          id={item._id}
          setMessage={setMessage} />
        <button onClick={() => setShowMaterials(!showMaterials)} className='btn buttonsGroub'>
          عرض المواد
        </button>

        <Dropdown onClick={(e) => e.stopPropagation()}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item >
              <ViewMainMaterial item={item} />
            </Dropdown.Item>
            <Dropdown.Item >
              <AddMainMaterial item={item} />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
      {showMaterials ?
        <ExpandedContent
          materials={materials.filter((material) => item.materialIDS.includes(material._id))} />
        : <></>
      }
    </>
  )
}

export default LevelRow