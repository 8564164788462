import React from 'react'
import ImageThanks from "../../assets/Advantages-amico.png"
import PageContainer from '../layout/PagecContainer'
const ThanksData = () => {
    return (
        <div>
            <PageContainer>
                <div style={{ marginTop: "130px" }} className='font'>
                    <div className='sharedBackground'>
                        <p>خدمات المعلم</p>
                    </div>
                    <div className="text-center">
                        <img src={ImageThanks} alt="imageData" className='w-50'/>
                        <p className="mt-5">تم التسجيل بنجاح</p>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default ThanksData
