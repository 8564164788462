import React, { useContext, useEffect, useState } from 'react'
import Timeline from './Timeline'
import PageContainer from '../layout/PagecContainer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import "./timeline.css"
import Spinner from 'react-bootstrap/Spinner';
import SharedImage from './SharedImage'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { getAllMainMaterialsFilter } from '../../utils/mainMaterial'
import { ThemeContext } from '../../ThemeProvider'
const Subjectselect = () => {
    const { theme } = useContext(ThemeContext);
    const params = useParams();
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [mainMats, setMainMats] = useState([]);
    const handleBranchClick = (branchId) => {
        setSelectedBranch(branchId);
    };

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(-1)
    }
    const [loading, setLoading] = useState(false)
    console.log(params)

    const handleGetAllMainMats = async () => {
        try {
            setLoading(true)
            const res = await getAllMainMaterialsFilter(params?.subject);
            console.log(res)
            if (res?.data?.case) {
                setMainMats(res?.data?.materials)
            }
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleGetAllMainMats();
    }, [])

    if (loading) {
        return (
            <div className="loading-screen" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5'
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className='container'>
                        <Timeline activeStep={4} />
                        <div className='row justify-content-between align-items-start' style={{ marginTop: "180px" }}>
                            <div className='col-lg-4 col-sm-12 col-md-12 '>
                                <SharedImage type={"material"} />
                            </div>
                            <div className='col-lg-8 col-sm-12 col-md-12' style={{
                                background: "white",
                                padding: "30px",
                                borderRadius: "10px",
                                height: "600px",
                                overflow: "auto",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                    paddingRight: "20px"
                                }}>
                                    {mainMats?.map((item, index) => (
                                        <div key={item?._id} className='w-100'>
                                            <Link
                                            to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${params?.subject}/${item?._id}`}
                                            style={{ textDecoration: "none", }}
                                                className={`text-center branchDiv py-3 shadow font ${selectedBranch === item?._id ? 'active' : ''}`}
                                                onClick={() => handleBranchClick(item?._id)}
                                            >
                                                <p className="font mb-0">{item?.name}</p>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>


                        <div style={{ textDecoration: "none", background: "white", textAlign: "center", padding: "10px", borderRadius: "10px", width: "50%", margin: "20px auto" }}>
                            <Link style={{ textDecoration: "none", color: "#071D5B" }} className='font'>اختيار مواد مجمعه</Link>
                        </div>
                        <div className='m-auto text-center mt-4 mb-5 font'>
                            <button onClick={handleNavigate} className='btn w-25 mx-2 prev'
                                style={{ color: theme === "light" ? "#071D5B" : "white", }}
                            >السابق</button>


                            <Link to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${params?.subject}/${selectedBranch}`}

                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color: "white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >التالي</Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Subjectselect
