import React, { useContext } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import imgCard from '../../assets/Image.png'
import { ThemeContext } from '../../ThemeProvider';
import "../../styles/programs.css"
import ProgramCard from '../shared/modules/ProgramCard';
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
const Programs = ({ packages }) => {
    const { theme } = useContext(ThemeContext);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // العرض بالبيكسل
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div style={{ margin: "120px auto", textAlign: "center", padding: "20px", width: "90%" }}>
            <h4 className='color'>{HomePage.Subjects[lang]}</h4>
            <Slider {...settings}>
                {
                    packages ? (
                        packages.map((item, index) => {
                            return (
                                <div key={item._id}>
                                    <ProgramCard item={item} />
                                </div>
                            )
                        })
                    ) : null
                }
            </Slider>
        </div>
    )
}

export default Programs
