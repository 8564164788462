import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Add_Class_Times } from '../../../actions/class';
import { useDispatch } from 'react-redux';

const daysOfWeek = [
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
  { id: 7, name: 'Sunday' }
];

const DayTimeSelector = ({ cls, material, show, setShow, toast }) => {
  const [sessionDuration, setSessionDuration] = useState('');
  const dispatch = useDispatch();
  const [selections, setSelections] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day.id] = { selected: false, time: '', id: day.id };
      return acc;
    }, {})
  );

  useEffect(() => {
    if (show) {
      const check = cls.schedual.find(s => s?.materialCode === material?.code);
      if (check) {
        setSelections(check.selections);
        setSessionDuration(check.sessionDuration);
      } else {
        setSelections(daysOfWeek.reduce((acc, day) => {
          acc[day.id] = { selected: false, time: '', id: day.id };
          return acc;
        }, {}));
        setSessionDuration('');
      }
    }
  }, [cls, show, material]);
if(show){
console.log('selections',selections)

}
  const handleClose = () => {
    setShow(false);
  };

  const onSelectionChange = async () => {
    try {
      const res = await dispatch(Add_Class_Times({ classId: cls._id, selections, sessionDuration, materialCode: material?.code }));
      if (res.data.case) {
        toast().success(res.data.message);
        handleClose();
      } else {
        toast().error(res.data.message);
      }
    } catch (err) {
      toast().error(err?.response?.data?.message || 'An error occurred');
      console.warn(err);
    }
  };

  const handleDayChange = (dayId) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [dayId]: {
        ...prevSelections[dayId],
        selected: !prevSelections[dayId]?.selected,
      },
    }));
  };

  const handleTimeChange = (dayId, time) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [dayId]: {
        ...prevSelections[dayId],
        time: time || '',
      },
    }));
  };

  const handleSessionDurationChange = (event) => {
    setSessionDuration(event.target.value || '');
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>اختر مواعيد الحصص المباشرة لمادة {material?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {daysOfWeek.map((day) => (
          <Form.Group key={day.id} className="mb-3">
            <Form.Check
              type="checkbox"
              label={day.name}
              checked={selections[day.id]?.selected}
              onChange={() => handleDayChange(day.id)}
            />
            {selections[day.id]?.selected && (
              <Form.Control
                type="time"
                value={selections[day.id]?.time || ''}
                onChange={(e) => handleTimeChange(day.id, e.target.value)}
                style={{ marginTop: '10px' }}
              />
            )}
          </Form.Group>
        ))}
        <Form.Group className="mb-3">
          <Form.Label>مدة الحصة (ساعة)</Form.Label>
          <Form.Control
            type="number"
            placeholder="ادخل مدة الحصة "
            value={sessionDuration || ''}
            onChange={handleSessionDurationChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          اغلاق
        </Button>
        <Button variant="primary" onClick={onSelectionChange}>
          حفظ التغييرات
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DayTimeSelector;
