import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { getUserRelatedZoom } from '../../../utils/zoom';
import { useSelector } from 'react-redux';

const AssignedUser = ({ show
    , handleClose, id }) => {
    // const [show, setShow] = useState(false);
    // const handleShow = () => setShow(true);
    // const handleClose = () => setShow(false);
    const loggedUser = useSelector((state) => state.loggedUser);

    const [users, setUsers] = useState([]);

    const handleGetRelatedUserToZoom = async () => {
        try {
            const res = await getUserRelatedZoom(id, loggedUser?.user?._id);
            console.log(res)
            if (res?.data?.case) {
                setUsers(res?.data?.users)
            }
        } catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        if (show) {
            console.log("show")
            handleGetRelatedUserToZoom();
        }
    }, [show])
    return (
        <div>
            {/* <button className='btn btn-primary' onClick={handleShow}>Assign User</button> */}
            <Modal centered onHide={handleClose} show={show} className="font">
                <Modal.Header>
                    <Modal.Title>Assigned User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <th>الاسم</th>
                            <th>التليفون</th>
                            <th>الايميل</th>
                        </thead>
                        <tbody>
                            {users?.length > 0 ? (
                                users?.map((user, i) => (
                                    <tr key={i}>
                                        <td>{user?.name}</td>
                                        <td>{user?.phone}</td>
                                        <td>{user?.email}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No Users</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AssignedUser
