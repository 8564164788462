import React from 'react'
import "../../../styles/teacher.css"
import Subscripe from './ModalPay'
import { useSelector } from 'react-redux';
import { HomePage } from '../../../utils/Translation/HomePage';
import { BiLogoZoom } from "react-icons/bi";

const ServiceCard = ({ sub }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    
    return (
        <div>
            <div className="package-card" dir={dir}>
                <h2 className="package-title">{sub?.name}</h2>
                <p className="package-description" style={{ minHeight: "200px" }}>
                    {sub?.description}
                </p>
                <ul className="package-features" dir={dir}>
                    {
                        sub.packageType === "STUDENT" ? (<li>
                            {
                                sub?.zoom ? (
                                    <span> <BiLogoZoom size={"25px"} /> هذ الباقه تدعم خاصية zoom </span>
                                ) : (<span>هذه الباقه لاتدعم خاصية zoom</span>)
                            }
                        </li>) : null
                    }
                    <li className="feature active">{HomePage.pricemonthDir[lang]}:{sub?.monthlyPrice} {HomePage.egp[lang]}</li>
                    <li className="feature active">{HomePage.annualPrice[lang]}:{sub?.annuallyPrice}{HomePage.egp[lang]}</li>
                    {
                        sub.packageType === "STUDENT" ? (
                            <li className="feature inactive">{HomePage.maxValue[lang]}:{sub?.maxValue}{HomePage.studetn[lang]}</li>
                        ) : (
                            <li className="feature inactive">{HomePage.maxGiga[lang]}:{sub?.maxValue}{HomePage.giga[lang]}</li>
                        )
                    }
                    {/* <li className="feature inactive">{HomePage.maxValue[lang]}:{sub?.maxValue}{HomePage.studetn[lang]}</li> */}
                    <li className="feature inactive">{HomePage.trialDuration[lang]}:{sub?.trialDuration}{HomePage.today[lang]}</li>
                </ul>

                <Subscripe sub={sub} />
            </div>
        </div>
    )
}

export default ServiceCard
