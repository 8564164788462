import React, { useState, useEffect, useContext } from 'react';
import Timeline from './Timeline';
import PageContainer from '../layout/PagecContainer';
import Spinner from 'react-bootstrap/Spinner';

import SharedImage from './SharedImage';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { ThemeContext } from '../../ThemeProvider';


const Chooselevel = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {theme} = useContext(ThemeContext);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branchData, setSelectBranchData] = useState(null);
    const [loading, setLoading] = useState(true); 
    const [eduTypes, setEduTypes] = useState(null); 

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleBranchClick = (branchId, branch) => {
        setSelectedBranch(branchId);
        setSelectBranchData(branch);
    };

    useEffect(() => {
        // محاكاة جلب البيانات
        setTimeout(() => {
            setEduTypes(location.state?.eduTypes); 
            setLoading(false); // إنهاء التحميل
        }, 2000); 
    }, [location.state]);

    if (loading) {
        return (
            <div className="loading-screen" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5'
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className="container">
                        <Timeline activeStep={2} />
                        <div className="row justify-content-between align-items-center" style={{ marginTop: "180px" }}>
                            <div className="col-lg-4 col-sm-12 col-md-12">
                                <SharedImage type={"level"} />
                            </div>
                            <div className="col-lg-8 col-sm-12 col-md-12">
                                <div className="row justify-content-center align-items-center font mb-5">
                                    {eduTypes?.branchs?.map((branch, index) => (
                                        <Link to={`/reservation-system/${params.gender}/${params?.stage}/${branch._id}`}
                                        state={{ levelData: branch }} className="col-lg-3 mb-3" key={branch?._id} style={{textDecoration:'none'}}>
                                            <div className={`text-center levelDiv`}
                                                onClick={() => handleBranchClick(branch._id, branch)}
                                            >
                                                <p>{branch?.name}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="m-auto text-center mt-4 mb-5 font">
                            <button onClick={handleNavigate} className="btn w-25 mx-2 prev"
                                 style={{ color:theme ==="light" ? "#071D5B":"white",}}
                            >السابق</button>
                            <Link to={`/reservation-system/${params.gender}/${params?.stage}/${selectedBranch}`}
                                state={{ levelData: branchData }}
                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color:"white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >
                                التالي
                            </Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

export default Chooselevel;
