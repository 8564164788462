// api.js
import axios from 'axios';

// Define base URL
import { urlBase } from './API';

// Function to create a new post
//author,title,content
async function createPost(data) {
    try {
        const response = await axios.post(`${urlBase}/api/posts`, data);
        return response;
    } catch (error) {
        console.error('Error creating post:', error);
        throw error;
    }
}

// Function to fetch all posts
async function getAllPosts() {
    try {
        const response = await axios.get(`${urlBase}/api/posts`);
        return response;
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
}

// Function to fetch a post by ID
async function getPostById(postId) {
    try {
        const response = await axios.get(`${urlBase}/api/posts/${postId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching post by ID:', error);
        throw error;
    }
}

// Function to update a post by ID
async function updatePostById(postId, data) {
    try {
        const response = await axios.patch(`${urlBase}/api/posts/${postId}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating post by ID:', error);
        throw error;
    }
}

// Function to delete a post by ID
async function deletePostById(postId) {
    try {
        const response = await axios.delete(`${urlBase}/api/posts/${postId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting post by ID:', error);
        throw error;
    }
}

// Function to create a new comment for a post
// (postId, {author,content})
async function createComment(postId, data) {
    try {
        const response = await axios.post(`${urlBase}/api/posts/${postId}/comments`, data);
        return response;
    } catch (error) {
        console.error('Error creating comment:', error);
        throw error;
    }
}

// Function to fetch all comments for a post
async function getAllCommentsForPost(postId) {
    try {
        const response = await axios.get(`${urlBase}/api/posts/${postId}/comments`);
        return response.data;
    } catch (error) {
        console.error('Error fetching comments for post:', error);
        throw error;
    }
}

// Function to fetch a comment by ID
async function getCommentById(commentId) {
    try {
        const response = await axios.get(`${urlBase}/api/comments/${commentId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching comment by ID:', error);
        throw error;
    }
}

// Function to update a comment by ID
async function updateCommentById(commentId, data) {
    try {
        const response = await axios.patch(`${urlBase}/api/comments/${commentId}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating comment by ID:', error);
        throw error;
    }
}

// Function to delete a comment by ID
async function deleteCommentById(commentId) {
    try {
        const response = await axios.delete(`${urlBase}/api/comments/${commentId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting comment by ID:', error);
        throw error;
    }
}

// Export all API functions
export {
    createPost,
    getAllPosts,
    getPostById,
    updatePostById,
    deletePostById,
    createComment,
    getAllCommentsForPost,
    getCommentById,
    updateCommentById,
    deleteCommentById
};
