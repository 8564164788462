import React, { useState } from 'react'
import Menu from './menu'
import Products from './products'
import ProductsList from './productsList'
import { useSelector } from 'react-redux'
import Orders from './orders'
function StoreControle() {
  const [status, setStatus] = useState('orders')

  const { products, categories } = useSelector(({ store }) => {
    return {
      products: store.products,
      categories: store.categories,
    }
  });
  return (
    <div>
      <Menu status={status} setStatus={setStatus} />
      {status === 'orders' && <><Orders /> </>}
      {status === 'products' && <Products products={products} categories={categories} />}
      {status === 'newOrders' && <></>}
    </div>
  )
}

export default StoreControle