import React, { useState } from 'react';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Update_Level } from '../../../actions/curriculums/levels';

const ModalAddMaterial = ({ materials, levelId, materialIDS, handleShowAddMaterialModal, handleCloseAddMaterialModal, showAddMaterialModal, setMessage }) => {

  const [choosed, setChoosed] = useState([]);
  // 
  const handleMaterialSelection = (selectedMaterial) => {
    // Handle the selected material
    const checkexistance = choosed.filter(m => m._id === selectedMaterial);
    if (checkexistance.length) return;
    const material = materials.filter(m => m._id === selectedMaterial)
    setChoosed([...choosed, ...material]);

  };

  const handleRemoveMaterial = (materialId) => {
    // Remove the selected material
    const updatedChoosed = choosed.filter((material) => material._id !== materialId);
    setChoosed(updatedChoosed);
  };



  const dispatch = useDispatch();
  // Submite materials
  const submiteMaterials = async () => {
    try {
      const newMaterialIDS = [...materialIDS];
      const oldMaterialIDSObject = {};
      materialIDS.forEach(m => oldMaterialIDSObject[m] = m);

      choosed.forEach(c => {
        if (!oldMaterialIDSObject[c._id]) {
          newMaterialIDS.push(c._id);
        }
      });
      const res = await dispatch(Update_Level({ _id: levelId, materialIDS: newMaterialIDS }));
      setMessage(res.data.message);
    } catch (err) {
      console.warn(err);
      setMessage(err.message)
    }
  }
  return (
    <>
      <button className='btn special color' onClick={() => {

        handleShowAddMaterialModal(levelId)
      }}>
        Add Material
      </button>
      {/* Modal for Adding Material */}
      <Modal show={showAddMaterialModal} onHide={handleCloseAddMaterialModal} centered className='font'>
        <Modal.Header>
          <Modal.Title>Add Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Include your form or content for adding material */}

          <Form className='font'>
            <Form.Group controlId="formMaterial">
              <Form.Label>Select Material:</Form.Label>
              <Form.Control as="select" onChange={(e) => handleMaterialSelection(e.target.value)}>
                <option value="">Select Material</option>
                {materials.map((material) => (
                  <option key={material._id} value={material._id}>
                    {material.name}: {material.code}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>



          </Form>
          {choosed.length > 0 && (
            <Form.Group controlId="selectedMaterials" className='font'>
              <Form.Label >Selected Materials:</Form.Label>
              <ListGroup>
                {choosed.map((selectedMaterial) => (
                  <ListGroup.Item key={selectedMaterial._id}>
                    {selectedMaterial.name} : {selectedMaterial.code} -
                    <Button variant="danger" size="sm" onClick={() => handleRemoveMaterial(selectedMaterial._id)}>
                      Remove
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
          )}
        </Modal.Body>

        <Modal.Footer>
          <button className='btn special color' onClick={handleCloseAddMaterialModal}>
            Close
          </button>
          {/* Other form fields and actions go here */}
          <button className='btn buttonsGroub' type="submit" onClick={() => submiteMaterials()} >
            Add Material
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAddMaterial;
