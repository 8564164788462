import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Create_Material } from '../../../actions/curriculums/materials';
import GetLevelName from '../../../components/shared/GetLevelName';
import { getLevelById } from '../../../utils/API';
import { Update_Level } from '../../../actions/curriculums/levels';
import { Get_All_Main_Materials } from '../../../actions/mainMaterial';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';
// import { BoxLoading } from 'react-loadingg';

function AddMaterial() {
  const [showModal, setShowModal] = useState(false);
  const [materialName, setMaterialName] = useState('');
  const [materialCode, setMaterialCode] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState(null); // State for selected teacher
  const [alertVariant, setAlertVariant] = useState('danger');
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeBranch, setActiveBranch] = useState(null); // حالة لحفظ الفرع النشط
  const [levels, setLevels] = useState([]);
  const [materialId, setMaterialId] = useState();
  const [mainMaterials, setMainMaterials] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [levelsContent, setLevelsContent] = useState([]);
  const [teacherBranch, setTeacherBranch] = useState(null);
  const { teachers } = useSelector(state => state.users);
  const [allMainMats, setAllMainMats] = useState([]);
  const [mainMats, setMainMats] = useState([]);
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setAlertMessage(null);
  };

  const loggedUser = useSelector((state) => state.loggedUser);

  const handleAllMats = async () => {
    try {
      const res = await dispatch(Get_All_Main_Materials());
      if (res?.data?.case) {
        setAllMainMats(res?.data?.materials);
        setMainMats(res?.data?.materials);
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleAllMats();
  }, [dispatch])

  const onChangeLevel = async (levelID) => {
    try {
      if ((levelID)) {
        const filteredMats = allMainMats.filter(mat => mat.levelId === levelID);
        setMainMats(filteredMats);
      }
    }
    catch (err) {
      console.log(err.message)
    }
  }

  const handleCreateMaterial = async () => {
    try {
      setLoading(true);
      const resLevels = await getLevelById({ _id: selectedLevel })
      setLevelsContent(resLevels?.data?.level?.materialIDS)
      const res = await dispatch(Create_Material({ name: materialName, code: materialCode, teacherId: selectedTeacher, mainMaterialId: materialId }, loggedUser?.user?._id));
      setMaterialName('');
      setMaterialCode('');
      setSelectedTeacher(''); // Reset selected teacher
      console.log(res)
      if(res?.data?.case){
        success(res?.data?.message);
        handleCloseModal();
      }
      // setAlertVariant(res.data.case ? 'success' : 'danger');
      // setAlertMessage(res.data.message);
      const updateLevelData = await dispatch(Update_Level({ _id: selectedLevel, materialIDS: [...resLevels?.data?.level?.materialIDS, res?.data?.material?._id] }));
    } catch (err) {
      console.error(err.message);
      if(err?.response?.data){
        ErrorMsg(err?.response?.data?.message)
      }
      setAlertVariant('danger');
      setAlertMessage('Error creating material. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const branches = useSelector((state) => state.branchs);

  const handleBranchSelection = (branchId) => {
    // const [teacher] = teachers.filter(t=>t._id===teacherId)
    // console.log('SELECTRD_TEACHER',teacherId,teacher)
    // const selectedBranch = branchId;
    const selectedBranch = branches.find((branch) => branch._id === branchId);
    setTeacherBranch(selectedBranch)
    setActiveBranch(selectedBranch);
    setMainMaterials(selectedBranch?.mainMaterials)
    setLevels(selectedBranch ? selectedBranch.levelsIDS : []); // تحديث المستويات بناءً على الفرع النشط
  };

  const handleMainMaterial = (mainMaterialId) => {
    setMaterialId(mainMaterialId)
  }




  // useEffect(()=>{
  //   if(branches){
  //    handleBranchSelection(selectedTeacher);
  //   }
  // },[branches])



  return (
    <div>
      <button className='btn buttonsGroub' onClick={handleOpenModal}>
        <span className='font'>اضافة</span>
      </button>

      <Modal show={showModal} onHide={handleCloseModal} dir={'rtl'} centered className='font'>
        <Modal.Header>
          <Modal.Title>اضافة مادة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {alertMessage && (
            <Alert variant={alertVariant} onClose={() => setAlertMessage(null)} dismissible>
              {alertMessage}
            </Alert>
          )} */}
          <Form>
            <Form.Group controlId="formMaterialName">
              <Form.Label>اسم المادة:</Form.Label>
              <Form.Control
                type="text"
                placeholder="ادخل اسم المادة"
                value={materialName}
                onChange={(e) => setMaterialName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formMaterialCode">
              <Form.Label>كود المادة:</Form.Label>
              <Form.Control
                type="text"
                placeholder="ادخل كود المادة"
                value={materialCode}
                onChange={(e) => setMaterialCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTeacherSelect">
              <Form.Label>المعلم:</Form.Label>
              <Form.Control
                as="select"
                value={selectedTeacher}
                onChange={(e) => {
                  setSelectedTeacher(e.target.value)
                }}
              >
                <option value={null}>اختر معلما</option>
                {teachers.map(teacher => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          <div className="mt-3 mb-3">
            <Form.Label>اختر فرع:</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) =>
                handleBranchSelection(e.target.value)
              }
            >
              <option value="">اختر مرحله دراسيه</option>
              {branches?.map(b =>
                <option key={b?._id} value={b?._id}

                >
                  {b?.name}
                </option>

              )}


            </Form.Select>
          </div>


          {levels.length > 0 && (
            <div className="mt-3 mb-3">
              <Form.Label>اختر مستوي :</Form.Label>
              <Form.Select aria-label="Default select example" onChange={(e) => onChangeLevel(e.target.value)}>
                <option value="">اختر مستوي </option>
                {levels.map((level) => (
                  <option key={level} value={level}>
                    <GetLevelName levelId={level} />
                    {/* <GetLevelName/> */}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}
          <div className="mt-3 mb-3">
            <Form.Label>اختر ماده رئيسيه:</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={materialId}
              onChange={(e) =>
                handleMainMaterial(e.target.value)
              }
            >
              <option value="">اختر ماده رئيسيه</option>
              {mainMats?.map(b =>
                <option key={b?._id} value={b?._id}

                >
                  {b?.name}
                </option>

              )}


            </Form.Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <>
              <Button variant="secondary" onClick={handleCloseModal}>
                الغاء
              </Button>
              <Button variant="primary" onClick={handleCreateMaterial}>
                اضافة المادة
              </Button>
            </>
          ) : (
            <div className="loaderIcon">
              <span className="loader"></span>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddMaterial;
