import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getUsersByIDS } from "../../../utils/API";
import { useSelector } from "react-redux";
import { HomePage } from "../../../utils/Translation/HomePage";
import ContactButton from "../../publicPges/shareCommponents/HeaderDashBoard/ContactButton";
import { useNavigate } from "react-router-dom";
//import { HomePage } from "../../../utils/Translation/HomePage";


export default function MaterialSuperVisorsModal({ showModal, setShowModal, classObj }) {
  const [loading, setLoading] = useState(() => false);
  const [superVisors, setSuperVisors] = useState(null);

  const navigate = useNavigate();

  const { user } = useSelector(
    (state) => state.loggedUser
  );

  const { lang, dir } = useSelector(({ language }) => {
    return {
      lang: language.lang,
      dir: language.dir
    }
  })

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const arr = classObj?.supervisorIDS?.map(el => el?.superV);
      const response = await getUsersByIDS({ IDS: arr });
      console.log('response', response);
      setSuperVisors(response?.data?.users);
      setLoading(false);
    };

    get();
  }, []);

  console.log('classObj', classObj?.supervisorIDS);
  console.log('superVisors', superVisors);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      style={{ direction: "rtl" }}
      className="font"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title style={{ color: "#2B6CCD" }}> {loading && <Spinner />} إختر المشرف :</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="stdResults"
          style={{ direction: "rtl" }}
        >
          <table className="w-100 px-3" style={{fontSize:"15px"}}>
            <thead>
              <tr>
                <th>
                  <div className="font" style={{fontSize:"15px"}}>اسم المشرف</div>
                </th>
                <th>
                  <div className="font" style={{fontSize:"15px"}}>تواصل</div>
                </th>
                <th>
                  <div className="font" style={{fontSize:"15px"}}> رابط اجتماع </div>
                </th>
              </tr>
            </thead>
            <tbody>

              {
                loading ? <tr>
                  <td colSpan={6}>
                    <Spinner />
                  </td>
                </tr>
                  : superVisors?.length === 0 ?
                    <tr>
                      <td colSpan={6} className="font" style={{fontSize:"15px"}}>
                        لا يوجد مشرفين
                      </td>
                    </tr>
                    :
                    superVisors?.map((el, i) => (
                      <tr key={i}>
                        <td style={{ height: 'auto' }}>
                          <div>
                            {el?.name}
                          </div>
                        </td>
                        <td style={{ height: 'auto' }}>
                          <div>
                            <ContactButton
                              name={HomePage.contact[lang]}
                              class_name="font"
                              contactID={el?._id}
                              contactRole="teacher"
                            />
                          </div>

                        </td>
                        <td style={{ height: 'auto' }}>
                          <div>
                            <button onClick={() => {
                              const roomId = user?._id + el?._id;
                              navigate(`/meeting-room/${user?.username}/:${user?._id}/:${roomId}`);
                            }}
                              className="font" style={{background:"#2B6CCD" , border:"none" , outline:"none" , color:"white",borderRadius:"5px",padding:"5px"}}>  اجتماع </button>
                          </div>
                        </td>
                      </tr>
                    ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button className="btn font" style={{background:"#2B6CCD" , color:"white"}} onClick={() => setShowModal(false)}> الغاء </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
