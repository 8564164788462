import React, { useContext } from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import common from "../../assets/commom.png"
import SharedStatic from './modules/SharedStatic'
import { ThemeContext } from '../../ThemeProvider'
const CommponQue = () => {
    const {theme} = useContext(ThemeContext)
    return (
        <div className='font' style={{color:theme === "light" ?"black":"white" }}>
            <PageContainer>
                <main id="contact-us">
                    <SharedImg img={common} title=" OLP مرحبا بكم في" line="اسئله شائعه" />
                    <div className='container'>
                        <p className='sharedColor'>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white"  }}>    
                                <h4>ما هي منصة الخدمات التعليمية؟ </h4>
                                <p>منصة OLP هي نظام متكامل يهدف إلى تقديم تجربة تعليمية
                                    متميزة عبر اإلنترنت. تشمل المنصة مجموعة متنوعة من
                                    الدروس التفاعلية، الفيديوهات التعليمية، المقاالت، والمواد
                                    المقروءة التي تغطي مختلف المجاالت التعليمية.</p>
                            </div>

                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>كيف يمكنني التسجيل في المنصة؟</h4>
                                <p>
                                    <h6 className='mt-3 mb-3'> : يمكنك التسجيل بسهولة عبر اتباع الخطوات التالية</h6>
                                    انقر على زر "التسجيل" في الصفحة الرئيسية.
                                    امأل النموذج بالمعلومات المطلوبة مثل االسم، البريد
                                    اإللكتروني، وكلمة المرور.
                                    تأكيد التسجيل عبر رابط التفعيل الذي سيتم إرساله إلى بريدك
                                    اإللكتروني.</p>
                            </div>

                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>هل هناك رسوم لالشتراك في المنصة؟</h4>
                                <p>تعتمد الرسوم على نوع االشتراك والدورات التي ترغب في
                                    التسجيل بها. هناك دورات مجانية وأخرى مدفوعة. يمكنك
                                    االطالع على تفاصيل الرسوم عند اختيار الدورة التي تهمك.</p>
                            </div>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>كيف يمكنني الوصول إلى الدورات بعد التسجيل؟</h4>
                                <p>بعد تسجيل الدخول إلى حسابك، يمكنك تصفح الدورات المتاحة
                                    من خالل صفحة "الدورات" واختيار الدورة التي ترغب في
                                    االنضمام إليها. ستتمكن من الوصول إلى المحتوى التعليمي
                                    مباشرة بعد االشتراك في الدورة.</p>
                            </div>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>هل يمكنني التفاعل مع المعلمين والمحاضرين؟</h4>
                                <p>نعم، يمكنك التفاعل مع المعلمين والمحاضرين عبر المنصة من
                                    خالل الدروس المباشرة، المناقشات الجماعية، والمنتديات.
                                    يمكنك طرح األسئلة والحصول على اإلرشاد الالزم.</p>
                            </div>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>كيف يتم تقييم المتعلمين؟</h4>
                                <p>
                                    يتم تقييم المتعلمين من خالل اختبارات قصيرة، مشاريع عملية،
                                    وأعمال تقييمية يتم تقديمها في نهاية كل وحدة دراسية.
                                    باإلضافة إلى ذلك، يمكنك الحصول على تغذية راجعة فورية
                                    من المعلمين.
                                </p>
                            </div>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>هل يمكنني الوصول إلى المنصة من خالل األجهزة
                                    المختلفة؟</h4>
                                <p>  نعم، المنصة متجاوبة ويمكنك الوصول إليها من خالل
                                    الحواسيب، الهواتف الذكية، واألجهزة اللوحية بكل سهولة.
                                </p>
                            </div>

                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>.ماذا أفعل إذا واجهتني مشكلة فنية؟</h4>
                                <p>
                                    يمكنك التواصل مع فريق الدعم الفني عبر البريد اإللكتروني،
                                    الدردشة المباشرة، أو من خالل صفحة "الدعم" على المنصة.
                                    نحن هنا لمساعدتك في حل أي مشكلة تواجهك.
                                </p>
                            </div>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>كيف يتم تحديث المحتوى التعليمي؟</h4>
                                <p>
                                    نقوم بتحديث المحتوى التعليمي بانتظام لضمان مواكبة أحدث
                                    التطورات العلمية والتقنية. يتم إضافة مواد جديدة وتحديث
                                    الدروس الحالية بشكل دوري.
                                </p>
                            </div>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>هل يمكنني مشاركة حسابي مع اآلخرين؟</h4>
                                <p>
                                    ال، كل حساب على المنصة مخصص لمستخدم واحد فقط.
                                    مشاركة الحساب مع اآلخرين يخالف سياسات االستخدام وقد
                                    يؤدي إلى تعليق الحساب.
                                </p>
                            </div>
                            <div style={{ textAlign: "right",color:theme === "light" ?"black":"white" , marginTop: "50px" }}>
                                <h4>كيف يمكنني تحديث معلوماتي الشخصية؟</h4>
                                <p>
                                    يمكنك تحديث معلوماتك الشخصية من خالل صفحة "الملف
                                    الشخصي" بعد تسجيل الدخول إلى حسابك. يمكنك تعديل
                                    البيانات الشخصية، تفضيالت اإلشعارات، وكلمة المرور
                                </p>
                            </div>
                        </p>
                    </div>


                    <SharedStatic hint="common" />

                </main>
            </PageContainer>
        </div>
    )
}

export default CommponQue
