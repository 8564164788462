import { Delete_Transaction, getAllTransactions, Update_Transaction } from "../utils/transaction";

export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS';
export const DELTETE_TRANSACTION = 'DELTETE_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';

const allTransactions = (transactions) => {
    return {
        type: GET_ALL_TRANSACTIONS,
        transactions
    }
}
// Delte
const Delete_single_Transactions = (id) => {
    return {
        type: DELTETE_TRANSACTION,
        id
    }
}
// Update
const Update_single_Transactions = (transaction) => {
    return {
        type: UPDATE_TRANSACTION,
        transaction
    }
}


export const Get_All_transactions = (userId, page, limit, filters) => {
    return dispatch => getAllTransactions(userId, page, limit, filters).then(res => {
        if (res.data.case) {
            dispatch(allTransactions({
                data: res.data.transactions.transactions,
                total: res.data.transactions.stats.num
            }))
        }
        return res
    })
}

// Delete Transaction
export const Delete_Transaction_Act = (id, userId) => {
    return dispatch => Delete_Transaction(id, userId).then(res => {

        dispatch(Delete_single_Transactions(id))

        return res
    })
}

// Delete Transaction
export const Update_Transaction_Act = (id, userId, data) => {
    return dispatch => Update_Transaction(id, userId, data).then(res => {
        console.log(res)
        if(res?.data?.case){
            dispatch(Update_single_Transactions(res?.data?.transaction))
        }

        return res
    })
}