import React from 'react'
import PageContainer from '../../components/layout/PagecContainer'
import ChooseGender from '../../components/ReservSystem/ChooseGender'
import Timeline from '../../components/ReservSystem/Timeline'

const ReversationSystem = () => {
    return (
        <div>
            <PageContainer>

                <div style={{ paddingTop: "120px" , height: "100vh"}}>
                    <ChooseGender />
                </div>
            </PageContainer>
        </div>
    )
}

export default ReversationSystem
