import React, { useState } from 'react';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';

function AddQuestion({ addQuestion, order, deleteLastQuestion }) {
  const [questionType, setQuestionType] = useState('');
  const [answerType, setAnswerType] = useState('');

  const questionTypes = ['Text_Image', 'Text_Video', 'Text_Audio'];
  const answerTypes = ['CHOICE', 'TRUE&FALSE', 'TEXT'];

  const handleQuestionTypeSelect = (type) => {
    console.log(type)
    setQuestionType(type);
  };

  const handleAnswerTypeSelect = (type) => {
    console.log(type)
    setAnswerType(type);
  };

  const handleAddButtonClick = () => {
    if (questionType && answerType) {
      const newQuestion = {
        q: questionType,
        answer: answerType,
      };
      console.log(newQuestion)
      addQuestion(newQuestion, order);
      // Reset dropdown selections after adding question
      setQuestionType('');
      setAnswerType('');
    } else {
      // Handle error or validation here if needed
      console.error('Both question type and answer type must be selected.');
    }
  };

  return (
    <>
      <hr />
      <h5>
        اضافة سؤال جديد
      </h5>
      <div className='align-evenly'

      >
        {/* <br/> */}
        <div className='add-q-btns'>
          <DropdownButton title="نوع السؤال " variant="primary" onSelect={handleQuestionTypeSelect} className='mx-2'>
            {questionTypes.map((type) => (
              <Dropdown.Item key={type} eventKey={type} >
                {type}
              </Dropdown.Item>
            ))}
          </DropdownButton>

          <DropdownButton title=" نوع الاجابة" variant="primary" onSelect={handleAnswerTypeSelect} className='mx-2'>
            {answerTypes.map((type) => (
              <Dropdown.Item key={type} eventKey={type}>
                {type}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <button onClick={handleAddButtonClick} className='btn special color' style={{ margin: '10px' }}>
          إضافة سؤال
        </button>
      </div>
      <div className=''>

        <button className='btn special color' onClick={() => deleteLastQuestion()}  style={{ margin: '10px' }}>
          حذف سؤال
        </button>
      </div>
    </>
  );
}

export default AddQuestion;
