import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetMaterialsByIds } from "../../../utils/materials";
import { getLessonsByIDS } from "../../../utils/lessons";
import ContactButton from "../../publicPges/shareCommponents/HeaderDashBoard/ContactButton";
import { HomePage } from "../../../utils/Translation/HomePage";

export default function ClassRowSuperVisorComponent({
  // students,
  classObj,
  setResultsTable,
  setShowModal,
  setSelectedStudent,
  student,
}) {
  // console.log('I am here!',classObj) 
  // alert('I am here')
  //const{classes}=useSelector(state=>state.loggedUser);
  const [showDropMenu, setShowDropMenu] = useState(false);
  const [materials, setMaterials] = useState([]);

  const { lang, dir } = useSelector(({ language }) => {
    return {
      lang: language.lang,
      dir: language.dir
    }
  })

  useEffect(() => {
    const get = async () => {
      try {
        const packMaterialsArr = classObj?.packID?.materials.map(
          (el) => el?.materialCode
        );
        const response = await GetMaterialsByIds({ codes: packMaterialsArr });
        setMaterials(response?.data?.materials);
      } catch (error) {
        console.error(error.message);
      }
    };
    get();
  }, []);
  const getMaterialSelectedLessons = async (studentID) => {
    try {
      // console.log('material',material);
      console.log("classes...classObj?.packID?.materials", classObj?.packID?.materials);
      const selectedLessons = classObj?.packID?.materials.find(
        (el) => el?.materialCode == classObj?.selectedMaterial.code
      )?.selectedLessons;
      console.log("selectedLessons.........", selectedLessons);

      // const lessonsRes = await getLessonsByIDS({ lessonsIDS: classObj?.selectedMaterial?.selectedLessons });
      // const lessons = lessonsRes?.data?.lessons;

      // setResultsTable(lessons);
      // setSelectedStudent(studentID);
      // setShowDropMenu(false);
      // setShowModal(true);
      //  console.log("lessons", lessons);

      const lessonsRes = await getLessonsByIDS({ lessonsIDS: selectedLessons });
      const lessons = lessonsRes?.data?.lessons;
      //  console.log("lessons", lessons);
      setResultsTable(lessons);
      setSelectedStudent(studentID);
      setShowDropMenu(false);
      setShowModal(true);
    } catch (error) {
      console.error(error.message);
    }
  };

  // console.log('student',student);

  return (
    <div className="classBox">
      <div className="box">
        <div className="classBoxContainer font1">
          الطالب: {student?.name}<br />
          <span>هاتف: {student?.phone}</span>
          <br />
          <span>البريد الالكترونى: {student?.email}</span>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center m-3">
          {/* <div class="dropdown"> */}
          <button
            onClick={() => getMaterialSelectedLessons(student?._id)}
            className="addclass_btn font mt-3"
            type="button"
          >
            عرض النتائج
          </button>
          <div className="w-100 my-2">
            <ContactButton
              name={HomePage.contact_student[lang]}
              class_name="font-meeting_btn w-100"
              contactID={student?._id}
              contactRole="supervisor"
            />
          </div>

          <div className="w-100">
            <ContactButton
              name={HomePage.contact_parent[lang]}
              class_name="addclass_btn font-addclass_btn h-75 w-100"
              contactID={student?.parentId}
              contactRole="supervisor"
            />
          </div>

          {/* {showDropMenu && (
              <div
                class="dropdown-menu d-block w-100"
                aria-labelledby="dropdownMenuButton"
                style={{ direction: "rtl" }}
              >
                {materials?.map((el, i) => (
                  <button
                    key={i}
                    onClick={() => getMaterialSelectedLessons(el,student?._id)}
                    className="dropdown-item"
                  >
                    {el?.name}
                  </button>
                ))}
              </div>
            )} */}
          {/* </div> */}
          {/* {
                    type=='class' && <div className="d-flex flex-column">
                    <button className="addclass_btn font-addclass_btn">اضافة موعد حصة</button>
                    <button className="meeting_btn font-meeting_btn">الدخول لغرفة الاجتماعات</button>
                    <button onClick={()=>getTeacherStudents()} className="btn btn-primary mt-3">الطلاب</button>
                </div>
                } */}

          {/* {
                    type=='student'&& <>
                    <button onClick={()=>getStudentResults(classObj?._id)} className="addclass_btn font-addclass_btn mt-3">عرض النتائج</button>
                    <button onClick={()=>setStudentAsWeekStudent(classObj?._id)} className="meeting_btn font-meeting_btn" >تعيين كطالب الاسبوع</button>
                    </> 

                } */}

          {/* {
                    type=='supervisor' && <>
                    <button onClick={()=>getSuperVisorStudents()} className="btn btn-primary mt-3">الطلاب</button>

                    </>
                } */}
        </div>
      </div>
    </div>
    // <div className="classBox">

    //   <div className="box">
    //     <div className="classBoxContainer_supervisor font1">
    //       {students?.length==0 ?
    //       <div className="text-center w-100">
    //         لا يوجد طلاب
    //       </div>

    //       :students?.map((student) => (
    //         <>
    //        <div>
    //        <div>{student?.name}</div>

    //        <button className="meeting_btn font-meeting_btn">تواصل مع ولي الامر</button>
    //         </div>

    //         <div className="d-flex flex-column justify-content-center align-items-center m-3">

    //     </div>

    //         </>
    //       ))}
    //     </div>

    //     {/* <div className="d-flex flex-column justify-content-center align-items-center m-3">
    //             {
    //                 type=='class' && <div className="d-flex flex-column">
    //                 <button className="addclass_btn font-addclass_btn">اضافة موعد حصة</button>
    //                 <button className="meeting_btn font-meeting_btn">الدخول لغرفة الاجتماعات</button>
    //                 <button onClick={()=>getTeacherStudents()} className="btn btn-primary mt-3">الطلاب</button>
    //             </div>
    //             }

    //             {
    //                 type=='student'&& <>
    //                 <button onClick={()=>getStudentResults(classObj?._id)} className="addclass_btn font-addclass_btn mt-3">عرض النتائج</button>
    //                 <button onClick={()=>setStudentAsWeekStudent(classObj?._id)} className="meeting_btn font-meeting_btn" >تعيين كطالب الاسبوع</button>
    //                 </>

    //             }

    //             {
    //                 type=='supervisor' && <>
    //                 <button onClick={()=>getSuperVisorStudents()} className="btn btn-primary mt-3">الطلاب</button>

    //                 </>
    //             }

    //         </div> */}
    //   </div>
    // </div>
  );
}
