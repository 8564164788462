import React, { useContext, useEffect, useRef, useState } from 'react';
import iconAi from "../../assets/iconChat.png";
import iconAiDark from "../../assets/iconChatDark.png";
import { Modal } from "react-bootstrap";
import "../../styles/conversation.css";
import { HomePage } from '../../utils/Translation/HomePage';
import { useSelector } from 'react-redux';
import { IoMdSend } from "react-icons/io";
import { aiConversation, getInlineData } from '../../utils/ai';
// import { ErrorMsg } from '../shared/modules/Notifications';
import { IoIosAttach } from "react-icons/io";
import { ImSpinner } from "react-icons/im";
import Alert from 'react-bootstrap/Alert';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeContext, ThemeProvider } from '../../ThemeProvider';
import axios from 'axios';
import { urlBase } from '../../utils/API';
import { ErrorMsg } from '../shared/modules/Notifications';

const ConversationModal = ({ title }) => {
    const [show, setShow] = useState(false);
    const [messages, setMessages] = useState([]);
    const [handleError, setHandleError] = useState(null);
    const [inputText, setInputText] = useState('');
    const [file, setFile] = useState(null);
    const [type, setType] = useState(null);
    const [isTyping, setIsTyping] = useState(false);
    const fileInputRef = useRef(null);
    const handleClose = () => setShow(false);
    const handleShow = (type) => {
        setShow(true)
        setType(type)
    };
    const chatEndRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const loggedUser = useSelector((state) => state.loggedUser);

    const navigate = useNavigate();

    const { theme, toggleTheme } = useContext(ThemeContext);

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' }); // تحريك الـ scroll إلى الأسفل بسلاسة
        }
    }, [messages]); // يتم استدعاء useEffect عند تحديث الرسائل

    // دالة لإرسال الرسالة
    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!loggedUser?.auth) {
            ErrorMsg(`${HomePage.shouldLogin[lang]}`)
            return navigate('/login')
        }
        try {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                setLoading(true)
                // const resFileInlineData = await getInlineData(formData)
                const resFileInlineData = await axios.post(`${urlBase}/api/gemini/inlineData`, formData, {
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        setLoading(progress); // تحديث حالة loading لتصبح النسبة المئوية
                    }
                });
                if (resFileInlineData?.data?.case) {
                    setLoading(false)
                }
                const newMessage = {
                    parts: [
                        { text: inputText },
                        { inlineData: resFileInlineData?.data?.inlineData }
                    ],
                    role: 'user',
                };

                setMessages(prevMessages => [...prevMessages, newMessage]);

                setInputText('');
                setFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
                setIsTyping(true);
                await handleConversation(newMessage);
            } else {

                const newMessage = {
                    parts: [
                        { text: inputText },
                    ],
                    role: 'user',
                };

                setMessages(prevMessages => [...prevMessages, newMessage]);

                setInputText('');
                setFile(null);

                setIsTyping(true);
                await handleConversation(newMessage);
            }
        } catch (e) {
            setHandleError(e)
        }
    };

    // التعامل مع تغيير الملف
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };


    // التحدث مع الـ AI
    const handleConversation = async (message) => {
        try {

            if (file) {
                const res = await aiConversation({
                    history: messages,
                    type: type,
                    message: message.parts[0].text,
                    files: [
                        {
                            inlineData: message.parts[1].inlineData
                        }
                    ]

                }, loggedUser?.user?._id);

                if (res?.data) {
                    const aiResponse = {
                        role: 'model',
                        parts: [
                            { text: res.data }
                        ]
                    };


                    setMessages(prevMessages => [...prevMessages, aiResponse]);
                }
            } else {
                const res = await aiConversation({
                    history: messages,
                    type: type,
                    message: message.parts[0].text,
                }, loggedUser?.user?._id);

                if (res?.data) {
                    const aiResponse = {
                        role: 'model',
                        parts: [
                            { text: res.data }
                        ]
                    };


                    setMessages(prevMessages => [...prevMessages, aiResponse]);
                }
            }






        } catch (e) {
            console.log(e);
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message);
                setHandleError(e?.response?.data?.message)
            }
        } finally {
            setIsTyping(false);
        }
    };

    const handleAttachClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // تفعيل الضغط على input file
        }
    };


    const [visible, setVisible] = useState(false);

    const toggleCircles = () => {
        setVisible(!visible);
    };


    return (
        <div>
            <div className="circle-container">
                <div className="main-circle" onClick={toggleCircles}>
                    <img src={theme === "light" ? iconAi : iconAiDark} alt="Main Circle w-100" />
                </div>

                <div style={{ cursor: "pointer" }} className={` ${type === 2 ? ('aiConv') : ('aiConvTwo')} small-circle bottom-circle ${visible ? 'show' : ''}`} onClick={() => { handleShow(2) }}>
                    {HomePage.helpnafsy[lang]}
                </div>
                <div style={{ cursor: "pointer" }} className={` ${type === 1 ? ('aiConv') : ('aiConvTwo')} small-circle top-circle ${visible ? 'show' : ''}`} onClick={() => { handleShow(1) }} >
                    {HomePage.helpAss[lang]}
                </div>
            </div>

            <Modal show={show} onHide={handleClose} style={{ direction: dir, color: "black" }} size="lg" centered className='font'>
                <Modal.Body>
                    <div className='text-center'>{HomePage.convAi[lang]}</div>

                    <div className='mt-5 mb-5 '>
                        {/* <Link to="/services-Ai" className='btn aiButton' >
                            {HomePage.seshatAitry[lang]}
                        </Link> */}
                        <p style={{ color: "#4169E2", fontWeight: "700" }}>
                            {/* {HomePage.setDays[lang]} */}
                        </p>
                    </div>

                    <div className='chat-box'>
                        {messages.map((msg, index) => (
                            <div key={index} className={`message ${msg.role}`}>

                                {msg.parts.map((part, i) => (
                                    <div key={i}>
                                        {part.text && <p>{part.text}</p>}
                                        {part.file && <p><strong>File:</strong> {part.file}</p>}
                                    </div>
                                ))}
                            </div>
                        ))}



                        {isTyping && (
                            <div className='message ai'>
                                <p><em>{HomePage.aiWrite[lang]}</em></p>
                            </div>
                        )}
                        <div ref={chatEndRef} />
                    </div>


                    {
                        handleError !== null ? (
                            <Alert variant={"danger"}>
                                {handleError}
                            </Alert>
                        ) : (
                            <form onSubmit={handleSendMessage}>
                                <div className='input-group'>
                                    <button type='submit' className='text-light'
                                        style={{ backgroundColor: theme === 'light' ? '#4169E2' : '#001C45', width: "10%", border: "none", outline: "none", height: "42px", borderRadius: "7px" }}
                                        disabled={loading > 0 && loading < 100}  >
                                        {!loading ? <IoMdSend /> : `${loading}%`}
                                    </button>
                                    <div className='w-75'>
                                        <input
                                            type='text'
                                            className='form-control py-2 px-2 inputTitle'

                                            placeholder='Type a message...'
                                            value={inputText}
                                            onChange={(e) => setInputText(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <IoIosAttach size={"25px"} onClick={handleAttachClick} style={{ cursor: 'pointer' }} />
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: "none" }}
                                            className="form-control"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Modal.Body >
            </Modal >
        </div >
    );
};

export default ConversationModal;