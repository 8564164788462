export const loginTrans = {
    createAccount: {
        AR: 'أنشئ حسابك الشخصي',
        EN: 'Create your personal account',
        FR: "Créez votre compte personnel"
    },
    password: {
        AR: 'هل نسيت كلمة السر',
        EN: 'Forget Password'
    },
    checkbox: {
        AR: 'تذكرني',
        EN: 'Remember me'
    },
    login: {
        AR: 'سجل الدخول',
        EN: 'Log in',
        FR: "Se connecter"
    },
    student: {
        AR: 'طالب',
        EN: 'Student',
        FR: "Étudiant"
    },
    parent: {
        AR: 'ولي الأمر',
        EN: 'Parent',
        FR: "Parent"
    },
    email: {
        AR: 'البريد الالكترونى',
        EN: 'Email',
        FR: "Email"
    },
    user_name: {
        AR: 'اسم المستخدم',
        EN: 'User name',
        FR: "Nom d'utilisateur"
    },
    username_hint:{
     AR:"لا يمكن أن يحتوي اسم المستخدم على مسافات أو / أو حروف عربية  أو نقاط .",
     EN:"username can not contain spaces, / , Arabic, or .",
     FR:"le nom d'utilisateur ne peut pas contenir d'espaces, /, arabe ou ."
    },
    parent_name: {
        AR: 'أسم ولي الأمر',
        EN: 'Parent name',
        FR: 'Parent name'
    },
    parent_username:{
        AR:"اسم المستخدم لولى الامر",
        EN:"Parent username",
        FR:"Nom d'utilisateur du parent"
    },
    student_name: {
        AR: ' اسم الطالب كامل',
        EN: 'Student full name',
        FR: "Nom de l'élève"
    },
    parent_job: {
        AR: 'وظيفة الاب',
        EN: 'Parent job',
        FR: 'Profession du père'
    },
    student_phone: {
        AR: 'رقم واتساب الطالب ',
        EN: 'Student whatsapp',
        FR: "WhatsApp étudiant"
    },
    father_phone: {
        AR: 'رقم واتساب الاب',
        EN: "Father's whatsapp number",
        FR: "Numéro WhatsApp du père"
    },
    mother_job: {
        AR: 'وظيفة الام',
        EN: "Mother's job",
        FR: "Profession de la mère"
    },
    mother_phone: {
        AR: 'رقم التليفون الام',
        EN: "Mother's phone",
        FR: "Numéro de téléphone de la mère"
    },
    select_level: {
        AR: 'أختر المرحلة الدراسية',
        EN: "Choose the educational stage",
        FR: "Choisissez le niveau d'études"
    },
    create_parent_account:{
        AR:'انشاء حساب لولى الامر',
        EN:'Create parent account',
        FR:''
    },
    same_student_pass:{
        AR:'استخدم الرقم السرى للطالب فى حساب ولى الامر',
        EN:`Use the student's password in the parent's account`,
        FR:`Utiliser le mot de passe de l'étudiant dans le compte du tuteur`
    }
    ,
    choose_country: {
        AR: 'أختر المحافظه',
        EN: "Choose the governorate",
        FR: "Choisissez le gouvernorat"
    },
    select_gender: {
        AR: 'أختر النوع',
        EN: "Choose the gender",
        FR: "Choisissez le genre"
    },
    male: {
        AR: 'ذكر',
        EN: "male",
        FR: "Masculin"
    },
    female: {
        AR: 'انثي',
        EN: "female",
        FR: "Féminin"
    },
    pass_confirm: {
        AR: 'تأكيد كلمة السر',
        EN: "Confirm password",
        FR: "Confirmer le mot de passe"
    },
    pass: {
        AR: 'كلمة السر',
        EN: "Password",
        FR: "Mot de passe"
    },
    enjoy_list: {
        AR: 'ما أكثر شيء تستمتع بفعله؟',
        EN: "What do you most enjoy doing ?",
        FR: "Qu'est-ce que vous aimez le plus faire ?"
    },
    enjoy_list1: {
        AR: 'ما أكثر شيء تستمتع بفعله؟',
        EN: "Enjoying delicious food and drinks",
        FR: "Apprécier de délicieux plats et boissons"
    },
    enjoy_list2: {
        AR: 'شراء الملابس والمقتنيات الجديدة',
        EN: "Buying new clothes and items",
        FR: "Acheter de nouveaux vêtements et objets"
    },
    enjoy_list3: {
        AR: ' متابعة صفحات التواصل الاجتماعي',
        EN: "Following social media pages",
        FR: "Suivre les pages de médias sociaux"
    },
    enjoy_list4: {
        AR: ' القراءة والاطلاع',
        EN: "Reading and keeping up to date",
        FR: "Lecture et rester à jour"
    },
    enjoy_list5: {
        AR: 'قضاء وقت ممتع مع الأصدقاء أو العائلة',
        EN: "Spending quality time with friends or family",
        FR: "Passer du bon temps avec des amis ou la famille"
    },
    enjoy_list6: {
        AR: 'قضاء وقت ممتع مع أسرتي',
        EN: "Spending quality time with my family",
        FR: "Passer du bon temps avec ma famille"
    },
    enjoy_list7: {
        AR: 'مجاملة أهل والأصدقاء في مناسباتهم',
        EN: "Complimenting family and friends on their occasions",
        FR: "Complimenter la famille et les amis lors de leurs événements"
    },
    enjoy_list8: {
        AR: 'ابتكار أفكار جديدة',
        EN: "Coming up with new ideas",
        FR: "Trouver de nouvelles idées"
    },
    enjoy_list9: {
        AR: 'تكوين علاقات جديدة',
        EN: "Building new relationships",
        FR: "Établir de nouvelles relations"
    },
    enjoy_list10: {
        AR: 'ادخار المال',
        EN: "Saving money",
        FR: "Économiser de l'argent"
    },
    terms_1: {
        AR: 'باستخدامك هذا الموقع فإنك توافق على',
        EN: 'By using this website, you agree to',
        FR: 'En utilisant ce site Web, vous acceptez de'
    },
    terms_2: {
        AR: 'شروط الاستخدام',
        EN: 'Terms of use',
        FR: "Conditions d'utilisation"
    },
    and: {
        AR: 'و',
        EN: 'and',
        FR: "et"
    },
    privacy: {
        AR: 'إتفاقية الخصوصية',
        EN: 'Privacy policy',
        FR: "Politique de confidentialité"
    },
    complete_info: {
        AR: 'اكمل البيانات التاليه',
        EN: 'Complete the information',
        FR: "Complétez les informations"
    },
    completed: {
        AR: 'سجل الدخول',
        EN: 'Login',
        FR: "Terminé"
    },

    footer_about_us_title: {
        AR: "عنا",
        EN: "About",
        FR: "À propos"
    },
    footer_help: {
        AR: "مساعدة",
        EN: "Help",
        FR: "Aide"
    },
    name: {
        AR: 'الاسم',
        EN: 'Name',
        FR: "Nom"
    },
    address: {
        AR: 'العنوان',
        EN: 'Address',
        FR: "NomAdresse"
    },
    addComment: {
        AR: 'ضع تعليقا',
        EN: 'Add Comment',
        FR: "Laissez un commentaire"
    },
    send: {
        AR: 'ارسال',
        EN: 'Send',
        FR: "Envoyer"
    },
    contactUs: {
        AR: "تواصل معنا",
        EN: "Contact Us",
        FR: "Contactez"
    },
    about_us_title: {
        AR: "من نحن؟",
        EN: "Who are we ?",
        FR: "Qui sommes-nous ?"
    },
    howToHelp: {
        AR: " كيف نساعدك؟",
        EN: "How Can we help?",
        FR: "Comment pouvons-nous vous aider ?"
    },

    level: {
        AR: ' المرحلة الدراسية',
        EN: "stage",
        FR: "Niveau d'études"
    },
    contact_me: {
        AR: 'تواصل معي',
        EN: "Contact me",
        FR: "Contactez-moi"
    },
    last_seen: {
        AR: 'اخر تواجد',
        EN: "Last seen",
        FR: "Dernière connexion"
    },

    login_date: {
        AR: 'تاريخ تسجيل الدخول',
        EN: "Login date",
        FR: "Date de connexion"
    },

    parent_contact_info: {
        AR: 'معلومات التواصل بولي الامر',
        EN: "Parent's contact information",
        FR: "Coordonnées du parent"
    },
    parent_phone: {
        AR: 'رقم هاتف ولي الامر',
        EN: 'Parent Phone',
        FR: 'Parent Phone'
    },
    parent_email: {
        AR: 'البريد الالكتروني لولي الامر',
        EN: 'Parent Email',
        FR: 'Parent Email'
    },

    student_email: {
        AR: 'البريد الالكتروني  للطالب',
        EN: 'Student Email',
        FR: "E-mail de l'élève"
    },
    edit_user_info: {
        AR: 'تعديل الملف الشخصي',
        EN: 'Edit profile',
        FR: "Modifier le profil"
    },
    phone: {
        AR: 'رقم الهاتف ',
        EN: ' Phone',
        FR: "Numéro de téléphone"
    },
    save: {
        AR: 'حفظ',
        EN: 'Save',
        FR: "Enregistrer"
    },
    cancel: {
        AR: "الغاء",
        EN: "Cancel",
        FR: "Annuler"
    },

    pass_old: {
        AR: "ادخل كلمة المرور القديمة",
        EN: "Enter the old password",
        FR: "Entrez l'ancien mot de passe"
    },

    pass_new: {
        AR: "ادخل كلمة المرور الجديدة",
        EN: "Enter the new password",
        FR: "Entrez le nouveau mot de passe"
    },
    pass_change: {
        AR: "تغيير كلمة المرور",
        EN: "Change password",
        FR: "Changer de mot de passe"
    },
    show_pass:{
        AR:"اظهار كلمة المرور",
        EN:"Show Password",
        FR:"Afficher le mot de passe"
    },
    enterMeetingRoom: {
        AR: 'غرفة المحادثات',
        EN: 'Meeting Room'
    },

    accountHasNot: {
        AR: "ليس لديك حساب",
        EN: "You don't have an account"
    },
    accountHas: {
        AR: "لديك حساب",
        EN: "you have an account"
    },
    subscripe: {
        AR: "اشترك الان",
        EN: "Login Now"
    },

}