import React, { useContext } from 'react'
import male from "../../assets/male.gif"
import female from "../../assets/female.gif"
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../ThemeProvider'
const ChooseGender = () => {
    const {theme} = useContext(ThemeContext)
    return (
        <div>
            <div className="container font">
                <div className='text-center m-auto'>
                    <h2>اختر النوع</h2>
                </div>
                <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
                        <div className='text-center'>
                            <Link to={`/reservation-system/male`} className='text-center' style={{ cursor: "pointer", textDecoration: "none" }}>
                                <img src={male} alt="male" loading='lazy' />
                                <p style={{ color:theme ==="light" ? "#071D5B":"white", fontWeight: "700", textAlign: "center" }}>ذكر</p>
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className='text-center'>
                            <Link to={`/reservation-system/female`} style={{ cursor: "pointer", textDecoration: "none" }}>
                                <img src={female} alt="male" loading='lazy' />
                            </Link>
                            <p style={{ color:theme ==="light" ? "#071D5B":"white", fontWeight: "700", textAlign: "center" }}>انثي</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseGender

