import React, { useEffect, useState } from 'react'
import { getAwarnessContent } from '../../utils/content';
import { urlBase } from '../../utils/API';
const AwarnesPage = () => {
    const [isTrue, setIsTrue] = useState(false);
    const [content, setContent] = useState({ title: '', content: '', video: {} });
    let newContent = content?.content.substring(0, 150);

    useEffect(() => {
        // Example to get content, replace with actual id or logic to fetch content
        const fetchContent = async () => {
            try {
                const fetchedContent = await getAwarnessContent({});
                if (fetchedContent?.data?.awarnessContent) {
                    const currentContent = fetchedContent.data.awarnessContent[0];
                    setContent(currentContent?.awarnessContent);
                }
            } catch (error) {
                console.error('Failed to fetch content:', error);
            }
        };

        // Example: Uncomment and replace 'contentId' with actual content ID
        fetchContent();
    }, []);

    return (
        <div>
            <div className="awarenessPage mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="awarenceImage">
                                <video   >
                                    <source width={'100%'} src={`${urlBase}/${content?.video?.name}`}></source>
                                </video>
                            </div>{" "}
                        </div>
                        <div className="col-md-6">
                            <div className="contentAwarenc mt-5 mt-md-0">
                                <p id="awarenceId241" className="awaernceTitle">
                                    {content?.title}
                                </p>
                                <p id="awarenceId2141" className="awaernceContent">
                                    {isTrue ? content?.content : newContent}....
                                    <span
                                        className="awaernceMore"
                                        id="awarenceId21414"
                                        onClick={(event) => {
                                            setIsTrue(true);
                                            event.target.style.display = "none";
                                        }}
                                    >
                                        تعرف أكثر
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AwarnesPage
