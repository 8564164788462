import React, { useState, useRef } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

function OrderInvoice({ order }) {
    const [show, setShow] = useState(false);
    const modalContentRef = useRef(null);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handlePrint = () => {
        const printContents = modalContentRef.current.innerHTML;
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Print Invoice</title>');
        printWindow.document.write('</head><body dir=rtl>');
        printWindow.document.write(printContents);
        // printWindow.document.write('<button onclick="window.print();window.close();" style="position:fixed;bottom:10px;right:10px;">Print Invoice</button>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
    };

    return (
        <>
            <p onClick={handleShow} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                فاتورة
            </p>
            <Modal dir={'rtl'} show={show} onHide={handleClose} size="lg" >
              <div ref={modalContentRef}>
                <Modal.Header closeButton>
                    <Modal.Title>فاتورة </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ marginBottom: '20px' }}>
                        {/* <h5>تفاصيل الشركة</h5> */}
                        <p><strong>اسم الشركة:</strong> منصة برديات التعليمية</p>
                        <p><strong>البريد الإلكتروني:</strong> info@bardiaat.com</p>
                        <p><strong>الموقع الإلكتروني:</strong> <a href="https://www.bardiaat.com" target="_blank" rel="noopener noreferrer">www.bardiaat.com</a></p>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <h5>تفاصيل العميل</h5>
                        <p><strong>اسم العميل:</strong> {order?.userID?.name}</p>
                        <p><strong>رقم الهاتف:</strong> {order?.userID?.phone}</p>
                        <p><strong>العنوان:</strong> {order.address}</p>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <h5>تفاصيل الطلب</h5>
                        <Table striped bordered hover className="invoice-table">
                            <thead>
                                <tr>
                                    <th>كود الطلب</th>
                                    <th>التاريخ</th>
                                    <th>المنتج</th>
                                    <th>العدد</th>
                                    <th>السعر</th>
                                    <th>الحالة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{order._id}</td>
                                    <td>{new Date(order.date).toLocaleString()}</td>
                                    <td>{order.productID?.name}</td>
                                    <td>{order.units}</td>
                                   <td>{order.productID?.price}</td>
                                    <td>{order.response}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div>
    
                    </div>
                </Modal.Body>
                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        إغلاق
                    </Button>
                    <Button variant="primary" onClick={handlePrint}>
                        طباعة الفاتورة
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default OrderInvoice;
