import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import "../../styles/society.css"
import colors from '../shared/Constant';
const Society = () => {
    const navigate  = useNavigate();
    const handleRouteCommunity = async()=>{
        navigate('/bardiat-community', { state: { filterTeacherPostRoute: true} });
    }
    return (
        <div>
            <div className="society mt-3 py-5 ">
                <div className="col-md-8 mx-auto ">
                    <div className="row ">
                        {/* <div className="weekStd  mb-3 mb-md-0 py-5 col-md-6 d-flex align-items-center shadow ">
                            <div className=" w-100 ">
                                <p className="anyQuestionTitle font" style={{color:`${colors.color}`}}>طالب الأسبوع</p>
                                <p className="anyQuestionContent text-decoration-none " style={{background:`${colors.sharedColor}`}}>
                                    <NavLink className=" text-decoration-none font" style={{ color: "white" }} to="/bardiat-community">اذهب الي المجتمع</NavLink>
                                </p>
                            </div>
                        </div> */}
                        <div>
                            <div className="anyQuestion">
                                <div className="uperBox comBox mb-3 shadow">
                                    <p id="anyQuestionId1" className="anyQuestionTitle font" style={{color:`${colors.color}`}}>
                                        أسئلة المسابقة
                                    </p>
                                    <p onClick={handleRouteCommunity} id="anyQuestionId1" className="anyQuestionContent font" style={{background:`${colors.sharedColor}`}}>
                                        أجب الآن
                                    </p>
                                </div>
                                {/* <div className="lowerBox comBox shadow mt-3">
                                    <p id="anyQuestionId1" className="anyQuestionTitle font" style={{color:`${colors.color}`}}>
                                        لديك سؤال ؟
                                    </p>
                                    <NavLink to='/bardiat-community' id="anyQuestionId1" className="anyQuestionContent font" style={{background:`${colors.sharedColor}`}}>
                                        أسأل الآن
                                    </NavLink>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Society
