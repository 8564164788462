import { NEW_CLASS,GET_CLASSES,ADD_STUDENTS,CHANGE_ACTIVE_STATE, ADD_CLASS_TEACHER, ADD_STUDENT_TO_SUPERVISOR } from "../actions/class";

export const classes = (state=[],action)=>{
    switch(action.type){
        case NEW_CLASS:
            return state= [...state,action.Class]
        case GET_CLASSES: 
            return state = action.classes
        case ADD_STUDENTS:
            return state.map(s=>{
                if(s._id===action.Class._id){
                    return action.Class
                }
                return s
            })
        case ADD_CLASS_TEACHER:
            return state.map(s=>{
                if(s._id===action?.Class?._id){
                    return action?.Class
                }
                return s
            })
        case CHANGE_ACTIVE_STATE:
            return state.map(s=>{
                if(s._id===action.Class._id){
                    return action.Class
                }
                return s
            })
        case ADD_STUDENT_TO_SUPERVISOR:
            return state.map(s=>{
                if(s._id===action.cls._id){
                    return action.cls
                }
                return s
            })
        default: return state
    }
}

