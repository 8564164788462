import React from 'react'
import "../../../../styles/dashboard.css"
import materialImage from '../../../../assets/subscribe.png'
import MaterialModal from './MaterialModal'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';

import ChooseMaterialModal from '../../../modals/ChooseMaterialModal'
import HonoraryModal from '../../../modals/HonoraryModal'
import SchedualModal from "./SchedualModal"
import { urlBase } from '../../../../utils/API'

const SubjectCard = ({ program }) => {


    const loggedUser = useSelector((state) => state.loggedUser);

    const formatToCairoTime = (dateString) => {
        const date = new Date(dateString); // Create Date object from creationDate string
        const options = {
            timeZone: 'Africa/Cairo', // Set time zone to Cairo
            weekday: 'long', // Display full weekday name (e.g., "Monday")
            year: 'numeric', // Display full year (e.g., "2024")
            month: 'long', // Display full month name (e.g., "January")
            day: 'numeric', // Display day of the month (e.g., "1")
            hour: 'numeric', // Display hour (e.g., "1" or "01" for 1 AM/PM)
            minute: 'numeric' // Display minute (e.g., "30")
        };
        return date.toLocaleString('en-US', options); // Format to Cairo local time
    };

    console.log(program)
    return (
        <div className='material' dir='rtl'>
            <div className="card font">
                <img src={`${urlBase}/${program?.packData?.coverImage}`} alt="Books" className="card-img" />
                {/* <div className='titleMaterialCard background font'>
                    
                </div> */}
                <div className="card-content font">
                    <h6>{program?.packData?.name}</h6>
                    <p>تاريخ التسجيل: {program?.date.split('T')[0]}</p>
                    <p>اسم البرنامج: {program?.packData?.name}</p>
                    <p>سعر البرنامج: {program?.packData?.price}</p>
                    <div className="card-buttons">
                        {/* <button className="card-btn text-decoration-none text-center">متابعة الدراسه</button> */}
                        <div>
                            {
                                loggedUser?.user?.role === "parent" ? (<p>لا تتوفر المتابعه</p>) : (
                                    program?.packData?.delayed !== undefined ? (
                                        program?.packData?.delayed === false ? (
                                            <div>
                                                <ChooseMaterialModal subject={program} />
                                            </div>
                                        ) : (null)
                                    ) : (
                                        <div>
                                            <ChooseMaterialModal subject={program} />
                                        </div>
                                    )
                                )
                            }
                        </div>

                        <div>
                            {
                                program?.packData?.delayed !== undefined ? (
                                    program?.packData?.delayed === false ? (

                                        program?.Class !== null ? (
                                            <Dropdown className='w-100'>
                                                <Dropdown.Toggle className='w-100' id="dropdown-basic">
                                                    اختيارات
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='w-100'>
                                                    <Dropdown.Item>
                                                        <MaterialModal dynamic={"programs"} title={"عدد المواد"} subject={program} />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <HonoraryModal weeksStudents={program?.Class?.weekStudents} />
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <p>تواصل مع المشرف</p>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <SchedualModal schedual={program?.Class?.schedual} />
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ) : (
                                            <p>لم يتم اضافتك لفصل دراسي بعد</p>
                                        )

                                    ) : (<p>سيتم بدأ العام الدراسي في {formatToCairoTime(program?.packData?.startDate)}</p>)
                                ) : (
                                    program?.Class !== null ? (
                                        <Dropdown className='w-100'>
                                            <Dropdown.Toggle className='w-100' id="dropdown-basic">
                                                اختيارات
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='w-100'>
                                                <Dropdown.Item>
                                                    <MaterialModal dynamic={"programs"} title={"عدد المواد"} subject={program} />
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <HonoraryModal weeksStudents={program?.Class?.weekStudents} />
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <p>تواصل مع المشرف</p>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <SchedualModal schedual={program?.Class?.schedual} />
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (<p>لم يتم اضافتك لفصل دراسي بعد</p>)
                                )
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubjectCard
