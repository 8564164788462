import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/layout/PagecContainer'
import DetailsProgram from '../../components/Details/DetailsProgram'
import { getPackageById } from '../../utils/packages'
import { useParams } from 'react-router-dom'
import Chat from '../../components-old/chat'
const ProgramDetails = () => {

    const [program, setProgram] = useState([]);

    const params = useParams();
    const getDetailsPackageId = async (id) => {
        const programDetails = await getPackageById({
            _id: id,
        });

        if (programDetails?.data?.case) {
            setProgram(programDetails)
        }
    }

    useEffect(() => {
        getDetailsPackageId(params.id);
    }, [params.id])
    return (
        <div className='font'>
            <PageContainer>
                <Chat />
                <main id="details" style={{ marginTop: "100px" }}>
                    <DetailsProgram program={program?.data?.package} />
                </main>
            </PageContainer>
        </div>
    )
}

export default ProgramDetails
