import React, { useState, useEffect, useRef } from 'react'
import Header from './Header';
import './userDashboard.css';
import SideNav from '../Admin/sideNav.jsx';
import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import dashIcon from '../../assets/dashIcon.svg';
// import classManagIcon from '../../assets/classManag.svg';
import { TbMessages } from 'react-icons/tb';
// import {IoIosNotificationsOutline} from 'react-icons/io';
import { CgProfile } from 'react-icons/cg';
import { MdClass } from 'react-icons/md';
import MobileNave from './mobileNave'
import { Inetialize_Users } from "../../actions/teachers";

import { useDispatch, useSelector } from 'react-redux';
import { Get_Materials } from '../../actions/curriculums/materials';
import { Inetialize_Levels } from '../../actions/curriculums/levels.js';
import HeaderDefault from '../../components/shared/HeaderDefault.js';
import { Add_Socket } from '../../actions/etcMeeting.js';
import { io } from 'socket.io-client';
import alertSound from '../../assets/alert-message.wav'
import { Get_All_My_Messages, sendMessage } from '../../actions/massenger.js';
import { urlBase } from '../../utils/API.js';
import { Get_All_Packages_Subscriptions } from '../../actions/packagesubscriptions.js';
import { Get_Zoom_Users } from '../../actions/zoomAction.js';


function UserDashboard({ user }) {
  const params = useParams();
  const socketRef = useRef(null);

  // console.log(params  )
  const taps = [
    {
      name: "فصلى", path: "", icon: <MdClass />
    },
    { name: 'الرسائل', path: 'messages', icon: <TbMessages /> },
    { name: 'حسابى ', path: 'profile', icon: <CgProfile /> },
  ]


  const adminTaps = [
    { name: "الاشتراكات", path: "" },
    { name: "المستويات", path: "branch-level" },
    // { name: "Eduction Type", path: "eduction-type" },
    { name: "المواد", path: "materials" },
    { name: "البرامج", path: "packages" },
    { name: "الفصول", path: "classes" },
    { name: "الطلاب", path: "students" },
    { name: "المعلمون", path: "teachers-list" },
    { name: "المشرفين", path: "supervisors" },
    { name: 'المتجر', path: 'store-control' },
    { name: 'المحتوى التوعوى', path: 'add-wareness-content' },

    // {name:'المنتجات',path:'products'},
    // {name:"اضافة منتج",path:"New-Product"},
    // {name:"اضافة كود خصم", path:'new/disscount'},
    // {name:" اكواد الخصم", path:'list/disscount'},
    { name: "المديرين", path: "accounts" },
    { name: "الخدمات", path: "packages-services" },
    { name: "Zoom Setting", path: "zoom-setting" },
    { name: "media", path: "my-media" },
    { name: "التحويلات", path: "all-transactions" },

  ]

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const dispatch = useDispatch();

  const loggedUser = useSelector((state)=>state.loggedUser)

  useEffect(() => {
    if (user.role === 'Admin') {
      dispatch(Inetialize_Users('supervisor'))
      dispatch(Get_Materials());
      dispatch(Inetialize_Levels());
      dispatch(Inetialize_Users('teacher'))
      dispatch(Inetialize_Users('student'))
      dispatch(Get_All_Packages_Subscriptions())
      dispatch(Get_Zoom_Users(loggedUser?.user?._id));
    }
  },[])
  
    useEffect(() => {
      if (user.auth && !socketRef.current) {
          socketRef.current = io.connect(urlBase);
          dispatch(Add_Socket(socketRef));
          socketRef.current.emit('user_connected', { userId: user._id });
      }
      return () => {
          // Cleanup the socket connection when the component unmounts
          if (socketRef.current) {
              socketRef.current.disconnect();
          }
      };
  }, [ user.auth, user._id]);
  
  
      // Handle receiving messages through the socket connection
      useEffect(() => {
        if (socketRef.current) {
            dispatch(Get_All_My_Messages({ ownerID: user?._id }));
            socketRef.current.on("accept_message", (data) => {
                dispatch(sendMessage(data));
                new Audio(alertSound).play();
            });
        }
    }, [ user?._id]);
  
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className='user-dashboard mt-5' dir='rtl'>
      <div className='user-dash-header'>
        <HeaderDefault />
        {/* <Header menuColor={'#333'}/> */}
      </div>
      <div className='user-dash-body'>
        <div className='subhead'>
          <img src={dashIcon} alt='dashIcon' />
          <span id='suh1'>
            Dashboard
          </span>
        </div>
        <div className='font'>
          {windowSize.width > 900 ? <SideNav taps={user.role === 'Admin' ? adminTaps : taps} /> :
            <MobileNave taps={user.role === 'Admin' ? adminTaps : taps} />
          }            
        </div>
        <div id='sub-body'>
          <Outlet />
        </div>
      </div>
    </div>

  )
}

export default UserDashboard
