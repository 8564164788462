import { useState } from "react"

export default function SetStudentDegreeComponent({ question, setEditedQuestionsArr,index }) {
    const [degree, setDegree] = useState(question.degree);
    return (
        <div className="d-flex gap-5 py-3">
            <div> درجة الطالب </div>
            <div>
                <input
                    type="number"
                    value={degree}
                    onChange={(e) => {
                        if (e.target.value <= question.degree) {
                            setDegree(e.target.value);
                            setEditedQuestionsArr(p => 
                                p.map((el,i)=>
                                    (i == index) ?
                                    { ...question, answerDegree: e.target.value }
                                    : el
                                ) 
                               );
                        }
                        // else if(e.target.value==''){
                        //     setEditedQuestionsArr(p => 
                        //         p.map((el,i)=>
                        //             (i == index) ?
                        //             { ...question }
                        //             : el
                        //         ) 
                        //        );
                        // }
                        else {
                            setEditedQuestionsArr(p => 
                                p.map((el,i)=>
                                    (i == index) ?
                                    { ...question, answerDegree: question.degree }
                                    : el
                                ) 
                               );
                            //setDegree(question.degree);
                        }
                    }
                    }
                    max={question.degree}
                    min={0}
                />
            </div>
        </div>
    )
}