import { CREATE_ZOOM_USER, GET_ZOOM } from "../actions/zoomAction"


export const zoomReducer = (state = [], action) => {
    switch (action.type) {
        case GET_ZOOM:
            return state = action.users
        case CREATE_ZOOM_USER:
            return state = [...state, action.user]
        default: return state
    }
}



// import { GET_ALL_PACKAGES, CREATE_NEW_PACKAGE, UPDATE_PACKAGE, DELETE_PACKAGE } from "../actions/packages";

// export const packages = (state = [], action) => {
//   switch (action.type) {
//     case GET_ALL_PACKAGES:
//       return state = action.packages
//     case CREATE_NEW_PACKAGE:
//       return state = [...state, action.pack]
//     case UPDATE_PACKAGE:
//       return state = state.map(s => {
//         if (s._id === action.pack._id) {
//           return action.pack
//         }
//         return s
//       })
//     case DELETE_PACKAGE:

//       return state = state.filter(s => s._id !== action.id)
//     default: return state
//   }
// }

