import React from 'react';
import { useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';

function Categories({ setSelectedCategory, selectedCategory }) {
    const categories = useSelector(state => state.store.categories);
// console.log('catt',categories)
    return (
        <div style={{boxShadow:'2px 2px 5px 1px green',padding:'10px'}}>
            {categories?<ListGroup>
                {categories.map(category => (
                    <ListGroup.Item
                        key={category._id}
                        onClick={() => setSelectedCategory(category._id)}
                        action
                        active={category._id === selectedCategory}
                    >
                        {category.name}
                    </ListGroup.Item>

                ))}
            </ListGroup>
        :<h4>لا توجد فئات مضافة</h4>    
        }
        </div>
    );
}

export default Categories;
