import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const ShowMessae = ({ sub }) => {
    const [show, setShow] = useState(false);
    const handleOpenModal = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false)
    }

    console.log(sub)
    return (
        <div>
            <button className='btn btn buttonsGroub' onClick={handleOpenModal}>
                message
            </button>
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Body>
                    <div className='text-center'>
                        <p>{sub?.key}</p>
                        <p>{sub?.message}</p>

                        <Link to={'/service-type/STORAGE'} className='btn btn-primary'>Storage Package</Link>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ShowMessae
