import { useSelector } from "react-redux";
import ClassRowComponent from "./ClassRowComponent";
import { useEffect, useState } from "react";
import ShowLessonsResultsModal from "./ShowLessonsResultsModal";
import ClassRowSuperVisorComponent from "./ClassRowSuperVisorComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserByID } from "../../../utils/API";
import ContactButton from "../../publicPges/shareCommponents/HeaderDashBoard/ContactButton";
import { HomePage } from "../../../utils/Translation/HomePage";
import { Col, Row } from "react-bootstrap";

export default function StudentsTableMaterialComponent() {
  const { teacherStudents, classes, user } = useSelector(
    (state) => state.loggedUser
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [resultsTable, setResultsTable] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [weekStudent, setWeekStudent] = useState(null);
  const [teacherMaterialCode, setTeacherMaterialCode] = useState();
  const [materialTeacher, setMaterialTeacher] = useState(() => {
    if (user.role == "teacher") return null;
    let selectedClass = location?.state;
    const teacher = selectedClass.teachersIDS.find(el => el.materialCode === selectedClass.selectedMaterial.code)?.teacher;

    return teacher;

  });

  const { lang, dir } = useSelector(({ language }) => {
    return {
      lang: language.lang,
      dir: language.dir
    }
  })


  let selectedClass = location?.state;

  useEffect(() => {
    const get = async () => {
      try {
        if (user.role === "teacher") {
          const teacherMaterialCode = selectedClass.teachersIDS.find(el => el.teacher === user?._id)?.materialCode;

          setTeacherMaterialCode(teacherMaterialCode);
          //console.log('teacherMaterialCode',teacherMaterialCode);
          const student = selectedClass.weekStudents.find(el => el.materialCode === teacherMaterialCode)?.studentID;
          //console.log('weekStudent',student);
          const res = await getUserByID({ userID: student });
          //console.log('studentRes',res?.data?.user);    
          setWeekStudent(res?.data?.user);
        }
        else {
          //for super visor account
          const teacherID = selectedClass.teachersIDS.find(el => el.materialCode === selectedClass.selectedMaterial.code)?.teacher;

          console.log("teacherID", teacherID);

        }
      }
      catch (err) {
        console.error(err.message);
      }
    }

    get();

  }, []);


  // console.log("selectedClass", selectedClass);
  // console.log("materialTeacher", materialTeacher);
  //console.log('user',user.username);

  return (
    <>
      <ShowLessonsResultsModal
        showModal={showModal}
        setShowModal={setShowModal}
        resultsTable={resultsTable}
        selectedStudent={selectedStudent}
      />

      <div className="TeacherClassesComponent" style={{ direction: "rtl" }}>
        {user?.role === "teacher" ? (
          <>
            <h4 className="font m-4" style={{ color: "#0F2A51" }}>  طالب الاسبوع: {weekStudent?.name} </h4>

            {
              teacherStudents?.map(el =>
                <ClassRowComponent
                  classObj={el}
                  type="student"
                  key={el}
                  setResultsTable={setResultsTable}
                  setShowModal={setShowModal}
                  setSelectedStudent={setSelectedStudent}
                  setWeekStudent={setWeekStudent}
                  teacherMaterialCode={teacherMaterialCode}
                />
              )
            }
          </>
        ) : (
          <>
            <Row>
              <Col>
                <ContactButton
                  name={HomePage.contact_teacher[lang]}
                  class_name="font"
                  contactID={materialTeacher?._id}
                  span={materialTeacher?.name}
                  contactRole="supervisor"
                />
              </Col>
              <Col>
                <button onClick={() => {
                  const roomId = user?._id + materialTeacher?._id;
                  navigate(`/meeting-room/${user?.username}/:${user?._id}/:${roomId}`);
                }}
                className='btn special color' >  الاجتماع مع المعلم </button>
              </Col>
            </Row>
            {
              selectedClass?.selectedMaterialstudentIDS?.map((el, i) => (
                <ClassRowSuperVisorComponent
                  setResultsTable={setResultsTable}
                  setShowModal={setShowModal}
                  setSelectedStudent={setSelectedStudent}
                  student={el}
                  classObj={selectedClass}
                />
              ))
            }
          </>

        )}
      </div>
    </>
  );
}
