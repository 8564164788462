import { createZoom, deleteZoomUser, getAllZoom } from "../utils/zoom";


// Create Zoom
export const CREATE_ZOOM_USER = "CREATE_ZOOM_USER";

const creaeZoomUserData = (user) => {
    return {
        type: CREATE_ZOOM_USER,
        user
    }
}

export const Create_Zoom_User = (data) => {
    return dispatch => createZoom(data).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(creaeZoomUserData(res.data.user));
        }
        return res
    })
}


// Get Zoom 
export const GET_ZOOM = "GET_ZOOM";

const getZoom = (users) => {
    return {
        type: GET_ZOOM,
        users
    }
}

export const Get_Zoom_Users = (userId) => {
    return dispatch => getAllZoom(userId).then(res => {
        console.log(res)
        if (res.data.case) {
            dispatch(getZoom(res?.data?.users?.users));
        }
        return res
    })
}


// Delete zoom
export const DELETE_ZOOM = "DELETE_ZOOM";

const delZoom = (users) => {
    return {
        type: DELETE_ZOOM,
        users
    }
}

export const Del_Zoom = (id) => {
    return dispatch => deleteZoomUser(id).then(res => {
        console.log(res)
        // if (res.data.case) {
        //     dispatch(delZoom(res?.data?.users?.users));
        // }
        return res
    })
}