import { CREATE_LEVELE, DELETE_LEVEL, INETIALIZE_LEVELS, EDIT_LEVEL } from "../actions/curriculums/levels";
import { ADD_BRANCH, DELETE_BRANCH, EDIT_BRANCH, INETIALIZE_BRANCHS } from '../actions/curriculums/branchs'
import { ADD_MAIN_MATERIAL_TO_BRANCH, DELETE_MAIN_MATERIAL_FROM_BRANCH, UPDATE_MAIN_MATERIAL_FROM_BRANCH } from "../actions/mainMaterial";

export const levels = (state = [], action) => {
    switch (action.type) {
        case CREATE_LEVELE:
            return state = [...state, action.level]
        case INETIALIZE_LEVELS:
            return state = action.levels
        case DELETE_LEVEL:
            return state = state.filter(l => l._id !== action.id)
        case EDIT_LEVEL:
            return state = state.map(s => {
                if (s._id === action.level._id) {
                    return action.level
                }
                return s
            })
        default: return state
    }
}

export const branchs = (state = [], action) => {
    switch (action.type) {
        case ADD_BRANCH:
            return state = [...state, action.branch]
        case ADD_MAIN_MATERIAL_TO_BRANCH:
            console.log("Actions Branch", action)
            console.log("State Branch", state)
            return state = state.map(s => {
                if (s._id === action.branchId) {
                    return { ...s, mainMaterials: [...s.mainMaterials, action.material] }
                }
                return s
            })
        case DELETE_MAIN_MATERIAL_FROM_BRANCH:
            return state.map(s => {

                if (s._id === action.branchId) {

                    return {
                        ...s,
                        mainMaterials: s.mainMaterials.filter(mainMat => mainMat._id !== action.id)
                    };
                }
                return s; 
            });
        case UPDATE_MAIN_MATERIAL_FROM_BRANCH:
            return state.map(s => {

                if (s._id === action.branchId) {
                    return {
                        ...s,

                        mainMaterials: s.mainMaterials.map(mainMat =>
                            mainMat._id === action.material._id ? action.material : mainMat
                        )
                    };
                }
                return s;
            });
        case INETIALIZE_BRANCHS:
            return state = action.branchs
        case DELETE_BRANCH:
            return state = state.filter(l => l._id !== action.id)
        case EDIT_BRANCH:
            return state = state.map(s => {
                if (s._id === action.branch._id) {
                    return action.branch
                }
                return s
            })
        default: return state
    }
}
