import React, { useState, useEffect, useContext } from 'react';
import Timeline from './Timeline';
import PageContainer from '../layout/PagecContainer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import "./timeline.css";
import SharedImage from './SharedImage';
import GetLevelName from '../shared/GetLevelName';
import Spinner from 'react-bootstrap/Spinner';
import { ThemeContext } from '../../ThemeProvider';

const Selectclassroom = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true); // حالة التحميل
    const [levels, setLevels] = useState([]); // حالة البيانات
    const [selectedBranch, setSelectedBranch] = useState(null);
    const {theme} = useContext(ThemeContext);
    const handleBranchClick = (level) => {
        setSelectedBranch(level);
    };

    const handleNavigate = () => {
        navigate(-1);
    };

    // جلب بيانات المستويات عند تحميل الصفحة
    useEffect(() => {
        const fetchLevels = async () => {
            setLoading(true); // بدء التحميل
            // محاكاة تأخير استرجاع البيانات
            setTimeout(() => {
                const fetchedLevels = location.state?.levelData?.levelsIDS || [];
                setLevels(fetchedLevels);
                setLoading(false); // انتهاء التحميل
            }, 1500); // يمكن استبدال هذا بمنطق الجلب الحقيقي
        };

        fetchLevels();
    }, [location.state]);

    // شاشة التحميل
    if (loading) {
        return (
            <div className="loading-screen" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f8f9fa'
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className='container'>
                        <Timeline activeStep={3} />
                        <div className='row justify-content-between align-items-center' style={{ marginTop: "180px" }}>
                            <div className='col-lg-4 col-sm-12 col-md-12 '>
                                <SharedImage type={"room"} />
                            </div>
                            <div className='col-lg-8 col-sm-12 col-md-12'>
                                <div className='row justify-content-center align-items-center font mb-5'>
                                    {levels.map((level, index) => (
                                        <div className='col-lg-3' key={level}>
                                            <Link to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${level}`} className={`text-center branchDiv ${selectedBranch === level ? 'active' : ''}`}
                                                onClick={() => handleBranchClick(level)}>
                                                <p>
                                                    <GetLevelName levelId={level} />
                                                </p>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='m-auto text-center mt-4 mb-5 font'>
                            <button onClick={handleNavigate} className='btn w-25 mx-2 prev' style={{ color:theme ==="light" ? "#071D5B":"white",}}>السابق</button>
                            <Link to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${selectedBranch}`}
                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color:"white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >
                                التالي
                            </Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    );
};

export default Selectclassroom;
