import {SHOW_MESSAGE_BOX, SEND_MESSAGE,GET_ALL_MY_MESSAGES,GET_MESSAGES_OF_CONTACT } from "../actions/massenger";

export const contacts = (state={show:false,contacts:[],viewContact:{}},action)=>{
    switch(action.type){
        case SHOW_MESSAGE_BOX:
            return state={...state,show:!state.show}
        case SEND_MESSAGE:
            const checkContact= state.contacts.length?state.contacts.filter(c=>c.contactID===action.contact.contactID):[];
            if(checkContact.length){
                return state={...state,contacts:state.contacts.map(s=>{
                    if(s.contactID ===action.contact.contactID && s.ownerID === action.contact.ownerID){
                        return {...s,...action.contact}
                    }
                    return s
                }),viewContact:{...state.viewContact,...action.contact}}
            }
            else{
                return {
                    ...state,
                    contacts: [...state.contacts, action.contact],
                    viewContact: {...state.viewContact,...action.contact}
                };
            }
        case GET_ALL_MY_MESSAGES:
            console.log('action',action);
            return state = {...state,contacts:action.contacts}
        case GET_MESSAGES_OF_CONTACT:
            return state = {...state,viewContact:action.contact,ownerID:action.ownerID,contactID:action.contactID}
        default : return state
    }
}