import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaCamera } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { update_user_info } from "../../../actions/login&register";
import { urlBase } from '../../../utils/API';
import axios from 'axios';
const ChangeImgProfile = ({ type }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [file, setFile] = useState();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.loggedUser);

    // console.log("user in modal pic");
    // console.log(user?.user?._id);

    const upload = async () => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("property", type);
            formData.append("id", user?.user?._id);

            const response = await axios({
                method: "POST",
                url: `${urlBase}/api/upload-user-files`,
                data: formData,
            });

            if (response?.data?.case === true) {
                let user = response?.data?.user;
                dispatch(update_user_info(user));

            }
            console.log("response");
            console.log(response);

            setShow(false);

        } catch (error) {
            console.error(error.message);
        }

    };
    return (
        <div className='font'>
            <FaCamera onClick={handleShow} />
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Body>
                    <input
                        type="file"
                        className="form-control mt-2"
                        onChange={(event) => {
                            setFile(event.target.files[0]);
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn special color" onClick={handleClose}>
                        الغاء
                    </button>
                    <button className='btn buttonsGroub' onClick={upload}>
                        حفظ
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ChangeImgProfile
