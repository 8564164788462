import React, { useState } from 'react'
import { Modal, Button, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Gates from "../../../assets/gate.png"
import { ErrorMsg, success } from '../../shared/modules/Notifications';
import { renewSubscriptions } from '../../../utils/API';
import Spinner from 'react-bootstrap/Spinner';
import { HomePage } from '../../../utils/Translation/HomePage';

const RenewSubscriptions = ({ sub }) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShowModal(false);

    const handleShow = () => {
        setShowModal(true);
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const loggedUser = useSelector((state) => state.loggedUser);
    const [loading, setLoading] = useState(false)


    // console.log(sub)



    const handleRenew = async (e) => {
        try {
            setLoading(true)
            const res = await renewSubscriptions({
                subscriptionPeriod: e
            }, sub?.packageSubscriptionData?._id, loggedUser?.user?._id)
            console.log(res)
            if (res?.data?.case) {
                if (res?.data?.subscription?.hppUrl) {
                    success(res?.data?.message)
                    window.location.href = `${res?.data?.subscription?.hppUrl}`;
                    handleClose();
                } else {
                    success(`تم الاشتراك بنجاح`);
                    handleClose();
                }
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }


    return (
        <div>
            <button className='btn' onClick={handleShow} style={{ background: "#2C6DCE", color: "white" }}>
                {HomePage.renewWord[lang]}
            </button>
            <Modal show={showModal} onHide={handleClose} centered className='font' dir={dir}>
                <Modal.Body >
                    <div className='text-center'>
                        <img src={Gates} alt="Gates" className='w-100' />
                        <div className='mt-5'>
                            <button className='btn' style={{ background: "#2C6DCE", color: "white" }}
                                onClick={() => { handleRenew('monthly') }}>
                                {HomePage.renewPerMonth[lang]}
                            </button>
                            <button className='btn mx-2' style={{ background: "#2C6DCE", color: "white" }}
                                onClick={() => { handleRenew('annually') }}>
                                {HomePage.renewPerAnnual[lang]}
                            </button>

                            {
                                loading ? (
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : null
                            }
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RenewSubscriptions
