import React, { useContext, useState } from 'react';
import PageContainer from '../layout/PagecContainer';
import contact from "../../assets/contact.png";
import "../../styles/contact.css";
import { IoLocation } from "react-icons/io5";
import { MdLocalPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import SharedImg from '../shared/modules/SharedImg';
import SharedStatic from './modules/SharedStatic';
import { InfoMsg, success } from '../shared/modules/Notifications';
import { contactUsForm } from '../../utils/contact';
import { IoLogoWhatsapp } from "react-icons/io";
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
import { loginTrans } from '../../utils/Translation/loginAndRegister';
import { ThemeContext } from '../../ThemeProvider';

const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [comment, setComment] = useState("");
    const [phone, setPhone] = useState("");
    const [response, setResponse] = useState("");

    let handelSubmit = async (event) => {
        event.preventDefault();
        let formData = {
            name,
            email,
            phone,
            address,
            comment,
        };
        try {
            const res = await contactUsForm(formData);
            console.log(res);
            if (res?.data?.case) {
                setResponse(res?.data?.message);
                success(res?.data?.message);
                setName("");
                setEmail("");
                setAddress("");
                setComment("");
                setPhone("");
            } else {
                setResponse("Something went wrong, please try again.");
            }
        } catch (e) {
            setResponse("Something went wrong, please try again.");
        }
        console.log("formData", formData);
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const {theme} = useContext(ThemeContext)

    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-u">
                    <SharedImg img={contact} title={`${HomePage.welcomeSentence[lang]}`} line={`${HomePage.Contact_us[lang]}`} />
                    <div className='container' style={{ marginTop: "50px" }} dir={dir}>
                        <div className='contactForm'>
                            <div className='row handleRow mb-5'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='m-auto mx-5 formContactData shadow mb-5'>
                                        <form>
                                            <div className='m-auto'>
                                                <div>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setName(e.target.value)}
                                                        value={name}
                                                        placeholder={HomePage?.firstName[lang]}
                                                        className='w-100 form-control py-3'
                                                        style={{ textAlign: dir === "ltr" ? "left" : "right" }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    type="email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    placeholder={loginTrans.email[lang]}
                                                    className='w-100 form-control py-3'
                                                    style={{ textAlign: dir === "ltr" ? "left" : "right" }}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    value={phone}
                                                    placeholder={loginTrans.phone[lang]}
                                                    className='w-100 form-control py-3'
                                                    style={{ textAlign: dir === "ltr" ? "left" : "right" }}
                                                />
                                            </div>
                                            <div>
                                                <textarea
                                                    onChange={(e) => setComment(e.target.value)}
                                                    value={comment}
                                                    style={{ width: "100%", resize: "none", textAlign: dir === "ltr" ? "left" : "right" }}
                                                    rows={"5"}
                                                    className='form-control textarea'
                                                    placeholder={HomePage.message[lang]}
                                                >
                                                </textarea>
                                            </div>

                                            <div className='divBtn'>
                                                <button
                                                    className='btn sendNow'
                                                    onClick={(e) => { handelSubmit(e) }}
                                                >
                                                    {HomePage.send_message_now[lang]}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-6 col-sm-12 col-12' style={{ textAlign: dir === "ltr" ? 'left' : 'right' }}>
                                    <h4 className='w-100' style={{ textAlign: dir === "ltr" ? 'left' : 'right',  }}>
                                        {HomePage.form_contact[lang]}
                                    </h4>
                                    <p className='w-100 mt-4' style={{ textAlign: dir === "ltr" ? 'left' : 'right' }}>
                                        {HomePage.form_contact_info[lang]}
                                    </p>
                                    <div className='w-100 p-3' dir={dir}>
                                        <ul>
                                            <li><span><IoLocation className='ms-4 fs-4' /></span > 
                                                <i style={{color:theme === "light" ?"black":"white" }}>remotely service</i>
                                            </li>
                                            <li><span><MdLocalPhone className='ms-4 fs-4' /></span>
                                                <i style={{color:theme === "light" ?"black":"white" }}> +2 01287029988</i>
                                            </li>
                                            <li><span><IoLogoWhatsapp className='ms-4 fs-4' /></span >
                                                <i style={{color:theme === "light" ?"black":"white" }}> +2 01287029988</i>
                                            </li>
                                            <li><span><MdEmail className='ms-4 fs-4' /></span >
                                                <i style={{color:theme === "light" ?"black":"white" }}>info@osbash.com</i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SharedStatic hint="contact" />
                </main>
            </PageContainer>
        </div>
    );
};

export default ContactPage;
