import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FcFullTrash } from "react-icons/fc";
import { BiMessageDetail } from "react-icons/bi";
import { Delete_Student } from "../../../actions/students.js";
import { Current_Product } from "../../../actions/product.js";
import NewAccount from "../newAccount/newAccount.jsx";
import { getStudents, urlBase } from "../../../utils/API.js";
import NewRequest from "../../curriculums/requests/newRequest.js";
import { cities } from "../../../constants.js";
import { Inetialize_Users } from "../../../actions/teachers.js";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
function Students() {
  const [render, setRender] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const [students, setStudents] = useState([])
  const [govers, setGovers] = useState({})


  useEffect(() => {
    const fetchStudents = async () => {
      try {
        // const res = await getStudents()
        await dispatch(Inetialize_Users('student'))
        // console.log('stRes', res.data)
        // setStudents(res.data.users)
      }
      catch (err) {
        console.log(err.message)
      }
    }
    fetchStudents();
    let govsObj = {}
    const gov = cities.forEach(c => govsObj[c.code] = c)
    setGovers(govsObj)
    // Do something on component mount or when render state changes
  }, [render]);

  const stds = useSelector((state) => state.users);
  console.log("stdsssssssssssss", stds)

  const handleDeleteStudent = (studentId) => {
    dispatch(Delete_Student(studentId));
    setRender(!render);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim());
  };

  const filteredStudents = stds?.students.filter(
    (student) =>
      (student.username ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (student.email ?? "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (student.phone ?? "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container fluid className="font">
      <Row style={{ border: '1px solid', marginBottom: '20px' }}>
        <Col xs={12} md={8}>
          <NewAccount type="student" />
        </Col>
        <Col xs={12} md={8}>
          عدد الطلاب : {filteredStudents?.length}
        </Col>
        <Col xs={12} md={4}>
          <Form>
            <Form.Group controlId="searchTerm">
              <Form.Control
                type="text"
                placeholder="Search by name, email, or phone"
                value={searchTerm}
                onChange={handleSearch}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row style={{ border: '1px solid', padding: '50px 0 50px 0', boxShadow: ' 0 0 3 3 brown' }}>
        <Col xs={12} md={12}>
          {filteredStudents.map((student, index) => (
            <StudentList key={index} student={student} govers={govers} onDelete={() => handleDeleteStudent(student._id)} />
          ))}
        </Col>
      </Row>
    </Container>
  );
}

function StudentList({ student, onDelete, govers }) {
  const personalImage = student.personalImage ? student.personalImage : null;
  const imageLink = personalImage ? `${urlBase}/uploads/${personalImage}` : "";
  const dispatch = useDispatch();
  const { loggedUser } = useSelector(mapStateToProps);
  const navigate = useNavigate();

  const handleViewMessages = () => {
    dispatch(Current_Product(student));
    navigate(`/user/${loggedUser.user._id}/messages`);
  };
  // console.log('sssss',govers)
  return (
    <Row className="mb-3" style={{ width: '100%', border: '0px solid' }}>
      <Col xs={2} md={1}>
        <img src={imageLink} alt='' className="img-fluid" />
      </Col>
      <Col xs={8} md={5} style={{ fontSize: '12px' }}>
        <h5>الاسم: {student?.name}</h5>
        <p>اسم المستخم: {student?.username}</p>
        <p>البريد الالكترونى: {student?.email}</p>
        <p>رقم الهاتف:{student?.phone}</p>
        <p>تاريخ التسجيل: {student?.date.split("T")[0]}</p>
        <p>المحافظة: {govers[student?.city]?.name}</p>
        <h6>معلومات ولى الامر</h6>
        <p>الاسم:  {student?.parentId?.name}</p>
        <p>هاتف ولى الامر: {student?.parentId?.phone}</p>
        <p>وظيفة ولى الامر: {student?.parentId?.parentJob}</p>
      </Col>
      {/* <Col xs={4} md={2}>
        <Button style={{width:'50px'}} variant="info" onClick={handleViewMessages}>
        <BiMessageDetail size="20px" />
        </Button>
      </Col> */}
      <Col xs={3} md={3} sm={5}>
        <NewRequest
          userID={student._id}
          valid={true}
        />
      </Col>
      <Col xs={2} md={3}>
        <button style={{ width: '30px', height: '30px', padding: '0px', alignContent: 'center', textAlign: 'center' }} className="btn special color" onClick={onDelete}>
          <FcFullTrash size="22px" color="#0F2A51" />
        </button>
      </Col>
    </Row>
  );
}

function mapStateToProps({ users, loggedUser }) {
  const students = users.students;

  return {
    students,
    loggedUser,
  };
}

export default Students;
