
import axios from "axios"
import { urlBase } from "./API"

// 
// Create Zoom 
export const createSession = (data, userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/sessions?userId=${userId}`,
        withCredentials: true,
        data: data
    });
}



// Get All Session

export const getallSessionByLesson = (lesonId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/sessions?lesson=${lesonId}`,
        withCredentials: true,
    });
}

// Add HomeWork To Session
export const addHomeWorkToSession = (data, id, userId) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/sessions/${id}/homework-task?userId=${userId}`,
        data: data
    })
}