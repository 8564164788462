import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Class_Teacher } from '../../../actions/class';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
// import {getPack}
function AddTeacherToClass({ Class }) {
    const [showModal, setShowModal] = useState(false);

    const [selectedTeacherId, setSelectedTeacherId] = useState('');
    const [currentMaterials, setCurrentMaterials] = useState([])
    const [materialCode, setMaterialCode] = useState(null);

    const dispatch = useDispatch();

    const { teachers, materials, packages } = useSelector(({ users, materials, packages }) => {
        return {
            teachers: users.teachers,
            materials,
            packages
        }
    })
    const handleSelectChange = (e) => {
        setSelectedTeacherId(e.target.value);
    };


    // console.log('materials',Class)
    useEffect(() => {
        const getMaterials = () => {
            try {
                const [program] = packages.filter(p => p._id === Class.packID?._id);
                console.log("program",[program])
                const materialsObjs = program?.materials;
                const mats = []
                materialsObjs.forEach(m => {
                    const mt = materials.find(mm => mm.code === m.materialCode);
                    if (mt) mats.push(mt);
                })
                setCurrentMaterials(mats);
            }
            catch (err) {
                console.warn(err.message)
            }
        }
        getMaterials();
    }, [])
    // console.log('mats', currentMaterials)
    // console.log("class",Class)
    // console.log("package",packages)
    // console.log("materials",materials)
    const loggedUser = useSelector((state) => state.loggedUser);
    const handleSubmit = async () => {
        try {
            // Call your API here to add teacher to class
            // For demonstration purposes, assume successful response from the API
            const response = await dispatch(Add_Class_Teacher({ teacher: selectedTeacherId, id: Class._id, materialCode },loggedUser?.user?._id));

            if (response.data.case) {
                // Success notification
                toast.success(response.data.message, {
                    position: "top-center"
                });
            } else {
                // Error notification
                toast.error(response.data.message, {
                    position: "top-center"
                });
            }
        } catch (error) {
            // Error notification
            toast.error('An error occurred while adding the teacher', {
                position: "top-center"
            });
        } finally {
            // Close the modal
            setShowModal(false);
        }
    };

    return (
        <div>
            {/* Button to open modal */}
            <p onClick={() => setShowModal(true)}>اضافة معلم</p>

            {/* Modal for selecting teacher */}
            <Modal dir='rtl' show={showModal} onHide={() => setShowModal(false)} className="font" centered>
                <Modal.Header>
                    <Modal.Title>اضافة معلم للفصل</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="selectTeacher">
                        <Form.Label>اختر معلم:</Form.Label>
                        <Form.Control as="select" onChange={handleSelectChange}>
                            <option value="">اختر</option>

                            {teachers.map(teacher => (
                                <option key={teacher._id} value={teacher._id}>{teacher.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="selectMaterial">
                        <Form.Label>اختر المادة </Form.Label>
                        <Form.Control as="select" onChange={(event) => setMaterialCode(event.target.value)}>
                            <option value="">اختر</option>
                            {/* Render the list of teachers */}
                            {/* Replace teachers with your actual list of teachers */}
                            {currentMaterials.map(m => (
                                <option key={m.code} value={m.code}>{m.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn buttonsGroub' onClick={() => setShowModal(false)}>Close</button>
                    <button className='btn special color' onClick={handleSubmit}>حفظ</button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default AddTeacherToClass;
