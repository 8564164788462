import { createComment, createPost, getAllPosts } from "../utils/community";
export const CREATE_POST = 'CREATE_POST';
const create_post= (post)=>{
    return {
        type:CREATE_POST,
        post
    }
}

export const Create_Post = (data)=>{
    return dispatch => createPost(data).then(res=>{
        if(res.data.case){
            dispatch(create_post(res.data.post))
        }
        return res
    })
}

export const INETIALIZE_POSTS = 'INETIALIZE_POSTS';
const inetialize_posts = (posts)=>{
    return {
        type:INETIALIZE_POSTS,
        posts
    }
}
export const Inetialize_Posts = ()=>{
    return dispatch => getAllPosts().then(res=>{
        if(res.data.case){
            dispatch(inetialize_posts(res.data.posts))
        }
        return res
    })
}

export const CREATE_COMMENT = 'CREATE_COMMENT';
const create_comment = (comment)=>{
    return{
        type:CREATE_COMMENT,
        comment
    }
}

export const Create_Comment = (postId,data)=>{
    return dispatch=>createComment(postId,data).then(res=>{
        if(res.data.case){
            dispatch(create_comment(res.data.comment))
        }
        return res
    })
}