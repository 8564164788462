import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Delete_Level } from '../../../actions/curriculums/levels';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from 'react-redux';
const ModalDelete = ({id,setMessage}) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = ()=>{
        try{
            dispatch(Delete_Level(id)).then(res=>{
                setMessage(res.data.message)
            })
            handleClose();
        }catch(e){
            console.warn(e);
            setMessage(e.message)
            handleClose()
        }
    }

    return (
        <div>
            <RiDeleteBin6Line onClick={handleShow} size='25px' color='#0F2A51'/>

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header >
                    <Modal.Title>Delete Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body> Did You Want To Delete Modal ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={()=>handleDelete()}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDelete
