import React, { useEffect, useState } from 'react'
import { Create_Product,Remove_product } from '../../../actions/store'
import { useDispatch, useSelector } from 'react-redux'
import ProductsList from './productsList';
import AddCategory from './addCategory';
import Categories from './categories';
import AddProduct from './addProduct';
function Products() {
  const[selectedCategory,setSelectedCategory] = useState(null);
  // const dispatch = useDispatch({products,categories});
  const {products,categories} = useSelector(({store})=>{
    return {
     products:store.products,
     categories:store.categories,
    }
 });



  return (
    <div style={{padding:'50px 0 50px 0'}}>
      <AddCategory/>
      <h2 style={{padding:'50px 0 10px 0'}}>الفئات والأصناف</h2>
      <Categories setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory}/>

      <AddProduct/>
      <h2 style={{padding:'10px 0 0px 0'}}>المنتجات</h2>
      <ProductsList products={products} selectedCategory={selectedCategory} categories={categories}/>
    </div>
  )
}

export default Products