import React, { useState } from "react";
import Modal from "../modal/modal";
import Contain from "../styled-components/contain.jsx";
import Col from "../styled-components/col.jsx";
import { GrView } from "react-icons/gr";
// import {FaReply} from 'react-icons/fa';
import './messages.css'
import SendMessage from './sendMessage'
function Message({ message }) {
  const [open,setOpen] = useState(false);
  return (
    <>
    <span className='view-message'>
      <i>
      <GrView
        color ="red"
        onClick={() => {
          setOpen(true);
        }}
      />
      </i>
      </span>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        width="70vw"
        height="80vh"
      >
        <Contain>
          <Col width="100%" dir="column">
            <Col width="90%" justify='space-between' border='1px' perfix='message-header'>
              <span>Date: {message.date.split("T")[0]}</span>
              <span className='reply'>< SendMessage to={message.from?message.from:message.to}/></span>
              <span>Time: {message.date.split("T")[1]}</span>
            </Col>
            <h3 className="message-subject">Subject: {message.title}</h3>
            <p className="message-content">{message.content}</p>
          </Col>
        </Contain>
      </Modal>
    </>
  );
}

export default Message;
