import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { HomePage } from '../../../utils/Translation/HomePage';
import { ThemeContext } from '../../../ThemeProvider';

const SharedStatic = ({ hint }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext)
    return (
        <div className='font sharedStatic'>
            <div className='container' style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                {
                    hint === "support" ? (<div>
                        <p>
                            <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/about-us">{HomePage.sharedTwo[lang]}</Link>
                        </p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/faq">{HomePage.commonQueuestions[lang]}</Link></p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }
                {
                    hint === "common" ? (<div>
                        <p>  <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/about-us">{HomePage.sharedTwo[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/support">{HomePage.sharedThree[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }
                {
                    hint === "contact" ? (<div>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/about-us">{HomePage.sharedTwo[lang]}</Link></p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/support">{HomePage.sharedThree[lang]}</Link></p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }
                {
                    hint === "about" ? (<div>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/faq">{HomePage.commonQueuestions[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/support">{HomePage.sharedThree[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light" ? "black" : "white" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }

            </div>
        </div>
    )
}

export default SharedStatic
