import React, { useState } from 'react';
import ModalAddMaterial from '../levels/ModalAddMaterial';
import ModalDelete from '../levels/ModalDelete';
import ExpandedContent from '../levels/ExpandedContent';
import LevelRow from './levelRow';

const ExpandedLevels = ({ levels, materials }) => {
  const [message, setMessage] = useState('');
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [selectedLevelId, setSelectedLevelId] = useState(null);

  const handleShowAddMaterialModal = (levelId) => {
    setSelectedLevelId(levelId);
    setShowAddMaterialModal(true);
  };

  const handleCloseAddMaterialModal = () => {
    setSelectedLevelId(null);
    setShowAddMaterialModal(false);
  };

  return (
    <div>
      <p className='font fs-6'>المستويات:</p>
      <ul>
        {levels.length ? levels.map((item, i) => (
          <>
            <LevelRow key={i} item={item} materials={materials} />
          </>
        )) : <li className='font'>
          لا توجد مستويات فى هذة الفئة التعليمية بعد
        </li>}
      </ul>
    </div>
  );
};

export default ExpandedLevels;
