// import React, { useState } from 'react'
// import PageContainer from '../layout/PagecContainer';
// import { useSelector } from 'react-redux';
// import { resetPass } from '../../utils/forgetPassApi';
// import { useParams } from 'react-router-dom';
// import { loginTrans } from '../../utils/Translation/loginAndRegister';
// import { FaKey } from "react-icons/fa";
// import { InfoMsg, success } from '../shared/modules/Notifications';
// const ResetPass = () => {
//     const [pass, setPass] = useState("");
//     const [rePass, setRePass] = useState("");
//     const [alertVisible, setAlertVisible] = useState(false);
//     const [alertMessage, setAlertMessage] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [message, setMessage] = useState("");
//     const params = useParams();


//     const hanldeResetPass = async (e) => {
//         e.preventDefault();
//         if (pass !== rePass) {
//             setAlertMessage('كلمة السر غير متطابقة');
//             setAlertVisible(true);
//             return;
//         }
//         try {
//             const res = await resetPass({
//                 email: params?.email,
//                 otp: params?.otp,
//                 password: pass,
//             });
//             // Handle success response
//             console.log(res)

//             if(res?.data?.case){
//                 success(res?.data?.message)
//             }else{
//                 InfoMsg(res?.data?.message)
//             }

//         } catch (e) {
//             // Handle error
//         }
//     };
//     const { lang } = useSelector(({ language }) => {
//         return { lang: language.lang, dir: language.dir };
//     });



//     return (
//         <PageContainer>
//             <div className="loginpage font">
//                 <div className="container spacePage">
//                     {!loading ? (
//                         <div className="loginForm py-4">
//                             {message && (
//                                 <div className={`mt-3 text-center alert  alert-success`} role="alert">
//                                     {message}
//                                 </div>
//                             )}

//                             <div className="formStyle w-50 m-auto">
//                                 <div className="">
//                                     <div className="formPart">
//                                         <form className="w-100" action="">
//                                             {alertVisible && (
//                                                 <div className="alert alert-danger" role="alert">
//                                                     {alertMessage}
//                                                 </div>
//                                             )}
//                                             <div className="font">
//                                                 <p className="color"> OLP مرحبا بكم في</p>
//                                                 <p>من فضلك ادخل الايميل</p>
//                                             </div>

//                                             <div className="mb-3 mt-4 positionInput">
//                                                 <input
//                                                     type="password"
//                                                     name="pass"
//                                                     className="form-control"
//                                                     placeholder={loginTrans.pass_new[lang]}
//                                                     id="loginId1"
//                                                     onChange={(event) => {
//                                                         setPass(event.target.value);
//                                                     }}
//                                                     value={pass}
//                                                 />
//                                                 <div className="email"><FaKey /></div>
//                                             </div>
//                                             <div className="mb-3 mt-4 positionInput">
//                                                 <input
//                                                     type="password"
//                                                     name="pass"
//                                                     className="form-control"
//                                                     placeholder={loginTrans.pass_confirm[lang]}
//                                                     id="loginId1"
//                                                     onChange={(event) => {
//                                                         setRePass(event.target.value);
//                                                     }}
//                                                     value={rePass}
//                                                 />
//                                                 <div className="email"><FaKey /></div>
//                                             </div>

//                                             <div className="text-center mt-5">
//                                                 <input
//                                                     type="submit"
//                                                     className=" w-100 font"
//                                                     id="loginId3"
//                                                     onClick={(e)=>hanldeResetPass(e)}
//                                                     value={loginTrans.completed[lang]}
//                                                 />
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     ) : (
//                         <div className="loaderIcon">
//                             <span className="loader"></span>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </PageContainer>
//     )
// }

// export default ResetPass
import React, { useState } from 'react';
import PageContainer from '../layout/PagecContainer';
import { useSelector } from 'react-redux';
import { resetPass } from '../../utils/forgetPassApi';
import { useParams, Link } from 'react-router-dom';
import { loginTrans } from '../../utils/Translation/loginAndRegister';
import { FaKey } from "react-icons/fa";
import { InfoMsg, success } from '../shared/modules/Notifications';

const ResetPass = () => {
    const [pass, setPass] = useState("");
    const [rePass, setRePass] = useState("");
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [formVisible, setFormVisible] = useState(true);
    const params = useParams();

    const hanldeResetPass = async (e) => {
        e.preventDefault();
        if (pass !== rePass) {
            setAlertMessage('كلمة السر غير متطابقة');
            setAlertVisible(true);
            return;
        }
        setLoading(true);
        try {
            const res = await resetPass({
                email: params?.email,
                otp: params?.otp,
                password: pass,
            });
            // Handle success response
            if (res?.data?.case) {
                success(res?.data?.message);
                setFormVisible(false);
            } else {
                InfoMsg(res?.data?.message);
            }
        } catch (e) {
            // Handle error
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const { lang } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <PageContainer>
            <div className="loginpage font">
                <div className="container spacePage">
                    {!loading ? (
                        formVisible ? (
                            <div className="loginForm py-4">
                                {alertVisible && (
                                    <div className="alert alert-danger" role="alert">
                                        {alertMessage}
                                    </div>
                                )}
                                <div className="formStyle w-50 m-auto">
                                    <div className="">
                                        <div className="formPart">
                                            <form className="w-100" action="">
                                                <div className="font">
                                                    <p className="color"> OLP مرحبا بكم في</p>
                                                    <p>من فضلك ادخل الايميل</p>
                                                </div>

                                                <div className="mb-3 mt-4 positionInput">
                                                    <input
                                                        type="password"
                                                        name="pass"
                                                        className="form-control"
                                                        placeholder={loginTrans.pass_new[lang]}
                                                        id="loginId1"
                                                        onChange={(event) => {
                                                            setPass(event.target.value);
                                                        }}
                                                        value={pass}
                                                    />
                                                    <div className="email"><FaKey /></div>
                                                </div>
                                                <div className="mb-3 mt-4 positionInput">
                                                    <input
                                                        type="password"
                                                        name="pass"
                                                        className="form-control"
                                                        placeholder={loginTrans.pass_confirm[lang]}
                                                        id="loginId1"
                                                        onChange={(event) => {
                                                            setRePass(event.target.value);
                                                        }}
                                                        value={rePass}
                                                    />
                                                    <div className="email"><FaKey /></div>
                                                </div>

                                                <div className="text-center mt-5">
                                                    <input
                                                        type="submit"
                                                        className="w-100 font"
                                                        id="loginId3"
                                                        onClick={(e) => hanldeResetPass(e)}
                                                        value={loginTrans.completed[lang]}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center mt-5" style={{height:"50vh"}}>
                                <p>{success}</p>
                                <Link to="/login" className="btn btn-primary">
                                    {loginTrans.login[lang]}
                                </Link>
                            </div>
                        )
                    ) : (
                        <div className="loaderIcon">
                            <span className="loader"></span>
                        </div>
                    )}
                </div>
            </div>
        </PageContainer>
    );
};

export default ResetPass;
