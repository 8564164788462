import { ADD_TO_CART, GET_ALL_CART_ITEMS, RESET_CART } from "../actions/cart.actions";



export const cart=(state=[],action)=>{
    switch (action.type) {
        case ADD_TO_CART:
        return state=[...state,action.payload];
                
        case GET_ALL_CART_ITEMS:
            return state;
        
        case RESET_CART:
            return state=[];
            
    
        default:
            return state;
    }
}