import React from "react";
import { NavLink } from "react-router-dom";
import './mobileNave.css';
import { Log_Out } from "../../actions/login&register";
import { useDispatch } from "react-redux";

function MobileNave({ taps }) {
  const dispatch = useDispatch()
  return (
    <div className="user-mobile-nave">
      <nav>
        <ul>
          {taps.map((t, i) => (
            <li key={i}>
              <NavLink
                //  className='user-mobile-tap' 
                className={({ isActive }) =>
                  isActive ? " side-link-active user-mobile-tap" : "user-mobile-tap"
                }
                id={t.name.split(' ').join('')} to={t.path}>
                {t.icon}<br />

                <span id={'mobnav' + i}>
                  {t.name}
                </span>
              </NavLink>
            </li>
          ))}
          <li>
            <button
              onClick={() => { dispatch(Log_Out()) }}
              className='user-mobile-tap' id='log-out'>خروج</button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default MobileNave;
