

// export default Subject
import React, { useEffect, useState } from 'react';
import EmptyComponent from './EmptyComponent';
import "../../../styles/dashboard.css";
import SubscripeMaterials from './SubscripeMaterials';
import { useDispatch, useSelector } from 'react-redux';
import { programs_per_user } from '../../../actions/login&register';
import Spinner from 'react-bootstrap/Spinner';
import SchedualClass from './SchedualClass';

const Subject = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    const [loading, setLoading] = useState(true); // Add loading state

    const fetchPrograms = async () => {
        setLoading(true); // Set loading to true before fetching
        if (loggedUser?.user?.role === "student") {
            await dispatch(programs_per_user({
                userID: loggedUser?.user?._id
            }));
        } else if (loggedUser?.user?.role === "parent") {
            await dispatch(programs_per_user({
                userID: loggedUser?.user?.childId
            }));
        }
        setLoading(false); // Set loading to false after fetching
    };

    useEffect(() => {
        fetchPrograms();
    }, []);

    const [activeTab, setActiveTab] = useState("content"); // New state for managing active tab

    return (
        <div className='font'>
            <div className='sharedBackground'>
                <p>برامجي الدراسيه</p>
            </div>

            <div className="tabs-container w-75 m-auto">
                <button className={`tab-button ${activeTab === "content" ? "active" : ""} btn`}
                    style={{ width: "50%", background: "#097514", color: "white", padding: "15px", borderRadius: "10px" }}
                    onClick={() => setActiveTab("content")}>
                    المحتوي الدراسي
                </button>
                <button className={`tab-button ${activeTab === "sessions" ? "active" : ""} btn`}
                    style={{ width: "50%", background: "#EB3C3F", color: "white", padding: "15px", borderRadius: "10px" }}
                    onClick={() => setActiveTab("sessions")}>
                    الحصص القادمه
                </button>
            </div>

            {activeTab === "content" ? (
                loading ? (
                    <div className="spinner-container d-flex justify-content-center align-items-center">
                        <span className='loader'></span>
                    </div>
                ) : loggedUser?.programs && loggedUser?.programs?.length > 0 ? (
                    <div className='container'>
                        <SubscripeMaterials programs={loggedUser?.programs} />
                    </div>
                ) : (
                    <div className='emptyComponent'>
                        <EmptyComponent showFooter={true} title={"انت غير مشترك في برامج دراسيه بعد"} />
                    </div>
                )
            ) : (
                <div className='container'>
                    {/* <SchedualClass /> */}
                    <SchedualClass />
                </div>
            )}

            {/* {loading ? (
                <div className="spinner-container d-flex justify-content-center align-items-center">
                    <span className='loader'></span>
                </div>
            ) : (
                loggedUser?.programs && loggedUser?.programs?.length > 0 ? (
                    <div className='container'>
                        <SubscripeMaterials programs={loggedUser?.programs} />
                    </div>
                ) : (
                    <div className='emptyComponent'>
                        <EmptyComponent showFooter={true} title={"انت غير مشترك في برامج دراسيه بعد"} />
                    </div>
                )
            )} */}
        </div>
    );
};

export default Subject;
