import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaUsers } from "react-icons/fa";
import { Modal, Button, Table } from 'react-bootstrap';
import SendMessage from '../../message/sendMessage';
import { getUsersByIDS } from '../../../utils/API';
import SendGroupMessage from './sendGroupMessage';

function ClassStudents({ Class }) {
  const [show, setShow] = useState(false);
  
  const [classStudents, setClassStudents] = useState([]);
  const [students ,setStudents] = useState([]);

  useEffect(() => {
    const getStudentsAPI = async()=>{
      try{
      const IDS = Class.studentsIDS;
  
         const res =await getUsersByIDS({IDS});
         setStudents( res.data?.users);
         setClassStudents(res?.data?.users);
      }
      catch(err){
        console.warn(err.message)
      }
    }
    getStudentsAPI()    
    
  }, [Class]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <FaUsers onClick={handleShow} size={20} color="green" />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table style={{fontSize:'12px !important'}} responsive striped bordered hover>
            <thead >
            <tr>
      <th style={{ fontSize: '14px' }}>#</th>
      <th style={{ fontSize: '14px' }}>الاسم</th>
      <th style={{ fontSize: '14px' }}>البريد الالكترونى</th>
      <th style={{ fontSize: '14px' }}>ارسال رسالة</th>
    </tr>
  </thead>
  <tbody>
    {classStudents.map((student, index) => (
      <tr key={student._id}>
        <td style={{ fontSize: '12px' }}>{index + 1}</td>
        <td style={{ fontSize: '12px' }}>{student.name ? student.name : student.username}</td>
        <td style={{ fontSize: '12px' }}>{student.email}</td>
        <td style={{ fontSize: '12px' }}><SendGroupMessage acceptorsIDS={[student._id]} /></td>
      </tr>
              ))}
            </tbody>    
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClassStudents;
