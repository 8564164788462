import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import AddStudents from './addStudents'

function AddStudentsModal({ Class,studentsToAdd }) {
    
    const [showModal, setShowModal] = useState(false);
    // Function to handle opening modal
    const openModal = () => {
        setShowModal(true);
    }

    // Function to handle closing modal
    const closeModal = () => {
        setShowModal(false);
    }
  return (
    <>
            <p onClick={openModal}>اضافة طلاب</p>
    <Modal show={showModal} onHide={closeModal}>

        <AddStudents Class={Class} studentsToAdd={studentsToAdd}/>
    </Modal>
    </>
    )
}

export default AddStudentsModal