export const ADD_SOCKET = 'ADD_SOCKET';
const addSocket = (socketRef) => {
    return {
        type: ADD_SOCKET,
        socketRef
    }
}
export const Add_Socket = (data) => {
    return dispatch => dispatch(addSocket(data));
}
export const ROOM_ID = "ROOM_ID";

const save_room_id = (id) => {
    return {
        type: ROOM_ID,
        id
    }
}

export const Save_Room_Id = (id) => {
    return (dispatch) => {
        dispatch(save_room_id(id))
    }
}

export const MEETING_DETAILS = 'MEETING_DETAILS';
const meetingDetails = (details) => {
    return {
        type: MEETING_DETAILS,
        details
    }
}
export const Meeting_Details = (details) => {
    return (dispatch) => dispatch(meetingDetails(details))
}