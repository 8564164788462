import React from 'react'
import { useParams } from 'react-router-dom';
import male from "../../assets/male.gif"
import female from "../../assets/female.gif"
import lafta from "../../assets/branchselect.png"
import classroomselect from "../../assets/classroomselect.png"
import selectSubject from "../../assets/selectSubject.png"
import saf from "../../assets/saf.png"
import courseSel from "../../assets/courseSel.png"
import allteachers from "../../assets/allTeachers.png"
const SharedImage = ({ type }) => {
    const params = useParams();
    return (
        <div>
            <div className='imageLafta'>
                <div>
                    {
                        params?.gender === "male" ? (<img src={male} alt="imageMaleorFemale" className='w-100' />) : (<img src={female} alt="imageMaleorFemale" className='w-100' />)
                    }

                    <div className='lafta'>
                        {
                            type === "branch" ? <img src={lafta} alt="laftaBranch" /> : null
                        }
                        {
                            type === "level" ? <img src={classroomselect} alt="laftaBranch" /> : null
                        }
                        {
                            type === "room" ? <img src={saf} alt="laftaBranch" /> : null
                        }
                        {
                            type === "material" ? <img src={selectSubject} alt="laftaBranch" /> : null
                        }
                        {
                            type === "course" ? <img src={courseSel} alt="laftaBranch" /> : null
                        }
                        {
                            type === "all-material" ? <img src={allteachers} alt="laftaBranch" /> : null
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SharedImage
