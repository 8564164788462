
export const STORE_ALL_USER_MESSAGES="STORE_ALL_USER_MESSAGES";

export const storeUserMessages=(messages)=>{
    return{
        type:STORE_ALL_USER_MESSAGES,
        payload:messages
    }
}

export const GET_ALL_USER_STORED_MESSAGES="GET_ALL_STORED_MESSAGES";

export const getAllUserStoredMessages=()=>{
    return{
        type:GET_ALL_USER_STORED_MESSAGES
    }
}