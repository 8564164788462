export const New_Notification = "New_Notification"
export const ADD_TOAST_FUNC = "ADD_TOAST_FUNC";
const newNotifications = ()=>{
    return {
        type:New_Notification,

    }
}
export const AddToastFunc = (toast)=>{
   return{type:ADD_TOAST_FUNC,toast}
}