import axios from 'axios';
import { urlBase } from './API';

// create get All Media
export const getMedia = (userId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/medias/me?userId=${userId}`,
    })
}


// Delete Media
export const deleteMediadata = (id,userId) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/medias/${id}/me?userId=${userId}`,
    })
}