import { SET_LANG } from "../actions/language";

export const language = (state={dir:'rtl',lang:'AR'},action)=>{
    switch(action.type){
        case SET_LANG:
            return state= {
                lang:action.lang,
                dir:action.lang ==='AR'?'rtl':'ltr'
            }
        default: return state
    }
}