import logo from "./logo.svg";
import "./App.css";
import 'core-js/stable';
import 'regenerator-runtime/runtime';


import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//  import Header from "./components/publicPges/shareCommponents/header/Header";
//  import Footer from "./components/publicPges/shareCommponents/footer/Footer";
import RegisterPage from "./components/register/registerPage/RegisterPage";
import LoginPage from "./components/register/loginPage/LoginPage";

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { intializeLogIn, programs_per_user } from "./actions/login&register";
// import Dashboard from "./components/dashBoard/dashboard";



// admin routes
import GetText from './components-old/translation/getText.jsx';
import UserDashboard from "./components-old/userDashboard/userDashboard";
import Appointments from "./components-old/Admin/appointments.jsx";
import AdminProfile from "./components-old/userDashboard/profile";
import MessagesAdmin from './components-old/Admin/messages';
import NewProduct from "./components-old/Admin/newProduc";
import UploadImage from "./components-old/Admin/upload";
import Products from "./components-old/Admin/products";
import SubCategory from "./components-old/Admin/subCategory.";
import TeachersList from "./components-old/Admin/teachers";
import Students from "./components-old/Admin/students/students.jsx";
import Accounts from "./components-old/Admin/accounts/accounts.jsx";
import DisscountList from './components-old/Admin/disscount/disscountList'
import NewDisscountCode from "./components-old/Admin/disscount/newCode.jsx";
import EditProduct from "./components-old/Admin/editProduct.jsx";
import SendMessage from "./components-old/hiWhats/sendMessage.jsx";
import LevelsMainPage from "./components-old/curriculums/levels/levelsMainPage.js";
import Materials from "./components-old/curriculums/materials/materials";
import Packages from "./components-old/curriculums/packages/packages.js";
import MultimediaViewer from "./components-old/curriculums/lessons/view.js";
import AddTest from "./components-old/curriculums/tests/addTest.js";
import Classes from "./components-old/Admin/classControl/classes"
import BuyRequests from "./components-old/Admin/requests/requests.js";
import StoreControle from "./components-old/Admin/store/store.js";
import SuperVisorAccounts from './components-old/Admin/supervisor/superVisorAccounts.js'
import AllBranchs from "./components-old/curriculums/branchs/AllBranchs.js";
import Parents from "./components-old/Admin/parents.js/parents.js";
import Chat from "./components-old/chat.jsx";

import { Get_All_Packages } from "./actions/packages.js";


import { getUsersByIDS, urlBase } from "./utils/API.js";
import { Inetialize_Branchs } from "./actions/curriculums/branchs.js";
import { Inetialize_Levels } from "./actions/curriculums/levels.js";
import { sendNewMessage } from "./utils/massenger.js";
import { Get_All_My_Messages } from "./actions/massenger.js";
import { AddToastFunc } from "./actions/notification.js";
import { Get_Store_Products, Inetialize_Categories } from "./actions/store.js";
import InetializeRoom from "./components/meetingSystem/inetializeRoom.js";
import HomePage from "./Pages/Home/HomePage.js";
import ContactPage from "./components/static/ContactPage.js";
import CommponQue from "./components/static/CommponQue.js";
import TechnicalSupport from "./components/static/TechnicalSupport.js";
import About from "./components/static/About.js";
import ProgramsPage from "./Pages/Programs/ProgramsPage.js";
import DashboardPage from "./Pages/Dashboard/DashboardPage.js";
import Profile from "./components/Dashboard/Account/Profile.js";
import Subject from "./components/Dashboard/Material/Subject.js";
import DetailsSubscription from "./components/Dashboard/Subscriptions/DetailsSubscription.js";
import Message from "./components/Dashboard/Message/Message.js";
import Results from "./components/Dashboard/Results/Results.js";
import ProgramDetails from "./Pages/Details/ProgramDetails.js";
import CourseBoard from "./components/Dashboard/studyArea/educationBoard.js";
import CommnityMain from "./components/commnity/commnityMain.js"
import SinglePostPage from "./components/commnity/SinglePostPage.js"
import PageContainer from "./components/layout/PagecContainer.jsx";
import ForgetPass from "./components/forget/ForgetPass.js";
import ResetPass from "./components/forget/ResetPass.js";
import ThanksPage from "./components/thanks/ThanksPage.js";
import Society from "./components/teacher/Society.js";
import TeacherClassesComponent from "./components/TeacherPages/classes/TeacherClassesComponent.js";
import StudentsTableMaterialComponent from "./components/TeacherPages/classes/StudentsTableMaterialComponent.js";
import TeacherCorrectionComponent from "./components/curriculums/tests/TeacherCorrectionComponent.js";
import AwarnesPage from "./components/ParentPages/AwarnesPage.js";
import ProgramsTeacher from "./components/TeacherPages/ProgramsTeacher/ProgramsTeacher.js";
import CreatePackage from "./components-old/curriculums/packages/createPackage.js";
import TeacherPagePrograms from "./Pages/TeacherPage/TeacherPagePrograms.js";
import DetailsTeacherPrograms from "./Pages/TeacherPage/DetailsTeacherPrograms.js";
import StdResults from "./components/StudentPages/StudentTabPages/StdResults/StdResults.jsx";
import { Inetialize_Users } from "./actions/teachers.js";
import MyStudents from "./components/TeacherPages/MyStudents.js";
import Settings from "./components/TeacherPages/Settings.js";
import Subscription from "./components/TeacherPages/Subscription.js";
import Services from "./components-old/Admin/Services/Service.js";
import RegisterTeacher from "./components/register/registerTeacher/RegisterTeacher.js";
import TeacherServicePage from "./Pages/TeacherServices/TeacherServicePage.js";
import ServicesPages from "./Pages/TeacherServices/ServicesPages.js";
import ThanksData from "./components/thanks/ThanksData.js";
import Emptysubscripe from "./components/TeacherServices/modules/Emptysubscripe.js";
import SubscriptionsTeachers from "./components/TeacherServices/modules/SubscriptionsTeachers.js";
import SelectSubject from "./components/TeacherServices/modules/SelectSubject.js";
import { Get_All_Packages_Subscriptions } from "./actions/packagesubscriptions.js";
import Zoom from "./components-old/Admin/Zoom/Zoom.js";
import { Get_All_Main_Materials } from "./actions/mainMaterial.js";
import ReversationSystem from "./Pages/ReservSystem/ReversationSystem.js";
import Branchselect from "./components/ReservSystem/Branchselect.js";
import Chooselevel from "./components/ReservSystem/Chooselevel.js";
import Selectclassroom from "./components/ReservSystem/Selectclassroom.js";
import Subjectselect from "./components/ReservSystem/Subjectselect.js";
// import Teacherselect from "./components/ReservSystem/Teacherselect.js";
import CourseDetails from "./components/ReservSystem/CourseDetails.js";
import Pay from "./components/ReservSystem/Pay.js";
import Thanksrevers from "./components/ReservSystem/Thanksrevers.js";
import ReservationSystemWrapper from "./Pages/ReservSystem/ReservationSystemWrapper.js";
import EductionType from "./components-old/Admin/Eductions/EductionType.js";
import SidNav from "./components-old/Admin/sideNav.jsx";
import Media from "./components-old/Admin/media/Media.js";
import Classselect from "./components/ReservSystem/Teacherselect.js";
import Alltrans from "./components-old/Admin/Transactions/Alltrans.js";
import TransactionMe from "./components/Dashboard/Trans/TransactionMe.js";




function App() {



  const [render, setRender] = useState('')
  const dispatch = useDispatch();
  const { user, dir, branchs, levels } = useSelector(({ loggedUser, language, branchs, levels }) => {
    return { user: loggedUser, dir: language.dir, branchs, levels };
  });

  //
  // getAwarnessContent({}).then(res=>console.log(res.data))
  // createAwarnessContent({_id:'6656538925b13e2aa85e710e',title:'test',content:'Test',video:{name:'vvv'}}).then(res=>console.log(res))
  //
  const toastfy = () => {
    setRender(' ')
    return toast
  }

  // --------- Test Area -------
  const testFunction = async () => {
    try {
      const res = await getUsersByIDS({ IDS: ["65eaa4719d5091014884e131"] })
      console.log('test', res.data)
    }
    catch (err) {
      console.warn(err)
    }
  }

  //-----------------------------------

  useEffect(() => {

    dispatch(intializeLogIn())
    dispatch(AddToastFunc(toastfy))
    dispatch(Get_All_Packages())
    dispatch(Inetialize_Branchs());
    dispatch(Inetialize_Categories());
    // dispatch(Get_Store_Products())
    dispatch(Inetialize_Users('teacher'))
    // console.log('Teachers.....................',getTeacherClasses({id:'6600b81ce198270324db2837'}))
    dispatch(Inetialize_Levels());
    dispatch(Get_All_Packages_Subscriptions())
    dispatch(Get_All_My_Messages(user?.user?._id))
    dispatch(Get_All_Main_Materials())
  }, [])



  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="*" element={<HomePage />}>
          </Route>



          {/* ---------------public routes --------------------------------*/}

          <Route path="/" element={<HomePage />} />

          {/* Reservation system */}



          <Route path="/reservation-system" element={<ReservationSystemWrapper />}>
            <Route index element={<ReversationSystem />} />
            <Route path=":gender" element={<Branchselect />} />
            <Route path=":gender/:stage" element={<Chooselevel />} />
            <Route path=":gender/:stage/:classroom" element={<Selectclassroom />} />
            <Route path=":gender/:stage/:classroom/:subject" element={<Subjectselect />} />
            <Route path=":gender/:stage/:classroom/:subject/:teacher" element={<Classselect />} />
            <Route path=":gender/:stage/:classroom/:subject/:teacher/:idteacher" element={<CourseDetails />} />
            <Route path=":gender/:stage/:classroom/:subject/:teacher/:idteacher/pay" element={<Pay />} />
            <Route path=":gender/:stage/:classroom/:subject/:teacher/:idteacher/pay/thanks" element={<Thanksrevers />} />
          </Route>


          {/* Reservation system */}

          {!user.auth && (
            <>
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/register-teacher" element={<RegisterTeacher />} />
              <Route path="/login" element={<LoginPage />} />
            </>
          )}
          <Route path="/forget-pass" element={<ForgetPass />} />
          <Route path="/reset-pass/:email/:otp" element={<ResetPass />} />
          <Route path="bardiat-community/:branchId_levelId_classId" element={
            <PageContainer>
              <CommnityMain branchs={branchs} levels={levels} />
            </PageContainer>
          } />
          <Route path="bardiat-community" element={
            <PageContainer>
              <CommnityMain branchs={branchs} levels={levels} />
            </PageContainer>
          } />

          <Route path="post/:id" element={
            <PageContainer>
              <SinglePostPage />
            </PageContainer>
          } />

          <Route path="/thanks" element={<ThanksPage />} />
          <Route path="/thanks-package" element={<ThanksData />} />
          <Route path="/empty-subscripe" element={<Emptysubscripe />} />





          <Route path="/subjects" element={<ProgramsPage />} />

          {/* Static Pages */}
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/faq" element={<CommponQue />} />
          <Route path="/support" element={<TechnicalSupport />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/teacher-services" element={<TeacherServicePage />} />
          <Route path="/teachers" element={<TeacherPagePrograms />} />
          <Route path="/service-type/:type" element={<ServicesPages />} />

          <Route path="/teachers/:id/:name" element={<DetailsTeacherPrograms />} />
          <Route path="/classroom" element={<SelectSubject />} />


          {/* Programs Details Page */}

          <Route path="/details/:name/:id" element={<ProgramDetails />} />
          {user?.user?.role === 'teacher' && <>
            <Route path="subscriptions-teacher" element={<SubscriptionsTeachers />} />
            <Route path="/classroom" element={<SelectSubject />} />
          </>}






          {/* ---------------------------------------------------------------------- */}

          {user && user.auth && (
            <>
              <Route path="meeting-room/:username/:id/:roomId" element={<InetializeRoom />} />
              <Route path="/dashboard/:name/:id" element={<DashboardPage />}>
                <>
                  {/* -----------------------COMMON ROUTES ----------------------- */}
                  {(user?.user?.role === 'student' || user?.user?.role === 'parent' || user?.user?.role === 'teacher' || user?.user?.role === 'supervisor') && (
                    <>
                      {/* <Route path="" element={<SidNav />} /> */}
                      <Route path="profile" element={<Profile />} />
                      <Route path="society" element={<Society />} />

                      {/* {user?.user?.role === "student" && (
                        <>
                          <Route path="subjects" element={<Subject />} />
                          <Route path="subscriptions" element={<DetailsSubscription />} />
                          <Route path="messages" element={<Message />} />
                          <Route path="results" element={<Results />} />
                        </>
                      )} */}
                    </>

                  )}
                  {user.user.role === 'parent' &&
                    <>
                      <Route path="awareness" element={<AwarnesPage />} />
                    </>
                  }

                  {(user.user.role === 'student' || user.user.role === 'parent') && (
                    <>
                      <>
                        <Route path="subjects" element={<Subject />} />
                        <Route path="subscriptions" element={<DetailsSubscription />} />
                        <Route path="messages" element={<Message />} />
                        {/* <Route path="results" element={<Results />} /> */}
                        <Route path="results" element={<StdResults />} />
                        <Route path="results/teacherCorrection" element={<TeacherCorrectionComponent />} />
                      </>
                    </>
                  )}

                  {(user.user.role === 'student') && (
                    <>
                      <>
                        <Route path="my-transactions" element={<TransactionMe />} />
                      </>
                    </>
                  )}



                  {
                    (user?.user?.role === 'teacher' || user?.user?.role === 'supervisor') && (
                      <>
                        <>
                          <Route path="classes" element={<TeacherClassesComponent />} />
                          <Route path="classes/classId/:classId/packId/:packId" element={<StudentsTableMaterialComponent />} />
                          <Route path="classes/classId/:classId/packId/:packId/teacherCorrection" element={<TeacherCorrectionComponent />} />
                        </>
                      </>
                    )
                  }
                  {user?.user?.role === 'teacher' && <>
                    <Route path='my-materials' element={<Materials />} />
                    <Route path='my-media' element={<Media />} />
                    <Route path='my-students' element={<MyStudents />} />
                    <Route path="view-lesson" element={<MultimediaViewer />} />
                    <Route path="complete-profile" element={<SelectSubject />} />
                    <Route path="add-test" element={<AddTest />} />
                    <Route path="programs-teacher" element={<ProgramsTeacher />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="subscriptions" element={<Subscription />} />
                    <Route path="my-transactions" element={<TransactionMe />} />
                    <Route path="subscriptions-teacher" element={<SubscriptionsTeachers />} />
                    <Route path="create-program-teacher" element={
                      <div className="container">
                        <CreatePackage role="teacher" />
                        <div className="mt-3"></div>
                        <ProgramsTeacher />
                      </div>
                    } />
                  </>}
                  {/* Other roles' routes can be added similarly */}
                </>
              </Route>

              <Route path="/dashboard/:username/:id/education-board/:requestId/:materialId" element={<CourseBoard />} />
              <Route path="/dashboard/:username/:id/education-board/:code/:lesson/:sheetId/:lessonId" element={<CourseBoard />} />


              {/* ------ */}

            </>
          )}



          {user && user.auth && user.user.role === 'Admin' && (
            <>
              <Route path="/user/:id" element={<GetText><UserDashboard user={user.user} />
                {/* <ToastContainer /> */}

              </GetText>}>
                <Route path="" element={<BuyRequests role="Admin" />} />
                {/* <Route path="" element={<Manage />} /> */}
                <Route path="profile" element={<Profile user={user.user} />} />
                <Route path='classes' element={<Classes />} />
                <Route path="messages" element={<MessagesAdmin />} />
                <Route path="new-product" element={<NewProduct />} />
                <Route path="cover" element={<UploadImage />} />
                <Route path="products" element={<Products user={user.user} />} />
                <Route path="sub-category" element={<SubCategory />} />
                <Route path="teachers-list" element={<TeachersList />} />
                <Route path="supervisors" element={<SuperVisorAccounts type={'supervisor'} />} />
                <Route path="students" element={<Students />} />
                <Route path="accounts" element={<Accounts />} />
                <Route path="packages-services" element={<Services />} />
                <Route path="zoom-setting" element={<Zoom />} />
                <Route path="my-media" element={<Media />} />
                <Route path="list/disscount" element={<GetText><DisscountList urlBase={urlBase} /></GetText>} />
                <Route
                  path="new/disscount"
                  element={
                    <NewDisscountCode
                      urlBase={''}
                      afterSubmitFunc={(data) => {
                        // console.log(data);
                      }}
                    />
                  }
                />
                <Route path="add-wareness-content" element={<Parents />} />
                <Route path="edit/product" element={<EditProduct />} />
                <Route path="whats-message" element={<SendMessage />} />
                <Route path='branch-level' element={<AllBranchs />} />
                <Route path='eduction-type' element={<EductionType />} />
                <Route path='materials' element={<Materials />}>
                  {/* <Route path="add-lesson/:id" element={<AddLesson/>}/> */}
                </Route>
                <Route path="view-lesson" element={<MultimediaViewer />} />
                <Route path="add-test" element={<AddTest />} />

                <Route path="packages" element={<Packages />} />
                <Route path="store-control" element={<StoreControle />} />
                <Route path="all-transactions" element={<Alltrans />} />
              </Route>


            </>
          )}



        </Routes>
        <ToastContainer />

      </BrowserRouter>
    </>



  );
}

export default App;
