import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { getContentUrls } from '../../../utils/lessons';
import { Container, Button } from 'react-bootstrap';
// import TestFile from '../../../assets/test.pdf';
// import PdfViewer from './pdfViewer';
// import MyDocument from './newPdfViewer';
// import PdfComp from './testPdf';
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import './pdf.css'
import "./view.css"
import { urlBase } from '../../../utils/API';
import ReactPlayer from "react-player"
import axios from 'axios';
// import PdfViewer from './pdfViewer';
const MultimediaViewer = () => {



  const [pdfNumPages, setPdfNumPages] = useState(null);
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [content, setContent] = useState([]);

  const { lesson } = useSelector(({ view }) => {
    return { lesson: view?.lesson };
  });

  const getURLS = async () => {
    try {
      const res = await getContentUrls(lesson.content);
      setContent(res.data.newContent);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (lesson && lesson.content.length) {
      getURLS();
    }
  }, [lesson]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfNumPages(numPages);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  console.log('lesson', lesson)
  const [presignedUrls, setPresignedUrls] = useState({});


  const getPresignedUrl = async (content) => {
    if (content.service === 'aws') {
      try {
        const response = await axios.post(`${urlBase}/api/retrieve-presigned-url`, {
          fileUrl: content.url,
          expires: 3600 // 1 hour in seconds
        });
        setPresignedUrls(prev => ({
          ...prev,
          [content._id]: response.data.url
        }));
      } catch (error) {
        console.error('Failed to get presigned URL:', error);
      }
    }
  };


  useEffect(() => {
    if (lesson?.content) {
      lesson.content.forEach(content => {
        if (content.service === 'aws') {
          getPresignedUrl(content);
        }
      });
    }
  }, [lesson]);

  return (
    <Container className='view-area-cont' onContextMenu={preventContextMenu}>
      <h2>{lesson?.name}</h2>

      {lesson?.content ? (
        <>
          {lesson?.content?.map((c, i) => {
            const contentUrl = c.service === 'aws' ? presignedUrls[c._id] : `${urlBase}/${c.url}`;

            if (c.mediaType.split('/').includes('image')) {
              return (
                <div key={i} onContextMenu={preventContextMenu}>
                  <hr />
                  <img
                    src={contentUrl}
                    alt="lesson cover"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              );
            } else if (c.mediaType.split('/').includes('pdf')) {
              return (
                <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
                  <iframe
                    src={contentUrl}
                    width="100%"
                    height="500px"
                  />
                </div>
              );
            }
          })}
        </>
      ) : (
        <>
          <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
        </>
      )}

      {/* Static PDF rendering for testing */}

      {/* <PdfViewer pdfUrl={TestFile}/>
      <MyDocument/> */}
    </Container>
  );
};

export default MultimediaViewer;




// {lesson?.content?.map((c, i) => {
//   if (c.mediaType.split('/').includes('image')) {
//     return (
//       <div key={i} onContextMenu={preventContextMenu}>
//         <hr />
//         <img
//           src={`${urlBase}/${c.name}`}
//           alt="lesson cover"
//           style={{ width: '100%', height: 'auto' }}
//         />
//       </div>
//     );
//   } else if (c.mediaType.split('/').includes('video')) {
//     if (c.source === "LOCAL") {
//       return (
//         <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
//           <hr />
//           <video controls controlsList="nodownload" width="100%" height="auto">

//             <source src={`${urlBase}/${c.name}`} type={c.mediaType} />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       );
//     }
//   } else if (c.mediaType.split('/').includes('pdf')) {
//     return (
//       <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
//         <div>

//           <iframe src={`${urlBase}/${c.name}`} width="100%" height="500px" />
//         </div>
//         {/* <PdfViewer fileURL={`${urlBase}/${c.name}`}/> */}
//       </div>
//     );
//   } else if (c?.type === "link") {
//     return (
//       <>
//         <br /> <br />
//         {/* <span>link</span> */}

//         <ReactPlayer
//           url={c?.name}
//           controls={true}
//           width="100%"
//           height="500px"
//           config={{
//             youtube: {
//               playerVars: {
//                 modestbranding: 1,
//                 rel: 0,
//                 showinfo: 0,
//                 iv_load_policy: 3,
//               }
//             },
//             dailymotion: {
//               params: {
//                 controls: true,
//                 autoplay: false,
//                 mute: false,
//                 'queue-enable': false,
//                 'sharing-enable': false,
//                 'ui-start-screen-info': false, // Hide video title and uploader info
//                 'ui-logo': false, // Hide Dailymotion logo
//               }
//             }
//           }}
//         />


//       </>
//     )
//   }
// })}