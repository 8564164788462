import axios from 'axios';
import { urlBase } from './API';


// Request Code 
/**
{materialCode,packID,lessonsIDS,expir,tries}
*/
export const createCodeSheet = (data) => {
    console.log(data)
    return axios({
        method: 'POST',
        url: `${urlBase}/api/create/codes`,
        data: data,
    })
}


// Get Sheets Codes Per Material Per Program
// {materialCode , packID}
export const getSheetsPerMaterialPerProgram = (data) => {
    console.log(data)
    return axios({
        method: 'POST',
        url: `${urlBase}/api/get/codes/sheets`,
        data: data,
    })
}



// Subscript To Program By Code
/**
{userID,packID}
*/
export const requestToProgramByCode = (data) => {
    // console.log(data)
    return axios({
        method: 'POST',
        url: `${urlBase}/api/create/request/by/code`,
        data: data,
    })
}


// Get Lesson By Code
export const getLessonByCode = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get/lesson/by/code`,
        data:data,
    })
}

// Increase Tries
// Request:{sheetId,lesson:{_id}}
export const increaseTries = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/increase/code/tries`,
        data:data,
    })
}


// download excel file ({id} => sheetId)

export const downloadExcelFile = (data)=>{
    console.log(data);
    return axios({
        method:"POST",
        url:`${urlBase}/api/create/code/sheet/excell/file`,
        data:data,
    })
}
// Activate Codes ({id})

export const codeActive = (data)=>{
    console.log(data);
    return axios({
        method:"POST",
        url:`${urlBase}/api/deactivate/codes/sheet`,
        data:data,
    })
}


