// import React, { useState } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import { uploading } from '../../utils/API';
// import { createRequest } from '../../utils/packagesRequests';
// import { useSelector } from 'react-redux';
// import { InfoMsg, success } from '../shared/modules/Notifications';
// import "../../styles/modal.css";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import { requestToProgramByCode } from '../../utils/codes';
// import { useNavigate } from 'react-router-dom';

// function AnotherBookingModal({ item, title , showModal, onHide }) {
//     const loggedUser = useSelector((state) => state.loggedUser);

//     const [show, setShow] = useState(false);
//     const [imageFile, setImageFile] = useState();
//     const [fileName, setFileName] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [tab, setTab] = useState("home")
//     console.log(item)
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     const handleImageUpload = (event) => {
//         const selectedImage = event.target.files[0];
//         setImageFile(selectedImage);
//         setFileName(selectedImage?.name || '');
//     };

//     const navigate = useNavigate();

//     const bookNow = async () => {
//         if (!loggedUser?.user?._id) {
//             InfoMsg('يرجى تسجيل الدخول يالايميل الخاص بك');
//             return;
//         }
//         try {
//             if (tab === "home") {


//                 if (!imageFile) {
//                     InfoMsg('يرجى اختيار صورة');
//                     return;
//                 }

//                 setLoading(true);

//                 const formData = new FormData();
//                 formData.append("file", imageFile);
//                 const fileImage = await uploading(formData);

//                 const resRequest = await createRequest({
//                     userID: loggedUser?.user?._id,
//                     packID: item?._id,
//                     packData: item,
//                     receipt: {
//                         file: fileImage?.fileName
//                     }
//                 });

//                 if (resRequest?.data?.case) {
//                     success(`${resRequest?.data?.message}`);
//                     handleClose();
//                     navigate("/thanks")
//                 } else {
//                     InfoMsg(`${resRequest?.data?.message}`);
//                     handleClose();
//                 }
//             } else if (tab === "code") {
//                 const data = { userID: loggedUser?.user._id, packID: item?._id }
//                 const uploadCodeRes = await requestToProgramByCode(data);

//                 if (uploadCodeRes?.data?.case) {
//                     success(`${uploadCodeRes?.data?.message}`);
//                     handleClose();
//                     navigate("/thanks")
//                 } else {
//                     InfoMsg(`${uploadCodeRes?.data?.message}`);
//                     handleClose();
//                 }
//             }
//         } catch (e) {
//             InfoMsg('حدث خطأ أثناء الحجز');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleTabSelect = (key) => {
//         setTab(key)
//     };

//     return (
//         <>
//             <button className="book-button buttonOpacity" onClick={handleShow}>{title}</button>

//             <Modal show={show} className='font modalBook' onHide={handleClose} centered dir="rtl">
//                 <Modal.Header className='headerTitle'>
//                     <Modal.Title className='text-center'>اشتراك البرنامج : {item?.name}</Modal.Title>
//                 </Modal.Header>

//                 <Modal.Body>
//                     {loading ? (
//                         <div className="loaderIcon" style={{ height: "10vh", textAlign: "center" }}>
//                             <span className="loader"></span>
//                         </div>
//                     ) : (
//                         <>
//                             <Tabs
//                                 defaultActiveKey="home"
//                                 id="uncontrolled-tab-example"
//                                 className="mb-3 custom-tabs"
//                                 onSelect={handleTabSelect}
//                             >
//                                 <Tab eventKey="home" title="فودافون" className="custom-tab">
//                                     <div>
//                                         <p><span className='namePrograam'>1-برجاء دفع مبلغ {item?.price} ج.م على محفظة الرقم الموضح</span></p>
//                                         <p><span className='namePrograam'>2- قم برفع صورة من إيصال أو رسالة الدفع</span></p>
//                                     </div>
//                                     <div className='namePrograam text-center phone'>
//                                         <p className='fs-5'>{item?.teacher?.myWallet}</p>
//                                     </div>
//                                     <div className='mt-3 text-center'>
//                                         <p className='namePrograam'>أختر صورة الإيصال أو رسالة الدفع</p>
//                                         <div className="file-input">
//                                             <input
//                                                 type="file"
//                                                 name="file-input"
//                                                 id="file-input"
//                                                 className="file-input__input"
//                                                 onChange={handleImageUpload}
//                                             />
//                                             <label className="file-input__label buttonOpacity" htmlFor="file-input">
//                                                 <span>ارفع الصوره</span>
//                                             </label>
//                                             {fileName && (
//                                                 <p className="file-name">{fileName}</p>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </Tab>
//                                 {/* <Tab eventKey="profile" title="فوري" className="custom-tab">
//                                     <div>
//                                         <p><span className='namePrograam'>1-برجاء دفع مبلغ {item?.price} ج.م علي فوري   </span></p>
//                                     </div>
//                                 </Tab> */}
//                                 <Tab eventKey="code" title="الكود" className="custom-tab">
//                                     <div>
//                                         <p><span className='namePrograam'> الاشتراك بنظام الاكواد
//                                             ملحوظة: لفتح كل درس يتطلب حصولك على كود خاص به</span></p>
//                                     </div>
//                                 </Tab>
//                             </Tabs>

//                             <div style={{ textAlign: "left", marginTop: "30px" }}>
//                                 <button className='btn buttonsGroub buttonOpacity' onClick={bookNow}>اشتراك</button>
//                                 <button className='btn special color' onClick={handleClose}>الغاء</button>
//                             </div>
//                         </>
//                     )}
//                 </Modal.Body>
//             </Modal >
//         </>
//     );
// }

// export default AnotherBookingModal;


import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { uploading } from '../../utils/API';
import { createRequest } from '../../utils/packagesRequests';
import { useSelector } from 'react-redux';
import { InfoMsg, success } from '../shared/modules/Notifications';
import "../../styles/modal.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { requestToProgramByCode } from '../../utils/codes';
import { useNavigate } from 'react-router-dom';

function AnotherBookingModal({ item, title, showModal, onHide }) {
    const loggedUser = useSelector((state) => state.loggedUser);

    const [imageFile, setImageFile] = useState();
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState("home");

    const handleImageUpload = (event) => {
        const selectedImage = event.target.files[0];
        setImageFile(selectedImage);
        setFileName(selectedImage?.name || '');
    };

    const navigate = useNavigate();

    const bookNow = async () => {
        if (!loggedUser?.user?._id) {
            InfoMsg('يرجى تسجيل الدخول يالايميل الخاص بك');
            return;
        }
        try {
            if (tab === "home") {
                if (!imageFile) {
                    InfoMsg('يرجى اختيار صورة');
                    return;
                }

                setLoading(true);

                const formData = new FormData();
                formData.append("file", imageFile);
                const fileImage = await uploading(formData);

                const resRequest = await createRequest({
                    userID: loggedUser?.user?._id,
                    packID: item?._id,
                    packData: item,
                    receipt: {
                        file: fileImage?.fileName
                    }
                });

                if (resRequest?.data?.case) {
                    success(`${resRequest?.data?.message}`);
                    onHide(); // Close the modal using the onHide prop
                    navigate("/thanks");
                } else {
                    InfoMsg(`${resRequest?.data?.message}`);
                    onHide(); // Close the modal using the onHide prop
                }
            } else if (tab === "code") {
                const data = { userID: loggedUser?.user._id, packID: item?._id };
                const uploadCodeRes = await requestToProgramByCode(data);

                if (uploadCodeRes?.data?.case) {
                    success(`${uploadCodeRes?.data?.message}`);
                    onHide(); // Close the modal using the onHide prop
                    navigate("/thanks");
                } else {
                    InfoMsg(`${uploadCodeRes?.data?.message}`);
                    onHide(); // Close the modal using the onHide prop
                }
            }
        } catch (e) {
            InfoMsg('حدث خطأ أثناء الحجز');
        } finally {
            setLoading(false);
        }
    };

    const handleTabSelect = (key) => {
        setTab(key);
    };

    return (
        <>
            {/* <button className="book-button buttonOpacity" onClick={onHide}>{title}</button> */}

            <Modal show={showModal} className='font modalBook' onHide={onHide} centered dir="rtl">
                <Modal.Header className='headerTitle'>
                    <Modal.Title className='text-center'>اشتراك البرنامج : {item?.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {loading ? (
                        <div className="loaderIcon" style={{ height: "10vh", textAlign: "center" }}>
                            <span className="loader"></span>
                        </div>
                    ) : (
                        <>
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="mb-3 custom-tabs"
                                onSelect={handleTabSelect}
                            >
                                <Tab eventKey="home" title="فودافون" className="custom-tab">
                                    <div>
                                        <p><span className='namePrograam'>1-برجاء دفع مبلغ {item?.price} ج.م على محفظة الرقم الموضح</span></p>
                                        <p><span className='namePrograam'>2- قم برفع صورة من إيصال أو رسالة الدفع</span></p>
                                    </div>
                                    <div className='namePrograam text-center phone'>
                                        <p className='fs-5'>{item?.teacher?.myWallet}</p>
                                    </div>
                                    <div className='mt-3 text-center'>
                                        <p className='namePrograam'>أختر صورة الإيصال أو رسالة الدفع</p>
                                        <div className="file-input">
                                            <input
                                                type="file"
                                                name="file-input"
                                                id="file-input"
                                                className="file-input__input"
                                                onChange={handleImageUpload}
                                            />
                                            <label className="file-input__label buttonOpacity" htmlFor="file-input">
                                                <span>ارفع الصوره</span>
                                            </label>
                                            {fileName && (
                                                <p className="file-name">{fileName}</p>
                                            )}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="code" title="الكود" className="custom-tab">
                                    <div>
                                        <p><span className='namePrograam'> الاشتراك بنظام الاكواد
                                            ملحوظة: لفتح كل درس يتطلب حصولك على كود خاص به</span></p>
                                    </div>
                                </Tab>
                            </Tabs>

                            <div style={{ textAlign: "left", marginTop: "30px" }}>
                                <button className='btn buttonsGroub buttonOpacity' onClick={bookNow}>اشتراك</button>
                                <button className='btn special color' onClick={onHide}>الغاء</button>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal >
        </>
    );
}

export default AnotherBookingModal;
