import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './filter.css'; // Custom CSS for styling
import { urlBase } from '../../utils/API';

function FilterPosts({ Tabs, onChoose, handleLevelClick, title, OutPut }) {

  return (
    <div className="filter-cont">
      <p className='filter-title'>{title}</p>
      {Tabs && Tabs.length ? (
        Tabs.map((tab, index) => <DropdownMenu key={index} tab={tab} OutPut={OutPut} handleLevelClick={handleLevelClick} />)
      ) : (
        <p>No tabs to display</p>
      )}
    </div>
  );
}

function DropdownMenu({ tab, handleLevelClick, OutPut }) {
  const navigate = useNavigate();



  return (
    <>
      <Dropdown className="drop-down-filter"
      
      >
        <Dropdown.Toggle
          style={{
            width: '98%',
            background: '#0F2A51',
            fontSize: '15px',
            lineHeight: '25px',
            marginBottom:"10px"
          }}
          id={`dropdown-${tab.name}`}
        >
          {tab.name}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="dropdown-menu-side w-100"
        >
          {tab.levels && tab.levels.length > 0 ? (
            tab.levels.map((level, index) => (
              <>
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    handleLevelClick(tab._id, level._id);
                    OutPut(level)
                  }} // Pass itemId to handleClick
                >
                  {level.name}
                </Dropdown.Item>
              </>
            ))
          ) : (
            <Dropdown.Item>No levels available</Dropdown.Item>
          )}
        </Dropdown.Menu>

      </Dropdown>

    </>
  );
}

export default FilterPosts;
