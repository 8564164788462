import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Container, Tabs, Tab } from "react-bootstrap";
import Col from "../styled-components/col";
import NewAccount from "./newAccount/newAccount";
import "./teacher.css";
import { FaRegTrashAlt } from "react-icons/fa";
import { Delete_Teacher, Inetialize_Users } from "../../actions/teachers.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import TableNewTeachers from "./TableNewTeachers.js";
import AssignUserToZoom from "./Zoom/AssignUserToZoom.js";
import DeactiveZoom from "./Zoom/DeactiveZoom.js";

function TeachersList() {
  const [render, setRender] = useState(false);
  const [key, setKey] = useState('teachers'); // للتحكم في التبويب النشط
  const dispatch = useDispatch();
  const teachers = useSelector(state => state.users.teachers);

  console.log(teachers);

  useEffect(() => {
    dispatch(Inetialize_Users('teacher'))
  }, [render]);

  return (
    <Container className="teacher-admin-page" style={{ height: '100%' }}>
      <Col fg='1' dir='column'>
        <NewAccount type='teacher' />
        <Col width={"80%"} dir="column" align={"flex-start"}>
          <Tabs
            id="teachers-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            {/* التبويب الأول - عرض المعلمين */}
            <Tab eventKey="teachers" title="المعلمين" className="font">
              <div className="scroll-overflow" style={{ width: "100%" }}>
                <Table responsive striped bordered hover className="font" style={{ fontSize: "20px" }}>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>الاسم</th>
                      <th>البريد الالكترونى</th>
                      <th>تاريخ التسجيل</th>
                      <th>التواصل</th>
                      <th>اضافة زووم</th>
                      <th>تعطيل زووم</th>
                      <th>حذف</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "20px" }}>
                    {teachers.map((t, i) => (
                      <TeacherList
                        key={i}
                        item={t}
                        icon={
                          <FaRegTrashAlt
                            size='20px'
                            onClick={() => {
                              dispatch(Delete_Teacher(t._id, t.role));
                              setRender(!render);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        }
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>

            {/* التبويب الثاني - جدول فارغ */}
            {/* <Tab eventKey="other" title="المعلمين الجدد" className="font">
              <div className="scroll-overflow" style={{ width: "100%" }}>
                <TableNewTeachers />
              </div>
            </Tab> */}
          </Tabs>
        </Col>
      </Col>
    </Container>
  );
}

function TeacherList({ item, icon }) {
  const { loggedUser } = useSelector(({ users, loggedUser }) => {
    return {
      loggedUser
    };
  });
  const IM = item.personalImage !== null ? item.personalImage : "";

  return (
    <tr style={{ fontSize: "20px" }}>
      <td style={{ fontSize: "18px" }}>
        <div style={{ width: "50px", height: "50px" }}>
          {IM !== "" ? (
            <img src={IM} alt="teacher" style={{ width: "50px", height: "50px" }} />
          ) : (<FaUser />)}
        </div>
      </td>
      <td style={{ fontSize: "18px" }}>{item.name}</td>
      <td style={{ fontSize: "18px" }}>{item.email}</td>
      <td style={{ fontSize: "18px" }}>{item.date.split('T')[0]}</td>
      <td style={{ fontSize: "18px" }}>
        <a href={`https://wa.me/${item?.phone}`}>
          <IoLogoWhatsapp color="#25D366" size={"25"} />
        </a>
      </td>
      <td>
        <AssignUserToZoom teacher={item} />
      </td>
      <td>
        <DeactiveZoom teacher={item} />
      </td>
      <td style={{ fontSize: "18px" }}>{icon}</td>

    </tr>
  );
}

export default TeachersList;
