import {getClasses, addStudentsToClass, changeClassActiveState, newClass, addTeacherToClass, addSuperVisorToClass, distributeStudentsOnSuperVisor, addWeekTimes } from "../utils/class";

export const NEW_CLASS = 'NEW_CLASS';
const newclass = (Class)=>{
    return {
        type:NEW_CLASS,
        Class
    }
}
export const New_Class = (data,userId)=>{
    return dispatch=>newClass(data,userId).then(res=>{
        if(res.data.case === true){
            dispatch(newclass(res.data.class))
        }
        return res
    })
}

// get all classes 
export const GET_CLASSES = 'GET_CLASSES';
const  getclasses = (classes)=>{
    return {
        type:GET_CLASSES,
        classes
    }
}
export const Get_Classes = ()=>{
    return dispatch => getClasses().then(res=>{
        if(res.data.case){
            dispatch(getclasses(res.data.classes))
        }
        return res
    })
}
// add students to class
export const ADD_STUDENTS = 'ADD_STUDENTS';
const addStudents = (Class)=>{
    return {
        type:ADD_STUDENTS,
        Class
    }
}
export const Add_Students_To_Class = (data)=>{ // data{students,ids}
    return dispatch => addStudentsToClass(data).then(res=>{
        if(res.data.case){
            dispatch(addStudents(res.data.class))
        }
        return res
    })
}
// add teacher to class
export const ADD_CLASS_TEACHER ='ADD_CLASS_TEACHER';
const addclassTeacher = (Class)=>{
   return {
    type:ADD_CLASS_TEACHER,
    Class
   }
}
export const Add_Class_Teacher = (data,userId)=>{
    return dispatch=>addTeacherToClass(data,userId).then(res=>{
        if(res.data.case){
            dispatch(addclassTeacher(res.data.class))
        }
        return res
    })
}

export const Add_Class_SuperVisor = (data)=>{
    return dispatch=>addSuperVisorToClass(data).then(res=>{
        if(res.data.case){
            dispatch(addclassTeacher(res.data.Class))
        }
        return res
    })
}

// change class active state 
export const CHANGE_ACTIVE_STATE = 'CHANGE_ACTIVE_STATE';
const changeActiveState = (Class)=>{
    return{
        type:CHANGE_ACTIVE_STATE,
        Class
    }
}
export const Change_Class_Active_State = (data)=>{
    return dispatch=> changeClassActiveState(data).then(res=>{
        if(res.data.case){
            dispatch(changeActiveState(res.data.class))
        }
        return res
    })
}
// distribute students on supervisor
export const ADD_STUDENT_TO_SUPERVISOR = 'ADD_STUDENT_TO_SUPERVISOR';
const addStusentToSuperVisor = (cls)=>{
    return {
        type:ADD_STUDENT_TO_SUPERVISOR,
        cls
    }
}

export const Distribute_Students_OnSuperVisors = (data)=>{
    return dispatch => distributeStudentsOnSuperVisor(data).then(res=>{
        if(res.data.case){
            dispatch(addStusentToSuperVisor(res.data.class))
        }
        return res
    })
}

export const Add_Class_Times = (data)=>{
   return dispatch => addWeekTimes(data).then(res=>{
    if(res.data.case){
        dispatch(addStusentToSuperVisor(res.data.class))
    }
    return res
   })
}