import React, { useEffect } from 'react'
import PageContainer from '../../components/layout/PagecContainer'
import HeaderDashboard from '../../components/Dashboard/modules/HeaderDashboard'
import "../../styles/dashboard.css"
import { useDispatch } from 'react-redux'
import { Get_Materials } from '../../actions/curriculums/materials'
const DashboardPage = () => {
    //Use Effect (dispatch (get Materials)) (role ===> teacher dispatch(action))

    const dispatch = useDispatch();

    const fetchMaterias = async () => {
        try {
            const res =await dispatch(Get_Materials())
            // console.log(res);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchMaterias();
    }, [])
    return (
        <div>
            <PageContainer>
                <main id="Dashboard" style={{ marginTop: "100px" }}>
                    <HeaderDashboard />
                </main>
            </PageContainer>
        </div>
    )
}

export default DashboardPage
