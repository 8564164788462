import React, { useContext, useEffect, useState } from 'react'
import Timeline from './Timeline'
import PageContainer from '../layout/PagecContainer'

import { Link, useParams } from 'react-router-dom'
import "./timeline.css"
import SharedImage from './SharedImage'
import { useDispatch, useSelector } from 'react-redux'
import { Get_Eduction_Type } from '../../actions/eduAction'
import Spinner from 'react-bootstrap/Spinner';
import { ThemeContext } from '../../ThemeProvider'
const Branchselect = () => {

    const dispatch = useDispatch();

    const handleGetEduTypes = async () => {
        try {
            setLoading(true);
            const res = await dispatch(Get_Eduction_Type());
            console.log(res)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const {theme} = useContext(ThemeContext);

    useEffect(() => {
        handleGetEduTypes();
    }, [])
    const eduTypes = useSelector((state) => state.eduRed);


    const params = useParams();
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectEduType, setSelectEduType] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleBranchClick = (branchId, eduType) => {
        setSelectedBranch(branchId);
        setSelectEduType(eduType)
    };


    console.log(eduTypes)
    if (loading) {
        return (
            <div className="loading-screen" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5'
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className='container'>
                        <Timeline activeStep={1} />
                        <div className='row justify-content-between align-items-center' style={{ marginTop: "180px" }}>
                            <div className='col-lg-4 col-sm-12 col-md-12 '>
                                <SharedImage type={"branch"} />
                            </div>
                            <div className='col-lg-8 col-sm-12 col-md-12'>
                                <div className='row justify-content-center align-items-center font mb-5'>
                                    {
                                        eduTypes?.map((eduType, index) => {
                                            return (
                                                <div className='col-lg-3' key={eduType?._id}>
                                                    <Link to={`/reservation-system/${params.gender}/${eduType._id}`}
                                                        state={{ eduTypes: eduType }}
                                                    className={`text-center branchDiv ${selectedBranch === eduType._id ? 'active' : ''}`}
                                                        onClick={() => handleBranchClick(eduType._id, eduType)} >
                                                        <p >{eduType.name}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='m-auto text-center mt-4 mb-5 font'>
                            <Link to={`/reservation-system`} className='btn w-25 mx-2 prev'
                                style={{ color:theme ==="light" ? "#071D5B":"white",}}
                            >السابق</Link>


                            <Link to={`/reservation-system/${params.gender}/${selectedBranch}`}
                                state={{ eduTypes: selectEduType }}
                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color:"white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >التالي</Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Branchselect
