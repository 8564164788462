import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

function MeetingRoomDetailsModal({ roomObj }) {
  console.log('roomObj', roomObj);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderSourceDetails = () => {
    switch (roomObj?.source) {
      case 'Google':
        return <div>Source: Google Meet</div>;
      case 'Zoom':
        return <div>Source: Zoom</div>;
      case 'Microsoft':
        return <div>Source: Microsoft Teams</div>;
      default:
        return <div>Source: Unknown</div>;
    }
  };

  const renderTypeDetails = () => {
    return roomObj?.type === 'IN' ? <div>Type: Internal</div> : <div>Type: External</div>;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="meeting-room-details-container">
      <Button className={"addclass_btn font"} onClick={openModal}>الذهاب للاجتماع</Button>

      {isModalOpen ? <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className='font'>Meeting Room Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='font'>
            <strong>Link:</strong> <a href={roomObj?.link} target="_blank" className='font' rel="noopener noreferrer">{roomObj?.link}</a>
          </div>
          {roomObj?.type === "OUT" ? renderSourceDetails() : "داخل المنصة"}
          {renderTypeDetails()}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn special color' onClick={closeModal}>Close</button>
        </Modal.Footer>
      </Modal> : <></>}
    </div>
  );
}

MeetingRoomDetailsModal.propTypes = {
  roomObj: PropTypes.shape({
    link: PropTypes.string.isRequired,
    source: PropTypes.oneOf(['Google', 'Zoom', 'Microsoft']).isRequired,
    type: PropTypes.oneOf(['IN', 'OUT']).isRequired
  }).isRequired
};

export default MeetingRoomDetailsModal;
