import axios from 'axios';
import { urlBase } from './API';

// Handle Payment
export const checkoutPay = (data,userId) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/checkout?userId=${userId}`,
        data:data
    })
}