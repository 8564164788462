// import { GET_ALL_TRANSACTIONS } from "../actions/transactionAct"


// export const transactionRed = (state = [], action) => {
//     switch (action.type) {
//         case GET_ALL_TRANSACTIONS:
//             return state = action.transactions
//         // case CREATE_NEW_PACKAGE:
//         //   return state = [...state, action.pack]
//         // case UPDATE_PACKAGE:
//         //   return state = state.map(s => {
//         //     if (s._id === action.pack._id) {
//         //       return action.pack
//         //     }
//         //     return s
//         //   })
//         // case DELETE_PACKAGE:

//         //   return state = state.filter(s => s._id !== action.id)
//         default: return state
//     }
// }

import { DELTETE_TRANSACTION, GET_ALL_TRANSACTIONS, UPDATE_TRANSACTION } from "../actions/transactionAct"

const initialState = {
    data: [],
    total: 0
}

export const transactionRed = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TRANSACTIONS:
            console.log(action)
            return {
                data: action.transactions.data,
                total: action.transactions.total
            }
        case DELTETE_TRANSACTION:
            return {
                data: state.data.filter(transaction => transaction._id !== action.id),
                total: state.total - 1
            }
        case UPDATE_TRANSACTION:
            return {
                data: state.data.map(transaction =>
                    transaction._id === action.transaction._id
                        ? action.transaction
                        : transaction
                ),
                total: state.total
            }
        default: return state
    }
}
