import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Col from "../../styled-components/col";
import Contain from "../../styled-components/contain";
import ListRow from "../../styled-components/listRow";
import NewAccount from ".././newAccount/newAccount";
import { FcFullTrash } from "react-icons/fc"
import { getAdmins } from '../../../utils/API';
// import {Inetialize_Ads} from "../../../actions/login&register.js"
function Accounts() {
  const [Admins, setAdmins] = useState([])
  const [render, setRender] = useState(true);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const getAdminsAccounts = async () => {
      try {
        setLoading(true)
        const res = await getAdmins();
        console.log('Admins', res)
        setLoading(false)
        if (res.data.case) {
          setAdmins(res.data.users)
        }
      }
      catch (err) {
        setLoading(false)
        console.warn(err)
      }
    }
    getAdminsAccounts();
  }, [render])
  return (
    <Contain perfix="teacher-admin-page" height='100%'>
      <Col fg='1' dir='column'>
        <NewAccount type='Admin' setRender={setRender} render={render} />
        <Col width={"80%"} dir="column" align={"flex-start"}>
          {!loading ?
            <div className="scoll-overflow" style={{ width: "100%" }}>
              {Admins?.map((t, i) => (
                <List key={i} item={t}
                  icon={<FcFullTrash
                    size='30px'
                    onClick={() => {
                      // dispatch(Delete_Teacher(t._id));
                      //  setRender(!render)
                    }} style={{
                      // top: "10px",
                      right: "5px",
                      cursor: "pointer",
                    }} />} />))}
            </div>

            :
            <div className="loaderIcon">
              <span className="loader"></span>
            </div>
          }
        </Col>
      </Col>
    </Contain>

  )
}
function List({ item, icon }) {

  return (
    <>
      <ListRow two={item.username} three={item.email} four={item.date.split('T')[0]} icon={icon} />
      <h1>Admins</h1>
    </>
  )
}

export default Accounts;