import React from 'react'
import homeImage from "../../assets/home_image.png"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
const HomeHeader = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='container mt-5' dir={`${dir}`}>
            <div className='row justify-content-between align-items-center'>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12 p-4 textHeader'>
                    <div className='textHeader' style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                        <h2 className='color mb-5'>{HomePage.info_header[lang]}</h2>
                        <p>{HomePage.info_header2[lang]}</p>
                    </div>
                    <div className='mt-5 textHeader ' style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                        <Link to="/register" className='btn buttonsGroub buttonOpacity'>{HomePage.watch[lang]}</Link>
                        <Link to="/login" className='btn special color buttonOpacity'>{HomePage.explore[lang]}</Link>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                    <img src={homeImage} alt="HomeImage" style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    )
}

export default HomeHeader
