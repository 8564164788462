import React, { useState } from "react";
import Contain from "../styled-components/contain.jsx";
import Col from "../styled-components/col";
import "./messages.css";
import ListRow from "../styled-components/listRow.jsx";
import { convertArrToObj } from "../globalFunc.jsx";
import Message from "./message";
function MessagesCont({ sent=[], recived=[], users }) {
  //users = students if user teacher else teachers

  const [messages, setMassages] = useState({
    messages: recived,
    type: "recived",
  });

  const usersObj = convertArrToObj(users, "_id");
  // console.log("test", usersObj, messages);
  return (
    <Contain>
      <Col width="100%"  dir="column">
        <Contain perfix="message-top-bar">
          <Col width="30%">
            <button
              onClick={() =>
                setMassages({ messages: recived, type: "recived" })
              }
            >
              Recived
            </button>
          </Col>
          <Col width="30%">
            <button
              onClick={() => setMassages({ messages: sent, type: "sent" })}
            >
              Sent
            </button>
          </Col>
       
        </Contain>
        {messages.messages.length?
        <Contain perfix='messages-body-cont'>
          <Col fg="1" dir="column" width="100%" border="1px">
            <div className='messages-cont'>
            <ListRow
              two={messages.type==='sent'?"To":'from'}
              three={"Subject"}
              four="view"
              five="date"
            />
   
            {messages.type === "recived" &&
              messages.messages.map((m, i) => {
                messages.messages.map((m, i) => {
                  // console.log(m);
                });
                return (
                  <ListRow
                    key={i}
                    two={usersObj[m.from].username}
                    three={m.title}
                    four={<Message message={m} />}
                    five={m.date.split("T")[0]}
                  />
                );
              })}
            
            
            {messages.type === "sent" &&
              messages.messages.map((m, i) => (
                <ListRow
                  key={i}
                  two={usersObj[m.to].username}
                  three={m.title}
                  four={<Message message={m} />}
                  five={m.date.split("T")[0]}
                />
              ))}
           
            {/* {!messages.messages.length?<p>No Messages to show</p>:null} */}
          </div>
          </Col>
        </Contain>:<h1>No Messages Yet!</h1>
        
      }
      </Col>
    </Contain>
  );
}

export default MessagesCont;
