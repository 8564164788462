import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Create_Product } from '../../../actions/store';
import { uploadSingleFile } from '../../../utils/upload';
import { Modal, Button, Form } from 'react-bootstrap';

function AddProduct() {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [numberOfColors, setNumberOfColors] = useState(1);
    const { categories } = useSelector(({ store }) => {
        return {
            categories: store.categories
        }
    });
    const [productData, setProductData] = useState({
        name: '',
        coverImage: null,
        images: [],
        description: '',
        price: '',
        colors: [],
        category: '',
        stock: ''
    });

    console.log(productData)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData({ ...productData, [name]: value });
    };

    const handleCoverImageChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        const res = await uploadSingleFile(formData); // Assuming uploadSingleFile returns the URL
        setProductData({ ...productData, coverImage: res.data.fileName });
    };

    const handleImageChange = async (e) => {
        const files = e.target.files;
        const imageUrls = await Promise.all(
            Array.from(files).map(async (file) =>{ 
                const formData = new FormData();
                formData.append("file",file)
                const res = await uploadSingleFile(formData);
                return res?.data?.fileName
            })
        );
        setProductData({ ...productData, images: [...productData.images, ...imageUrls] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(Create_Product(productData));
        // Reset form data after submission
        setProductData({
            name: '',
            coverImage: null,
            images: [],
            description: '',
            price: '',
            colors: [],
            category: '',
            stock: ''
        });
        handleCloseModal();
    };

    const handleOpenModal = () => setShowModal(true);

    const handleCloseModal = () => setShowModal(false);

    const handleNumberOfColorsChange = (e) => {
        const value = parseInt(e.target.value);
        setNumberOfColors(value > 0 ? value : 1);
        setProductData({ ...productData, colors: Array(value).fill('') });
    };

    const handleColorChange = (index, color) => {
        const updatedColors = [...productData.colors];
        updatedColors[index] = color;
        setProductData({ ...productData, colors: updatedColors });
    };

    return (
        <div style={{padding:'100px 0 10px 0'}}>
            <Button variant="primary" onClick={handleOpenModal}>
                اضف منتج جديد
            </Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title> اضف منتج جديد </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="name">
                            <Form.Label>الاسم:</Form.Label>
                            <Form.Control type="text" name="name" value={productData.name} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group controlId="coverImage">
                            <Form.Label> الصورة الاساسية للمنتج:</Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={handleCoverImageChange} />
                        </Form.Group>
                        <Form.Group controlId="images">
                            <Form.Label>صور أخرى للمنتج:</Form.Label>
                            <Form.Control type="file" accept="image/*" multiple onChange={handleImageChange} />
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>الوصف:</Form.Label>
                            <Form.Control as="textarea" name="description" value={productData.description} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group controlId="price">
                            <Form.Label>السعر:</Form.Label>
                            <Form.Control type="text" name="price" value={productData.price} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group controlId="numberOfColors">
                            <Form.Label> عدد الالوان:</Form.Label>
                            <Form.Control type="number" name="number of colors" min={1} value={numberOfColors} onChange={handleNumberOfColorsChange} />
                        </Form.Group>
                        {productData.colors.map((color, index) => (
                            <Form.Group key={index} controlId={`color${index}`}>
                                <Form.Label>لون {index + 1}:</Form.Label>
                                <Form.Control type="color" value={color} onChange={(e) => handleColorChange(index, e.target.value)} />
                            </Form.Group>
                        ))}
                        <Form.Group controlId="category">
                            <Form.Label>الصنف :</Form.Label>
                            <Form.Control as="select" name="category" value={productData.category} onChange={handleInputChange}>
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>{category.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="In-Stock">
                            <Form.Label>الكمية المتوفرة بالمخزن:</Form.Label>
                            <Form.Control type="number" min={1} name="stock" value={productData.stock} onChange={handleInputChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            حفظ
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AddProduct;
