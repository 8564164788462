import React, { useEffect, useState } from 'react'
import ClassStudents from './classStudents'
import AddSupervisorModal from './addSupervisorModal'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import AddTeacherToClass from './addTeacherToClass'
import SendGroupMessage from './sendGroupMessage'
import AddStudentsModal from './addStudentsModal'
import DayTimeSelector from './addTimes'
import { GetMaterialsByIds } from '../../../utils/materials';
import { useSelector } from 'react-redux'
import DeactivateClass from './deactivateClass'
import { getStudentsInProgramNotInClass } from '../../../utils/packagesRequests';
import TeachersList from './teachersList'
import CreateSession from './sessions/CreateSession'
import ShowSessions from './sessions/ShowSessions'


function SingleClass({ cls, students, toast }) {

    const [materials, setMaterials] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [showTimeModal, setShowTimeModal] = useState(false);
    const [studentsToAdd, setStudentsToAdd] = useState([]);
    const [loading, setLoading] = useState(false);
    const { classes } = useSelector(({ classes }) => ({
        classes
    }));
    const loggedUser = useSelector((state) => state.loggedUser);
    // console.log(cls)
    const getMaterials = async () => {
        try {
            setLoading(true)
            const codes = cls?.packID?.materials.map(m => m.materialCode);
            const res = await GetMaterialsByIds({ codes: codes })
            setMaterials(res?.data?.materials);
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
            console.warn(err)
        }
    }

    useEffect(() => {
        getMaterials();
    }, [])

    // inetialize new Students 
    useEffect(() => {
        const getNewStudents = async () => {
            try {
                const newStudents = await getStudentsInProgramNotInClass({ programId: cls?.packID?._id });
                setStudentsToAdd(newStudents?.data?.studentsDoesNotInClass);
            }
            catch (err) {
                console.log(err.message)
            }
        }
        getNewStudents();
    }, [classes])

    //   console.log('materials',materials);
    return (
        <>
            <DayTimeSelector
                cls={cls}
                show={showTimeModal}
                setShow={setShowTimeModal}
                material={selectedMaterial}
                toast={toast}
            />
            <tr>
                <td>{cls.name}</td>
                <td>{cls?.packID?.name}</td>
                <td>{studentsToAdd.length}</td>
                <td>{cls.studentsIDS.length}</td>
                <td><ClassStudents Class={cls} /></td>
                <td><TeachersList cls={cls} /></td>
                <td><AddSupervisorModal Class={cls} toast={toast} /></td>
                <td>
                <Dropdown className="cls-drop-menu" >
                        <Dropdown.Toggle variant="primary">
                            حصص
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            
                            <Dropdown.Item className='cls-drop-menu-item'>
                                <CreateSession cls={cls}/>
                            </Dropdown.Item>
                            <Dropdown.Item className='cls-drop-menu-item'>
                                <ShowSessions cls={cls}/>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
                <td>
                    <Dropdown className="cls-drop-menu" >
                        <Dropdown.Toggle variant="primary">
                            اختيارات
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                loggedUser?.user?.role === "Admin" ? (
                                    <Dropdown.Item style={{ height: '40px' }} className='cls-drop-menu-item'>
                                        <AddTeacherToClass Class={cls} toast={toast} />
                                    </Dropdown.Item>
                                ) : null
                            }
                            <Dropdown.Item className='cls-drop-menu-item'>
                                <SendGroupMessage toast={toast} acceptorsIDS={cls?.studentsIDS} />
                            </Dropdown.Item>
                            <Dropdown.Item className='cls-drop-menu-item'>
                                <AddStudentsModal
                                    toast={toast}
                                    Class={cls}
                                    allStudents={students}
                                    studentsToAdd={studentsToAdd}
                                />
                            </Dropdown.Item>

                            <Dropdown.Item className='cls-drop-menu-item'>
                                <AddSupervisorModal Class={cls} toast={toast} />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
                <td> <Dropdown className="cls-drop-menu" drop="center">
                    <Dropdown.Toggle variant="secondary">
                        الجدول
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='submenu-cont'>
                        {!loading && materials && materials.length ? materials?.map((m, i) =>
                            <Dropdown.Item
                                key={i}
                                onClick={() => {
                                    setSelectedMaterial(m); setShowTimeModal(true)
                                }}
                            >
                                {m?.name}
                            </Dropdown.Item>

                        ) : (<> {loading ? <span>تحميل ...</span> : <span>لا توجد مواد بهذا الكورس - او تم حذفها</span>}</>)}
                    </Dropdown.Menu>
                </Dropdown></td>
                <td><DeactivateClass classId={cls._id} /></td>
            </tr>
        </>
    )
}

export default SingleClass