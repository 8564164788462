import React, { useEffect, useState } from 'react'
import PageContainer from '../../layout/PagecContainer'
import { IoMdAdd } from "react-icons/io";
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Inetialize_Levels } from '../../../actions/curriculums/levels';
import { teacherCompleteRegister, teacherRequestMainMaterial } from '../../../utils/API';
import { useNavigate } from 'react-router-dom';
import { ErrorMsg, success } from '../../shared/modules/Notifications';
import { Get_All_Main_Materials, Set_Main_Materials_By_Level } from '../../../actions/mainMaterial';
import { HomePage } from '../../../utils/Translation/HomePage';

const SelectSubject = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Inetialize_Levels());
    }, [])

    const { branchs, levels, toast, user } = useSelector(({ branchs, levels, notify, loggedUser }) => {
        return { branchs, levels, toast: notify.toast, user: loggedUser?.user }
    })

    const [brLevels, setBrLevels] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [branchId, setBranchId] = useState(null);
    const [materialWord, setMaterialWord] = useState("");
    const [levelMaterials, setLevelMaterials] = useState([]);
    const [selectedLevels, setSelectedLevels] = useState([]);
    const [levelMaterialsMap, setLevelMaterialsMap] = useState({});
    const loggedUser = useSelector((state) => state.loggedUser)
    const [allMainMats, setAllMainMats] = useState([]);
    const [mainMats, setMainMats] = useState([]);

    const onChangeBranch = (branchID) => {
        try {
            setBranchId(branchID)
            const branch = branchs.find(b => b._id === branchID)
            const levelsIDS = branch.levelsIDS ? branch.levelsIDS : []
            const branchLevels = levels.filter(l => levelsIDS.includes(l._id));
            setBrLevels(branchLevels);
            setLevelMaterials([])
            setSelectedLevels([]);
            setLevelMaterialsMap({});
        }
        catch (err) {
            console.log(err.message)
        }
    }

    const onChangeLevel = async (levelID) => {
        try {
            if (!selectedLevels.includes(levelID)) {
                setSelectedLevels([...selectedLevels, levelID]);
                const filteredMats = allMainMats.filter(mat => mat.levelId === levelID);
                setLevelMaterialsMap(prev => ({
                    ...prev,
                    [levelID]: filteredMats
                }));
            }
        }
        catch (err) {
            console.log(err.message)
        }
    }

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const handleCompleteRegister = async () => {
        setLoading(true)
        try {
            const res = await teacherCompleteRegister({
                materials: selectedMaterials
            }, loggedUser?.user?._id)
            if (res?.data?.case) {
                success(res?.data?.case)
                navigate(`/dashboard/${loggedUser?.user?.username}/${loggedUser?.user?._id}//profile`, { state: { key: true } })
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(true)
        }
    }

    const handleAllMats = async () => {
        try {
            const res = await dispatch(Get_All_Main_Materials());
            if (res?.data?.case) {
                setAllMainMats(res?.data?.materials);
                setMainMats(res?.data?.materials);
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        handleAllMats();
    }, [dispatch])

    const handleMaterialSelect = (e) => {
        const selectedMaterial = mainMats.find(mat => mat.name === e.target.value);
        if (selectedMaterial && !selectedMaterials.find(m => m === selectedMaterial._id)) {
            setSelectedMaterials([...selectedMaterials, selectedMaterial._id]);
        }
    };

    const removeMaterial = (materialId) => {
        setSelectedMaterials(selectedMaterials.filter(id => id !== materialId));
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <PageContainer>
                <div className='text-center font w-50' style={{ margin: "120px auto", background: "white", padding: "15px 0", borderRadius: "15px" }} dir={dir}>
                    <div style={{ background: "#2C6DCE", color: "white", padding: "5px" }}>
                        <p>{HomePage.enterclassRoom[lang]}</p>
                        <p>{HomePage.classRoomInfo[lang]}</p>
                    </div>
                    <div style={{ textAlign: dir === "rtl" ? "right" : "left" }} className='mt-3 px-2'>
                        <div>
                            <Form.Group controlId="levelSelect">
                                <Form.Label> {HomePage.category[lang]}</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => onChangeBranch(e.target.value)}
                                >
                                    <option value="">{HomePage.chooseCategory[lang]}</option>
                                    {branchs.map((br) => (
                                        <option key={br._id} value={br._id}>
                                            {br.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <div>
                                <Form.Group controlId="levelSelect">
                                    <Form.Label> {HomePage.level[lang]}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={(e) => onChangeLevel(e.target.value)}
                                    >
                                        <option value="">{HomePage.chooseLevel[lang]}</option>
                                        {brLevels.map((level) => (
                                            <option key={level._id} value={level._id}>
                                                {level.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {selectedLevels.map(levelId => (
                                    <Form.Group key={levelId} controlId={`mainMaterialSelect-${levelId}`}>
                                        <Form.Label>
                                            {`${HomePage.mainMaterials[lang]} - ${levels.find(l => l._id === levelId)?.name}`}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={handleMaterialSelect}
                                            value=""
                                        >
                                            <option value="">{HomePage.chooseMaterial[lang]}</option>
                                            {levelMaterialsMap[levelId]?.map((mat) => (
                                                <option key={mat._id} value={mat.name}>
                                                    {mat.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        
                                        <div className="selected-materials mt-2">
                                            {selectedMaterials.map((materialId) => {
                                                const material = allMainMats.find(m => m._id === materialId);
                                                return material?.levelId === levelId ? (
                                                    <div
                                                        key={materialId}
                                                        className="d-inline-block m-1 p-2"
                                                        style={{
                                                            background: '#e9ecef',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {material?.name}
                                                        <span
                                                            onClick={() => removeMaterial(materialId)}
                                                            className="ms-2 text-danger"
                                                            style={{ fontWeight: 'bold' }}
                                                        >
                                                            ×
                                                        </span>
                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    </Form.Group>
                                ))}
                            </div>
                        </div>
                        <div className='text-center'>
                            <button 
                                className='btn' 
                                style={{ background: "#2C6DCE", color: "white" }}
                                onClick={handleCompleteRegister}
                                disabled={loading}
                            >
                                {loading ? HomePage.loading[lang] : HomePage.done[lang]}
                            </button>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default SelectSubject


