const colors = {
    sharedColor: "#0F2A51",
    color: "#2B6CCD",
    danger: "#e74c3c",
    warning: "#f1c40f",
    success: "#2ecc71",
    info: "#3498db",
    light: "#ecf0f1",
    dark: "#34495e",
};

export default colors