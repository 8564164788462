import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddMaterial from './addMaterial';
import { Update_Material, Delete_Material } from '../../../actions/curriculums/materials';
import { Table, Button, Modal, NavLink } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import AddLesson from '../lessons/addLesson';
import ViewLessons from '../lessons/viewLessons'
import AddTestBtn from '../tests/AddTestBtn';
import EditMaterial from './editMaterial';
import { checkTypevalidationStorage } from '../../../utils/API';
import ShowMessae from './ShowMessae';
import AddLessonOther from '../lessons/AddLessonOther';
import CreateCourse from './CreateCourse';
function Materials() {
  const materials = useSelector(state => {
    const user = state?.loggedUser?.user;
    // console.log('user',user)
    if (user?.role === "Admin") {
      return state.materials
    }
    else if (user?.role === 'teacher') {
      return state.materials.filter(m => m?.teacherId?._id === user?._id)
    }
  })

  const { user } = useSelector(state => state.loggedUser);
  const dispatch = useDispatch();
  const [deleteMaterialId, setDeleteMaterialId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteConfirmation = (materialId) => {
    setDeleteMaterialId(materialId);
    setShowDeleteModal(true);
  };

  const handleDeleteMaterial = () => {
    // Dispatch the delete material action
    dispatch(Delete_Material(deleteMaterialId));
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };

  const handleCloseDeleteModal = () => {
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };



  const [sub, setSub] = useState({})
  const handleCheckValidate = async () => {
    try {
      const res = await checkTypevalidationStorage(user._id);
      console.log(res)
      if (res?.data?.case) {
        setSub(res?.data?.subscription)
      }
    } catch (e) {
      console.log(e)
    }
  }


  useEffect(() => {
    if (user?.role === "teacher") {
      handleCheckValidate();
    }
  }, [])



  const [selectMat, setSelectMat] = useState(null);
  const [selectMatEdit, setSelectMatEdit] = useState(null);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleCreateCourse = (mat) => {
    setSelectMat(mat);
    setShow(true)
  }
  const handleEditMaterial = (mat) => {
    setSelectMatEdit(mat);
    setShowEdit(true)
  }




  return (
    <div>
      {
        user.role === 'Admin' ?
          <AddMaterial />
          : <></>
      }

      <Table responsive className='container mt-5 font fs-6'>
        <thead>
          <tr>
            <th>المادة</th>
            <th>كود المادة</th>
            <th>الماده الرئيسيه</th>
            <th>اضافة درس</th>
            <th>الدروس</th>
            {/* <th>اضافة اختبار</th> */}
            <th>معلم المادة</th>
            <th>تعديل</th>
            <th>انشاء كورس</th>
            {
              user?.role !== "Admin" ? null : (<th>حذف</th>)
            }

          </tr>
        </thead>
        <tbody>
          {materials.map(material => (
            <tr key={material._id}>
              <td>{material.name}</td>
              <td>{material.code}</td>
              <td>{material?.mainMaterial?.name}</td>

              <td>
                {/* {
                  sub?.status ? (
                    <AddLesson materialCode={material.code} />
                  ) : (<ShowMessae sub={sub}/>)
                } */}
                {user?.role !== 'teacher' ? (
                  <AddLesson materialCode={material.code} />
                  // <AddLessonOther materialCode={material.code}/>
                ) : (
                  sub?.status ? (
                    // <AddLesson materialCode={material.code} />
                    <AddLessonOther materialCode={material.code} />
                  ) : (
                    <ShowMessae sub={sub} />
                  )
                )}



              </td>
              <td><ViewLessons materialCode={material.code} /></td>
              {/* <td>
              <AddTestBtn 
                type={'material'}
                testFor={material._id}
                />
              </td> */}
              <td>
                {material.teacherId ? material?.teacherId?.name : "لايوجد"}
              </td>

              <td>
                {/* Add your edit functionality or button here */}
                <button className='btn buttonsGroub' onClick={() => handleEditMaterial(material)}>تعديل</button>

              </td>
              <td>
                <button className="btn bg-primary" disabled={!material.teacherId} onClick={() => { handleCreateCourse(material) }}>Create Course</button>
              </td>
              <td>
                {
                  user?.role !== "Admin" ? (
                    null
                  ) : (
                    <button className='btn special color' onClick={() => handleDeleteConfirmation(material._id)}>
                      Delete
                    </button>
                  )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <CreateCourse show={show} handleClose={() => setShow(false)} material={selectMat} />
      <EditMaterial show={showEdit} handleClose={() => setShowEdit(false)} material={selectMatEdit} />

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered className='font'>
        <Modal.Header>
          <Modal.Title>Delete Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this material?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteMaterial}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Materials;
